import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  InputBase,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getStaff, activeOrDeactiveStaff } from "../../store/action";
import history from "../../utils/HistoryUtils";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import AlertDialog from "../../components/Alert/AlertDialog";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },
  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ViewStaff = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const { loading, getStaffItem } = useSelector((state) => state.staff);
  const [searchData, setSearchData] = useState({ search: "" });
  let staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  staffDetail = staffDetail ? staffDetail.replace(/['"]+/g, "") : null;
  const loggedRole = localStorage.getItem("role").replace(/['"]+/g, "");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("users.first_name");
  const [staffId, setStaffId] = React.useState(0);
  const [activeOpen, setActiveOpen] = useState(false);
  const [deActiveOpen, setDeActiveOpen] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);

  const onhandlClick = (id) => {
    history.push(`${match.url}/${id}/detail`);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(getStaff1(value), 2000);
  };

  const getStaff1 = (
    pageNo = 1,
    search = "",
    type = order,
    column_name = orderBy
  ) => {
    dispatch(getStaff({ pageNo, search, type, column_name }));
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(getStaff1(page, d1), 100);
    } else {
      setTimeout(getStaff1(page, ""), 100);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setTimeout(getStaff1(page, searchData.search), 1000);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const aActiveClose = () => {
    setActiveOpen(false);
  };
  const dActiveClose = () => {
    setDeActiveOpen(false);
  };
  const alertActiveResponse = (id) => {
    dispatch(activeOrDeactiveStaff(id, 1));
    setStaffNotify(true);
  };
  const alertDeActiveResponse = (id) => {
    dispatch(activeOrDeactiveStaff(id, 0));
    setStaffNotify(true);
  };

  const onHandlActive = (id) => {
    setActiveOpen(true);
    setStaffId(id);
  };

  const onHandlDeActive = (id) => {
    setDeActiveOpen(true);
    setStaffId(id);
  };

  useEffect(() => {
    getStaff1();
  }, [orderBy, order]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Box className="mb-5" display="flex" alignItems="center">
          <SearchIcon
            className={classes.searchIcondet}
            onClick={handleClickSearch}
          />
          <div className={classes.search}>
            <InputBase
              name="search"
              placeholder="Search…"
              onChange={handleSearchChange}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          {(staffDetail === "Admin" || loggedRole === "ORGANIZATION") && (
            <div className="ml-5">
              <Link to={`${match.url}/create`} className="btn btn-secondary">
                <AddIcon className="mr-2" />
                Add Staff
              </Link>
            </div>
          )}
        </Box>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={getStaffItem?.data?.data?.length}
              headCells={headCells[0]}
            />
            <TableBody>
              {getStaffItem?.data?.data &&
                getStaffItem?.data?.data.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        {getStaffItem?.data?.from + index}
                      </TableCell>
                      <TableCell align="left">{`${row.first_name} ${row.last_name}`}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.contact_number}</TableCell>
                      <TableCell align="left">{row.designation_name}</TableCell>
                      <TableCell align="left">{row.role_name}</TableCell>

                      {getStaffItem?.is_smt === "Yes" && row.deleted !== "0" ? (
                        <TableCell align="right">
                          <Link
                            to="#"
                            className="btn btn-secondary btn-sm ml-auto"
                            onClick={() => onHandlDeActive(row.id)}
                          >
                            Deactivate
                          </Link>
                        </TableCell>
                      ) : getStaffItem?.is_smt === "Yes" ? (
                        <TableCell align="right">
                          <Link
                            to="#"
                            className="btn btn-secondary btn-sm ml-auto"
                            onClick={() => onHandlActive(row.id)}
                          >
                            Activate
                          </Link>
                        </TableCell>
                      ) : (
                        <TableCell align="right"></TableCell>
                      )}
                      <TableCell align="right">
                        <Link
                          to="#"
                          className="btn btn-secondary btn-sm ml-auto"
                          onClick={(e) => onhandlClick(row.id)}
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {getStaffItem?.data?.data == "" && (
                <TableRow>
                  <TableCell scope="row" colSpan="6">
                    <div className="" align="center">
                      Sorry, staff not available!
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={getStaffItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
      <AlertDialog
        id={staffId}
        open={activeOpen}
        close={aActiveClose}
        response={alertActiveResponse}
        title="Active Staff Member"
        description="Are you sure you want to active this user?"
        buttonName="Active"
      />

      <AlertDialog
        id={staffId}
        open={deActiveOpen}
        close={dActiveClose}
        response={alertDeActiveResponse}
        title="Deactive Staff Member"
        description="Are you sure you want to deactive this user?"
        buttonName="Deactive"
      />
    </>
  );
};

export default ViewStaff;
