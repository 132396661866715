import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  TextField,
  Menu,
  Tooltip,
  InputBase,
  TableContainer,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { changeShiftStatus, getBooking } from "../../store/action";
import history from "../../utils/HistoryUtils";
import Notification from "../../components/Notification/Notification";
import apiConfigs from "../../config/config";
import axios from "axios";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import {
  getStaffDetail,
  getCandidateList,
  getCandidateMatchingList,
} from "../../store/action/staff/staffAction";
import {
  getBookingTrust,
  getHospitalList,
  getWardByHospital,
  createFilter,
  deleteFilter,
  getFilterData,
} from "../../store/action/booking/bookingAction";
import { getSpecShiftCreate } from "../../store/action/specialities/specialitiesAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import { notificationSuccess } from "../../store/action/notificationMsg";
import { customEnLocale } from "../../helper/customLocale";
import SuperAssign from "./SuperAssign";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
    marginBottom: 0,
  },

  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 335px)",
  },

  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "800px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  formControl: {
    width: "24%",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,

    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: 140,
      cursor: "pointer",
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const ViewBooking = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const tabList = [
    "CREATED",
    "CONFIRMED",
    "CANCEL",
    "PROVISIONAL",
    "COMPLETED",
  ];
  const displayTabList = [
    "VACANCY",
    "BOOKED",
    "CANCEL",
    "PROVISIONAL",
    "COMPLETED",
  ];
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [open, setOpen] = useState(false);
  const [bookingMultiIds, setBookingMultiIds] = useState("");
  const [flag, setFlag] = useState(true);

  const [searchData, setSearchData] = useState({
    search: "",
    specialty: "",
    customer: "",
    site: "",
    ward: "",
    consultants: "",
    candidate: "",
    start_date: "",
    end_date: "",
    provisional_status: "",
    module_name: "bookings",
  });
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [bookingNotify, setBookingNotify] = useState(false);
  const [getTrustId, setTrustId] = useState();
  const [getHospitalId, setHospitalId] = useState();
  const [speciality, setSpeciality] = useState([]);
  const [trust, setTrust] = useState([]);
  const [hospitalList, setHospitalList] = useState();
  const [wardList, setWardList] = useState([]);
  const [staff, setStaff] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [multipleCandidate, setMultipleCandidates] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");

  if (
    localStorage.getItem("pageNo") !== undefined &&
    page != localStorage.getItem("pageNo")
  ) {
    setPage(localStorage.getItem("pageNo"));
  }

  if (
    localStorage.getItem("tabStatus") !== undefined &&
    activeIndex != localStorage.getItem("tabStatus")
  ) {
    setActiveIndex(localStorage.getItem("tabStatus"));
  }

  useEffect(() => {
    if (
      localStorage.getItem("pageNo") !== undefined &&
      page != localStorage.getItem("pageNo")
    ) {
      localStorage.setItem("pageNo", page);
    }
  }, [page]);

  useEffect(() => {
    if (
      localStorage.getItem("tabStatus") == undefined &&
      activeIndex != localStorage.getItem("tabStatus")
    ) {
      localStorage.setItem("tabStatus", activeIndex);
    }
  }, [activeIndex]);

  const { bookingItem, loading, shiftStatusSuccess, getFilterDataSuccess } =
    useSelector((state) => state.booking);

  const [bookingStatus, setBookingStatus] = useState({
    booking_id: "",
    status: "",
  });

  const [pdfData, setPdfData] = useState({
    id: [],
  });
  const [bookingData, setBookingData] = useState({
    id: [],
    refId: [],
  });

  const trustHandleChange = (event) => {
    setTrustId(event.target.value);
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
    gethospital();
  };
  const hospitalHandleChange = (event) => {
    setHospitalId(event.target.value);
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
    getWardType(event.target.value);
  };

  const getSpecialities = async () => {
    dispatch(
      getSpecShiftCreate((result) => {
        if (result?.status === true) {
          setSpeciality(result);
        }
      })
    );
  };

  const getTrust = async () => {
    dispatch(
      getBookingTrust((result) => {
        if (result?.status === true) {
          setTrust(result);
        }
      })
    );
  };
  const gethospital = async () => {
    if (getTrustId) {
      dispatch(
        getHospitalList(getTrustId, (result) => {
          if (result?.status === true) {
            setHospitalList(result);
          }
        })
      );
    }
  };
  const getWardType = async (hospitalId) => {
    if (hospitalId && getTrustId) {
      dispatch(
        getWardByHospital(hospitalId, getTrustId, (result) => {
          if (result?.status === true) {
            setWardList(result);
          }
        })
      );
    }
  };
  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
        }
      })
    );
  };

  const getCandidates = async () => {
    dispatch(
      getCandidateList((result) => {
        if (result?.data) {
          setCandidates(result);
        }
      })
    );
  };

  const getBookingList = (
    pageNo = 1,
    search = "",
    status = 0,
    specialty = "",
    customer = "",
    site = "",
    ward = "",
    consultants = "",
    candidate = "",
    start_date = "",
    end_date = "",
    provisional_status = "",
    type = order,
    column_name = orderBy
  ) => {
    dispatch(
      getBooking({
        pageNo,
        search,
        status,
        specialty,
        customer,
        site,
        ward,
        consultants,
        candidate,
        start_date,
        end_date,
        provisional_status,
        type,
        column_name,
      })
    );
  };

  const onhandlClick = (id) => {
    history.push(`${match.url}/${id}/detail`);
  };

  const handleChangePage = (pageNo, value) => {
    let status = tabList[activeIndex];
    setPage(value);
    localStorage.setItem("pageNo", value);
    setPdfData(pdfData);
    setBookingData(pdfData);
    setTimeout(
      getBookingList(
        value,
        searchData.search,
        status,
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date
          ? moment(searchData?.start_date).format("DD-MM-yyyy")
          : startDate,
        searchData?.end_date
          ? moment(searchData?.end_date).format("DD-MM-yyyy")
          : endDate,
        searchData?.provisional_status
      ),
      2000
    );
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    let status = tabList[activeIndex];
    if (d1) {
      setPage(1);
      setTimeout(
        getBookingList(
          1,
          d1,
          status,
          searchData?.specialty,
          searchData?.customer,
          searchData?.site,
          searchData?.ward,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date
            ? moment(searchData?.start_date).format("DD-MM-yyyy")
            : startDate,
          searchData?.end_date
            ? moment(searchData?.end_date).format("DD-MM-yyyy")
            : endDate,
          searchData?.provisional_status
        ),
        100
      );
    } else {
      setTimeout(
        getBookingList(
          page,
          "",
          status,
          searchData?.specialty,
          searchData?.customer,
          searchData?.site,
          searchData?.ward,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date
            ? moment(searchData?.start_date).format("DD-MM-yyyy")
            : startDate,
          searchData?.end_date
            ? moment(searchData?.end_date).format("DD-MM-yyyy")
            : endDate,
          searchData?.provisional_status,
          order,
          orderBy
        ),
        100
      );
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const handleClickSearch = (event, value) => {
    let status = tabList[activeIndex];
    setPage(1);
    handleMenuClose(true);
    console.log("searchData?.end_date", searchData?.end_date, endDate);
    console.log("searchData?.end_date", searchData?.start_date, startDate);
    setTimeout(
      getBookingList(
        1,
        searchData.search,
        status,
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date
          ? moment(searchData?.start_date).format("DD-MM-yyyy")
          : moment(startDate).format("DD-MM-yyyy"),
        searchData?.end_date
          ? moment(searchData?.end_date).format("DD-MM-yyyy")
          : moment(endDate).format("DD-MM-yyyy"),
        searchData?.provisional_status,
        order,
        orderBy
      ),
      1000
    );
  };
  const handleClickSave = async () => {
    dispatch(createFilter(searchData, "Bookings"));
    getFilterDataByModule();
    let status = tabList[activeIndex];
    setPage(1);
    if (getFilterDataSuccess?.data?.start_date) {
      setSearchData({
        ...searchData,
        start_date: getFilterDataSuccess?.data?.start_date,
      });
      setStartDate(getFilterDataSuccess?.data?.start_date);
    }
    if (getFilterDataSuccess?.data?.end_date) {
      setSearchData({
        ...searchData,
        end_date: getFilterDataSuccess?.data?.end_date,
      });
      setEndDate(getFilterDataSuccess?.data?.end_date);
    }

    let iStartDate = searchData?.start_date
      ? moment(searchData?.start_date).format("DD-MM-yyyy")
      : "";
    let sStartDate = getFilterDataSuccess?.data?.start_date
      ? moment(getFilterDataSuccess?.data?.start_date).format("DD-MM-yyyy")
      : "";
    iStartDate = iStartDate !== "" ? iStartDate : sStartDate;
    setStartDate(iStartDate);
    let iEndDate = searchData?.end_date
      ? moment(searchData?.end_date).format("DD-MM-yyyy")
      : "";
    let sEndDate = getFilterDataSuccess?.data?.end_date
      ? moment(getFilterDataSuccess?.data?.end_date).format("DD-MM-yyyy")
      : "";
    iEndDate = iEndDate !== "" ? iEndDate : sEndDate;
    setEndDate(iEndDate);

    setTimeout(
      getBookingList(
        1,
        searchData?.search
          ? searchData?.search
          : getFilterDataSuccess?.data?.search,
        status,
        searchData?.specialty
          ? searchData?.specialty
          : getFilterDataSuccess?.data?.specialty,
        searchData?.customer
          ? searchData?.customer
          : getFilterDataSuccess?.data?.customer,
        searchData?.site ? searchData?.site : getFilterDataSuccess?.data?.site,
        searchData?.ward ? searchData?.ward : getFilterDataSuccess?.data?.ward,
        searchData?.consultants
          ? searchData?.consultants
          : getFilterDataSuccess?.data?.consultants,
        searchData?.candidate
          ? searchData?.candidate
          : getFilterDataSuccess?.data?.candidate,

        iStartDate,

        iEndDate,

        searchData?.provisional_status
          ? searchData?.provisional_status
          : getFilterDataSuccess?.data?.provisional_status,
        order,
        orderBy
      ),
      1000
    );
    handleMenuClose(true);
  };
  const handleReset = () => {
    let status = tabList[activeIndex];
    let data = {
      search: "",
      specialty: "",
      candidate: "",
      customer: "",
      site: "",
      ward: "",
      consultants: "",
      start_date: "",
      end_date: "",
      provisional_status: "",
      module_name: "bookings",
    };
    setSearchData(data);
    handleMenuClose(true);
    setTimeout(getBookingList(page, "", status), 100);
    dispatch(deleteFilter(data, "bookings")).then(() => {
      setFlag(!flag);
      // const today = new Date();
      // const defaultStartDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Today - 15 days

      // getBookingList({
      //   search: '',
      //   specialty: '',
      //   candidate: '',
      //   customer: '',
      //   site: '',
      //   ward: '',
      //   consultants: '',
      //   start_date: moment(defaultStartDate).format("DD-MM-yyyy"),
      //   end_date: moment(today).format("DD-MM-yyyy"),
      //   provisional_status:'',
      //   module_name:'bookings',
      // })
    });
  };

  const resetBooking = () => {
    setBookingData({ id: [], refId: [] });
  };

  useEffect(() => {
    getSpecialities();
    getTrust();
    gethospital();
    getWardType(getHospitalId);
    getStaff();
    getCandidates();
    getFilterDataByModule();
  }, []);

  useEffect(() => {
    if (startDate) {
      setSearchData({
        ...searchData,
        start_date: startDate,
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setSearchData({
        ...searchData,
        end_date: endDate,
      });
    }
  }, [endDate]);

  useEffect(() => {
    if (getTrustId) {
      gethospital();
    }
  }, [getTrustId]);

  useEffect(() => {
    if (getHospitalId) {
      getWardType(getHospitalId);
    }
  }, [getHospitalId]);

  const tabChange = (index, list) => {
    pdfData.id = [];
    setPage(1);
    localStorage.setItem("pageNo", 1);
    setSearchData({ ...searchData });
    setActiveIndex(index);
    setPdfData({
      id: [],
    });
    setBookingData({
      id: [],
      refId: [],
    });
    localStorage.setItem("tabStatus", index);
    setTimeout(
      getBookingList(
        1,
        searchData?.search,
        list,
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date
          ? moment(searchData?.start_date).format("DD-MM-yyyy")
          : startDate,
        searchData?.end_date
          ? moment(searchData?.end_date).format("DD-MM-yyyy")
          : endDate,
        searchData?.provisional_status,
        order,
        orderBy
      ),
      100
    );
  };

  const downloadPdf = async (booking_id, reference_id) => {
    setLoadings(true);
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/generate-invoice/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", reference_id + ".pdf");
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const deleteBooking = async () => {
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .post(
        `${apiConfigs.API_URL}api/organization/booking/delete`,
        bookingData,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          dispatch(notificationSuccess(response?.data?.message));
          setTimeout(() => {
            getBookingList();
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const dublicateBooking = async (paymentSchemeId) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/dublicate-booking/${paymentSchemeId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          dispatch(notificationSuccess(response?.data?.message));
          setTimeout(() => {
            getBookingList();
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };
  const multipleInvoiceDownload = async () => {
    setLoadings(true);
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(`${apiConfigs.API_URL}api/organization/multiple-invoice`, pdfData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice.pdf");
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        // setTimesheetError("Sorry timesheet upload failed");
      });
  };
  const handleCheckboxClick = (event, ids) => {
    const multiplePdf = JSON.parse(JSON.stringify(pdfData));
    const isChecked = event.target.checked;
    if (isChecked) {
      multiplePdf.id.push(parseFloat(ids));
      setPdfData(multiplePdf);
    } else {
      const newData = multiplePdf.id.filter((item) => item != parseFloat(ids));
      multiplePdf.id = newData;
      setPdfData(multiplePdf);
    }
  };
  const handleBookingCheckboxClick = (event, ids, refIds) => {
    const multiplePdf = JSON.parse(JSON.stringify(bookingData));
    const isChecked = event.target.checked;
    if (isChecked) {
      multiplePdf.id.push(parseFloat(ids));
      multiplePdf.refId.push(parseFloat(refIds));
      setBookingData(multiplePdf);
    } else {
      const newData = multiplePdf.id.filter((item) => item != parseFloat(ids));
      const newDataRef = multiplePdf.refId.filter(
        (item) => item != parseFloat(refIds)
      );
      multiplePdf.id = newData;
      multiplePdf.refId = newDataRef;
      setBookingData(multiplePdf);
    }
  };
  const handleCheckboxAllClick = (event) => {
    const multiplePdf = JSON.parse(JSON.stringify(pdfData));
    const isChecked = event.target.checked;
    if (isChecked && bookingItem?.data?.data?.length > 0) {
      const createArray = [];
      bookingItem?.data?.data.map((row, index) => {
        createArray.push(row.id);
      });
      multiplePdf.id = createArray;
      setPdfData(multiplePdf);
    } else {
      multiplePdf.id = [];
      setPdfData(multiplePdf);
    }
  };
  useEffect(() => {
    if (bookingStatus.booking_id !== "") {
      dispatch(changeShiftStatus(bookingStatus));
      setBookingNotify(true);
      setTimeout(() => {
        getBookingList();
      }, 4000);
    }
  }, [bookingStatus]);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    const today = new Date();
    const defaultStartDate = new Date(
      today.getTime() - 7 * 24 * 60 * 60 * 1000
    ); // Today - 15 days

    if (
      searchData.search === "" &&
      searchData.specialty === "" &&
      searchData.customer === "" &&
      searchData.site === "" &&
      searchData.ward === "" &&
      searchData.consultants === "" &&
      searchData.candidate === "" &&
      searchData.start_date === "" &&
      searchData.end_date === "" &&
      searchData.provisional_status === ""
    ) {
      getFilterDataByModule();
    }
    setAnchorEl(event.currentTarget);
    if (getFilterDataSuccess?.data?.start_date != undefined) {
      setStartDate(getFilterDataSuccess?.data?.start_date);
    } else {
      setStartDate(moment(defaultStartDate).format("YYYY-MM-DD"));
    }
    if (
      getFilterDataSuccess?.data?.start_date != undefined &&
      getFilterDataSuccess?.data?.start_date != "Invalid date"
    ) {
      setEndDate(getFilterDataSuccess?.data?.end_date);
    } else {
      setEndDate(today);
    }
    gethospital();
    getWardType(getHospitalId);
  };

  const getFilterDataByModule = () => {
    let data = {
      search: "",
      specialty: "",
      candidate: "",
      customer: "",
      site: "",
      ward: "",
      consultants: "",
      start_date: "",
      end_date: "",
      provisional_status: "",
      module_name: "bookings",
    };

    dispatch(getFilterData("bookings"));
    if (getFilterDataSuccess?.data) {
      if (getFilterDataSuccess?.data?.specialty !== "") {
        data.specialty = getFilterDataSuccess?.data?.specialty;
      }
      if (getFilterDataSuccess?.data?.customer !== "") {
        data.customer = getFilterDataSuccess?.data?.customer;
        setTrustId(getFilterDataSuccess?.data?.customer);
      }
      if (getFilterDataSuccess?.data?.site !== "") {
        data.site = getFilterDataSuccess?.data?.site;
        gethospital();
        setHospitalId(getFilterDataSuccess?.data?.site);
      }
      if (getFilterDataSuccess?.data?.ward !== "") {
        getWardType(getHospitalId);
        data.ward = getFilterDataSuccess?.data?.ward;
      }
      if (getFilterDataSuccess?.data?.consultants !== "") {
        data.consultants = getFilterDataSuccess?.data?.consultants;
      }
      if (getFilterDataSuccess?.data?.candidate !== "") {
        data.candidate = getFilterDataSuccess?.data?.candidate;
      }
      if (getFilterDataSuccess?.data?.provisional_status) {
        data.provisional_status =
          getFilterDataSuccess?.data?.provisional_status;
      }
      if (getFilterDataSuccess?.data?.start_date) {
        data.start_date = getFilterDataSuccess?.data?.start_date;
        setStartDate(getFilterDataSuccess?.data?.start_date);
      }
      if (getFilterDataSuccess?.data?.end_date) {
        data.end_date = getFilterDataSuccess?.data?.end_date;
        setEndDate(getFilterDataSuccess?.data?.end_date);
      }
      setSearchData(data);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy == property && order == "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSuperAssign = async (bookingIds) => {
    if (bookingIds?.length > 0) {
      setBookingMultiIds(bookingIds);
      setOpen(true);
      const payload = { booking_ids: bookingIds };
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      await axios
        .post(
          `${apiConfigs.API_URL}api/organization/get-search-multiple-booking`,
          payload,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${loggedInUser}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setMultipleCandidates(response?.data?.data);
          }
        })
        .catch((error) => {
          console.error("error.message", error.message);
        });
    }
  };
  const handleClose = (action) => {
    setOpen(false);
  };

  useEffect(() => {
    const today = new Date();
    const defaultStartDate = new Date(
      today.getTime() - 7 * 24 * 60 * 60 * 1000
    ); // Today - 15 days

    let iStartDate = searchData?.start_date
      ? moment(searchData?.start_date).format("DD-MM-yyyy")
      : moment(defaultStartDate).format("DD-MM-yyyy");
    let sStartDate = getFilterDataSuccess?.data?.start_date
      ? moment(getFilterDataSuccess?.data?.start_date).format("DD-MM-yyyy")
      : moment(defaultStartDate).format("DD-MM-yyyy");
    iStartDate = iStartDate !== "" ? iStartDate : sStartDate;
    setStartDate(iStartDate);
    let iEndDate = searchData?.end_date
      ? moment(searchData?.end_date).format("DD-MM-yyyy")
      : moment(today).format("DD-MM-yyyy");
    let sEndDate = getFilterDataSuccess?.data?.end_date
      ? moment(getFilterDataSuccess?.data?.end_date).format("DD-MM-yyyy")
      : moment(today).format("DD-MM-yyyy");
    iEndDate = iEndDate !== "" ? iEndDate : sEndDate;
    setEndDate(iEndDate);
    let status = tabList[activeIndex];
    setTimeout(
      getBookingList(
        page,
        searchData?.search
          ? searchData?.search
          : getFilterDataSuccess?.data?.search,
        status,
        searchData?.specialty
          ? searchData?.specialty
          : getFilterDataSuccess?.data?.specialty,
        searchData?.customer
          ? searchData?.customer
          : getFilterDataSuccess?.data?.customer,
        searchData?.site ? searchData?.site : getFilterDataSuccess?.data?.site,
        searchData?.ward ? searchData?.ward : getFilterDataSuccess?.data?.ward,
        searchData?.consultants
          ? searchData?.consultants
          : getFilterDataSuccess?.data?.consultants,
        searchData?.candidate
          ? searchData?.candidate
          : getFilterDataSuccess?.data?.candidate,
        iStartDate,
        iEndDate,
        searchData?.provisional_status
          ? searchData?.provisional_status
          : getFilterDataSuccess?.data?.provisional_status,
        order,
        orderBy
      ),
      1000
    );
  }, [orderBy, order, flag]);

  let loggedInRole = localStorage.getItem("role");
  loggedInRole = loggedInRole && loggedInRole.replace(/['"]+/g, "");
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {loadings ? (
        <Backdrop className={classes.backdrop} open={loadings}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {bookingNotify && shiftStatusSuccess?.message && (
        <Notification data={shiftStatusSuccess?.message} status="success" />
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ display: "flex", alignItems: "center", width: "24%" }}>
            <SearchIcon
              className={classes.searchIcondet}
              onClick={handleClickSearch}
            />
            <div className={classes.search}>
              <InputBase
                name="search"
                placeholder="Search…"
                onChange={handleSearchChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Tooltip title="Filters">
              <Button onClick={handleMenu}>
                <FilterListIcon />
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={openMenu}
              onClose={handleMenuClose}
              className={classes.filterBookingSearch}
            >
              <div className={classes.filterBox}>
                <Box className={classes.radioGroup}>
                  <Box mt={3} mb={3} display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Specialty</InputLabel>
                        <Select
                          label="Specialty"
                          name="specialty"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.specialty}
                        >
                          <MenuItem value="">Select Specialty</MenuItem>
                          {speciality?.data !== undefined &&
                            speciality?.data &&
                            speciality?.data.map((items, index) => {
                              return (
                                <MenuItem value={items.id} key={index + 55}>
                                  {items.speciality_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Customer</InputLabel>
                        <Select
                          label="Customer"
                          name="customer"
                          onChange={trustHandleChange}
                          value={
                            searchData?.customer
                              ? searchData?.customer
                              : getTrustId
                          }
                        >
                          <MenuItem value="">Select Customer</MenuItem>
                          {trust?.data !== undefined &&
                            trust?.data &&
                            trust?.data.map((trustList, index) => {
                              return (
                                <MenuItem
                                  value={trustList.id}
                                  key={index + 11111}
                                >
                                  {trustList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Site</InputLabel>
                        <Select
                          label="Site"
                          name="site"
                          onChange={hospitalHandleChange}
                          value={
                            searchData?.site ? searchData?.site : getHospitalId
                          }
                        >
                          <MenuItem value="">Select Site</MenuItem>
                          {hospitalList?.data !== undefined &&
                            hospitalList?.data &&
                            hospitalList?.data.map((List, index) => {
                              return (
                                <MenuItem value={List.id} key={index + 1}>
                                  {List.hospital_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Ward</InputLabel>
                        <Select
                          label="Ward"
                          name="ward"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.ward}
                        >
                          <MenuItem value="">Select Ward</MenuItem>
                          {wardList?.data !== undefined &&
                            wardList?.data &&
                            wardList?.data.map((list, index) => {
                              return (
                                <MenuItem value={list.id} key={index + 11}>
                                  {list.ward_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box mt={2} mb={1} display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Consultants</InputLabel>
                        <Select
                          label="Consultants"
                          name="consultants"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.consultants}
                        >
                          <MenuItem value="">Select Consultants</MenuItem>
                          {staff?.data !== undefined &&
                            staff?.data &&
                            staff?.data.map((trustList, index) => {
                              return (
                                <MenuItem
                                  value={trustList.id}
                                  key={index + 111}
                                >
                                  {trustList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <Box className={classes.formControl}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={customEnLocale}
                          localeText={{ start: "Check-in", end: "Check-out" }}
                        >
                          <DatePicker
                            label="Start Date"
                            inputFormat="dd/MM/yyyy"
                            name="start_date"
                            onChange={(newValue) => setStartDate(newValue)}
                            value={
                              startDate !== undefined
                                ? startDate
                                : moment().subtract(7, "d").format("YYYY-MM-DD")
                            }
                            firstDayOfWeek={1}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box className={classes.formControl}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={customEnLocale}
                          localeText={{ start: "Check-in", end: "Check-out" }}
                        >
                          <DatePicker
                            label="End Date"
                            inputFormat="dd/MM/yyyy"
                            name="end_date"
                            onChange={(newValue) => setEndDate(newValue)}
                            value={
                              endDate !== undefined
                                ? endDate
                                : moment().format("YYYY-MM-DD")
                            }
                            firstDayOfWeek={1}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Candidate</InputLabel>
                        <Select
                          label="Candidate"
                          name="candidate"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.candidate}
                        >
                          <MenuItem value="">Select Candidate</MenuItem>
                          {candidates?.data !== undefined &&
                            candidates?.data &&
                            candidates?.data.map((candidateData, index) => {
                              return (
                                <MenuItem
                                  value={candidateData.id}
                                  key={index + 1111}
                                >
                                  {candidateData.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box mt={2} mb={1} display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Provisional Status</InputLabel>
                        <Select
                          label="provisional_status"
                          name="provisional_status"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.provisional_status}
                        >
                          <MenuItem value="">Select Status</MenuItem>
                          <MenuItem value="CONFIRMED" key={1111}>
                            CONFIRMED
                          </MenuItem>
                          <MenuItem value="CREDIT NOTES" key={11111}>
                            CREDIT NOTES
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: "16px", marginLeft: "16px" }}
                          className={classes.searchIcondet}
                          onClick={handleClickSearch}
                        >
                          Search
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>

                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: "16px", marginLeft: "16px" }}
                          className={classes.searchIcondet}
                          onClick={handleClickSave}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Menu>
            {bookingData?.id?.length > 0 && (
              <div className="ml-5">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={deleteBooking}
                >
                  Delete
                </Button>
              </div>
            )}
            {bookingData?.id?.length > 0 && (
              <div className="ml-5">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSuperAssign(bookingData.id)}
                >
                  Super Assignment
                </Button>
              </div>
            )}

            {pdfData?.id?.length > 0 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={multipleInvoiceDownload}
                className={classes.downloadButton}
              >
                <span className="material-icons mr-2">download</span> Download
                PDF{" "}
              </Button>
            )}
            {staffDetail != "Finance" && (
              <div className="ml-5">
                <Link to={`${match.url}/create`} className="btn btn-secondary">
                  <AddIcon className="mr-2" />
                  Add Booking
                </Link>
              </div>
            )}
          </Box>
        </Box>

        <Box className={classes.statusButton}>
          {tabList.map((list, index) => (
            <span
              style={{ textTransform: "capitalize" }}
              className={`btn ${activeIndex == index ? "active" : ""}`}
              key={index + 888}
              onClick={() => tabChange(index, list)}
            >
              {displayTabList[index].toLowerCase()}
            </span>
          ))}
        </Box>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              handleCheckboxAllClick={handleCheckboxAllClick}
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={bookingItem?.data?.data?.length}
              headCells={headCells[activeIndex ? activeIndex : 0]}
              activeIndex={activeIndex}
              pdfDataCount={pdfData?.id?.length}
            />

            <TableBody>
              {bookingItem?.data?.data &&
                bookingItem?.data?.data.map((row, index) => {
                  let userRole =
                    bookingItem?.data?.data[0]["loggedUserDesignation"];
                  userRole = userRole && userRole.replace(/['"]+/g, "");
                  const dateFormate = row.date
                    .toString()
                    .split("-")
                    .reverse()
                    .join("-");
                  const originalDate = row.invoice_date || null;
                  let newdate;
                  if (originalDate) {
                    const parts = originalDate.split(" ");
                    const datePart = parts[0];
                    const timePart = parts[1];
                    const dateParts = datePart.split("-");
                    const day = dateParts[2];
                    const month = dateParts[1];
                    const year = dateParts[0];
                    newdate = `${day}-${month}-${year} ${timePart}`;
                  }

                  return (
                    <TableRow key={index + 666}>
                      {activeIndex == 4 && (
                        <TableCell scope="row">
                          <Checkbox
                            value={row.id}
                            checked={pdfData?.id?.includes(row.id)}
                            onClick={(event) =>
                              handleCheckboxClick(event, row.id)
                            }
                            className="selectCheckbox"
                          />
                        </TableCell>
                      )}
                      {activeIndex == 0 && (
                        <TableCell scope="row">
                          <Checkbox
                            value={row.id}
                            checked={bookingData?.id?.includes(row.id)}
                            onClick={(event) =>
                              handleBookingCheckboxClick(
                                event,
                                row.id,
                                row.reference_id
                              )
                            }
                            className="selectCheckbox"
                          />
                        </TableCell>
                      )}
                      <TableCell scope="row">
                        {bookingItem?.data?.from + index}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.hospital_name}</TableCell>
                      <TableCell align="left">{row.ward_name}</TableCell>
                      {activeIndex > 0 && (
                        <TableCell align="left">{row.candidate_name}</TableCell>
                      )}
                      <TableCell align="left">{row.grade_name}</TableCell>
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        {dateFormate}
                      </TableCell>
                      {activeIndex == 4 && (
                        <TableCell align="right">{newdate}</TableCell>
                      )}
                      <TableCell align="left">
                        {row.start_time} <br /> {row.end_time}
                      </TableCell>
                      <TableCell align="left">{row.createdByName} </TableCell>

                      {activeIndex == 4 && (
                        <TableCell align="right">
                          {loggedInRole === "ORGANIZATION" ||
                          userRole === "Admin" ||
                          userRole === "Finance" ? (
                            <Link
                              to="#"
                              onClick={() =>
                                downloadPdf(row.id, row.invoice_number)
                              }
                              className="btn btn-secondary"
                            >
                              Download
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              component="button"
                              variant="contained"
                              disabled
                              color="secondary"
                              style={{ cursor: "no-drop" }}
                              onClick={() =>
                                downloadPdf(row.id, row.invoice_number)
                              }
                              className="btn "
                            >
                              Download
                            </Link>
                          )}
                        </TableCell>
                      )}
                      {activeIndex == 0 && (
                        <TableCell className="mr-0">
                          <Link to="#" onClick={() => dublicateBooking(row.id)}>
                            <FileCopyOutlinedIcon />
                          </Link>
                        </TableCell>
                      )}

                      <TableCell className="ml-0" align="right">
                        <Link
                          to="#"
                          onClick={() => onhandlClick(row.id)}
                          className="btn btn-secondary"
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!bookingItem?.data && (
                <TableRow>
                  <TableCell scope="row" colSpan="15">
                    <div className="" align="center">
                      Sorry, booking not available!
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={
              page <= bookingItem?.data?.last_pag
                ? page
                : parseInt(localStorage.getItem("pageNo"))
            }
            count={bookingItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
      <SuperAssign
        open={open}
        handleClose={handleClose}
        resetBooking={resetBooking}
        bookingMultiIds={bookingMultiIds}
        bookingMultiRefs={bookingData.refId}
        getBookingList={() => getBookingList()}
        multipleCandidate={multipleCandidate}
      />
    </>
  );
};

export default ViewBooking;
