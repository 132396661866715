import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { addNotificationPreference } from "../../store/action/notificationPreference/notificationPreferenceAction";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const CreateNotificationPreference = ({ open, handleClose }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [roleMsg, setRoleMsg] = useState(false);
  const { NotificationPrefsErrors, NotificationPrefsSuccess } = useSelector(
    (state) => state.notificationPrefs
  );
  const [data, setData] = useState({
    title: "",
  });

  
  const [formError, setError] = useState([]);
  const handleChange = (event) => {
    if (event.target.value) {
      setError([]);
    } else {
      setError("err_is_active");
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSubmitRoles = async (event) => {
    event.preventDefault();
    if (data.title === "") {
      setError("err_title");
    } else {
      dispatch(addNotificationPreference(data));
      setRoleMsg(true);
      handleClose();
    }
  };
  const closeModal = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, title: "" });
  };

  return (
    <>
      {roleMsg && NotificationPrefsErrors?.message && (
        <Notification data={NotificationPrefsErrors?.message} status="error" />
      )}
      {roleMsg && NotificationPrefsSuccess?.message && (
        <Notification data={NotificationPrefsSuccess?.message} status="success" />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitRoles(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Create Notification Preference</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="title"
                  type="text"
                  label="Title"
                  variant="outlined"
                  name="title"
                  fullWidth
                  required
                  value={data.title || ""}
                  error={formError.includes("err_title") ? true : false}
                  helperText={
                    formError.includes("err_title")
                      ? "Please enter title"
                      : false
                  }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateNotificationPreference;
