import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Notification from "../../components/Notification/Notification";

import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import history from "../../utils/HistoryUtils";
import {changepasswordByAdmin} from '../../store/action';

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  formControl: {
    width: "100%",
    "& > .MuiFormHelperText-root": {
      color: "red",
    },
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ChangePassword = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [resetMsg, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);
  const {staffUpdateSuccess,staffUpdateError} = useSelector(
    (state) => state.staff
  );
  const dId = btoa(match.params.id);
  const { changesuccess, changeerrors } = useSelector(
    (state) => state.authReducer
  );
  
  const [data, setData] = useState({
    password: "",
    confirm_password:""
   });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const {
    formState: { errors },
    reset,
  } = useForm();

  const staffChangePasswordSubmit=(e)=>{
    e.preventDefault();
    data.decode_id = dId;
    dispatch(changepasswordByAdmin(data));
    setStaffNotify(true);
    setTimeout(() => {
      backPage();
    }, 3000);
    
  }
 
  const backPage = () => {
    history.goBack();
  };
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {staffNotify && changesuccess?.message && (
        <Notification
          data={
            changesuccess?.message
              ? changesuccess?.message
              : ""
          }
          status="success"
        />
      )}

      {staffNotify && changeerrors?.message &&
                <Notification
                    data={changeerrors?.message}
                    status="error"
                />
      }
      <Paper className={classes.root}>
        <h2>Change Password</h2>
        <form onSubmit={(e) => staffChangePasswordSubmit(e)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
              type="password"
                id="password"
                label="Password"
                variant="outlined"
                name="password"
                value={data?.password ? data?.password : ""}
                onChange={handleChange}
                InputProps={{
                  autoComplete: 'off',
                }}
                //helperText={changeerrors?.message}
                //error={!!changeerrors?.message}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
              type="password"
                id="confirm_password"
                label="Confirm Password"
                variant="outlined"
                name="confirm_password"
                value={data?.confirm_password}
                InputProps={{
                  autoComplete: 'off',
                }}
               // helperText={changeerrors?.message}
                //error={!!changeerrors.message}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            
          </Grid>

          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              formNoValidate
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ChangePassword;
