import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getComplianceItem :[],
  getComplianceError: [],

  getSingleComplianceItem: [],
  getSingleCoplianceError: [],

  
  
  getCandidateReferrredForm: [],

  signeeProStatusSuccess: [],
  signeeProStatusError: [],

  signeeComStatusSuccess: [],
  signeeComStatusError: [],

  changeDocStatusSuccess: [],
  changeDocStatusError: [],

  getCandidateEventItem: [],
  getCandidateEventError: [],

  addCandidateEventSuccess: [],
  addCandidateEventError: [],

  addCandidateRemarkSuccess: [],
  addCandidateRemarkError: [],

  addDocExpireDateSuccess: [],
  addDocExpireDateError: [],
  deleteDocumentSuccess: [],
  deleteDocumentError: [],
  
  getVCFilterDataSuccess: [],
};

const ComplianceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPLIANCE_REQUETS:
      return updateObject(state, {
        loading: true,
      });

    case actionTypes.GET_COMPLIANCE_SUCCESS:
      return updateObject(state, {
        loading: false,
        getComplianceItem: action.payload,
      });


    // case actionTypes.GET_VC_FILTER_DATA_REQUEST:
    //   return updateObject(state, {
    //     loading: true,
    // });

    // case actionTypes.GET_VC_FILTER_DATA_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     getVCFilterDataSuccess: action.payload,
    // });
    // case actionTypes.GET_COMPLIANCE_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     getComplianceError: action.payload,
    //   });

    // case actionTypes.CREATE_COMPLIANCE_REQUETS:
    //   return updateObject(state, {
    //     loading: true,
    //     createComplianceSuccess: "",
    //     createComplianceError: "",
    //   });

    
    // case actionTypes.GET_COMPLIANCE_REQUETS:
    //   return updateObject(state, {
    //     loading: true,
    //     getComplianceItem: "",
    //     getComplianceError: "",
    //   });

    // case actionTypes.GET_SINGLE_COMPLIANCE_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     getComplianceSigneeItem: action.payload,
    //   });

    // case actionTypes.GET_SINGLE_COMPLIANCE_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     getComplianceSigneeError: action.payload,
    //   });

   

   
    // case actionTypes.GET_CANDIDATE_REFERRED_FROM_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     getCandidateReferrredForm: action.payload,
    //   });

    // -------------------------------------------

    // case actionTypes.CHANGE_SIGNEE_PRO_STATUS_REQUEST:
    //   return updateObject(state, {
    //     loading: true,
    //     signeeProStatusSuccess: "",
    //     signeeProStatusError: "",
    //   });

    // case actionTypes.CHANGE_SIGNEE_PRO_STATUS_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     signeeProStatusSuccess: action.payload,
    //   });

    // case actionTypes.CHANGE_SIGNEE_PRO_STATUS_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     signeeProStatusError: action.payload,
    //   });

    // -------------------------------------------

    // case actionTypes.CHANGE_SIGNEE_COMP_STATUS_REQUEST:
    //   return updateObject(state, {
    //     loading: false,
    //     signeeComStatusSuccess: "",
    //     signeeComStatusError: "",
    //   });

    // case actionTypes.CHANGE_SIGNEE_COMP_STATUS_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     signeeComStatusSuccess: action.payload,
    //   });

    // case actionTypes.CHANGE_SIGNEE_COMP_STATUS_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     signeeComStatusError: action.payload,
    //   });
    // -------------------------------------------

    // case actionTypes.CHANGE_DOC_STATUS_REQUEST:
    //   return updateObject(state, {
    //     loading: false,
    //     changeDocStatusSuccess: "",
    //     changeDocStatusError: "",
    //   });

    // case actionTypes.CHANGE_DOC_STATUS_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     changeDocStatusSuccess: action.payload,
    //   });

    // case actionTypes.CHANGE_DOC_STATUS_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     changeDocStatusError: action.payload,
    //   });

    // case actionTypes.GET_CONTACT_EVENT_REQUETS:
    //   return updateObject(state, {
    //     loading: true,
    //     getCandidateEventItem: "",
    //     getCandidateEventError: "",
    //   });

    // case actionTypes.GET_CONTACT_EVENT_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     getCandidateEventItem: action.payload,
    //   });

    // case actionTypes.GET_CONTACT_EVENT_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     getCandidateEventError: action.payload,
    //   });

    // case actionTypes.ADD_CONTACT_EVENT_REQUETS:
    //   return updateObject(state, {
    //     loading: true,
    //     addCandidateEventSuccess: "",
    //     addCandidateEventError: "",
    //   });

    // case actionTypes.ADD_CONTACT_EVENT_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     addCandidateEventSuccess: action.payload,
    //   });

    // case actionTypes.ADD_CONTACT_EVENT_ERROR:
    //   return updateObject(state, {
    //     loading: false,
    //     addCandidateEventError: action.payload,
    //   });

    //    case actionTypes.ADD_SIGNEE_REMARK_REQUETS:
    //     return updateObject(state, {
    //       loading: true,
    //       addCandidateRemarkSuccess: "",
    //       addCandidateRemarkError: "",
    //     });
  
    //   case actionTypes.ADD_SIGNEE_REMARK_SUCCESS:
    //     return updateObject(state, {
    //       loading: false,
    //       addCandidateRemarkSuccess: action.payload,
    //     });
  
    //   case actionTypes.ADD_SIGNEE_REMARK_ERROR:
    //     return updateObject(state, {
    //       loading: false,
    //       addCandidateRemarkError: action.payload,
    //     });

    //   case actionTypes.ADD_DOC_EXPIRE_DATE_REQUETS:
    //     return updateObject(state, {
    //       loading: true,
    //       addDocExpireDateSuccess: "",
    //       addDocExpireDateError: "",
    //     });
  
    //   case actionTypes.ADD_DOC_EXPIRE_DATE_SUCCESS:
    //     return updateObject(state, {
    //       loading: false,
    //       addDocExpireDateSuccess: action.payload,
    //     });
  
    //   case actionTypes.ADD_DOC_EXPIRE_DATE_ERROR:
    //     return updateObject(state, {
    //       loading: false,
    //       addDocExpireDateError: action.payload,
    //     });
        
    //   case actionTypes.DELETE_DOCUMENT_REQUETS:
    //     return updateObject(state, {
    //       loading: true,
    //       deleteDocumentError: "",
    //       deleteDocumentSuccess: "",
    //     });
  
    //   case actionTypes.DELETE_DOCUMENT_SUCCESS:
    //     return updateObject(state, {
    //       loading: false,
    //       deleteDocumentSuccess: action.payload,
    //     });
  
    //   case actionTypes.DELETE_DOCUMENT_ERROR:
    //     return updateObject(state, {
    //       loading: false,
    //       deleteDocumentError: action.payload,
    //     });

    default:
      return state;
  }
};

export default ComplianceReducer;
