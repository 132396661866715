import React, { useState } from "react";
import clsx from "clsx";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Delete as DeleteIcon, ViewColumn } from "@material-ui/icons";

function EnhancedTableToolbar(props) {
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            // backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));

  const classes = useToolbarStyles();
  const { numSelected, visibleColumns, handleColumnToggle } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div>
        <Tooltip title="Visible Columns">
          <Button onClick={handleFilterClick}>
            <ViewColumn />
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
        >
          {props.headCells.map((headCell) => (
            <MenuItem key={headCell.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleColumns[headCell.id]}
                    onChange={() => handleColumnToggle(headCell.id)}
                  />
                }
                label={headCell.label}
              />
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
