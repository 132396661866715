import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSignee, getCandidateReferredFrom } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import axios from "axios";
import apiConfigs from "../../config/config";
import history from "../../utils/HistoryUtils";
import UtilService from "../../helper/service";
import { getStaffDetail,  getCompilanceOfficerDetail} from "../../store/action/staff/staffAction";
import { getUmbrellDetail } from "../../store/action/umbrella/umbrellaAction";
import { useGooglePlaceAutoCompleteForCandidate } from "../../components/GoogleAPI/google_place_autocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  radioGroup: {
    flexDirection: "row",
  },
  formControl: {
    width: "100%",
  },
  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const CreateSignee = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    createSigneeSuccess,
    createSigneeError,
  } = useSelector((state) => state.signee);
  const [signeeNotify, setSigneeNotify] = useState(false);

  // for today date selection
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();
  const materialDateInput = `${year}-${month}-${date}`;
  const disFutureDate = UtilService.disabledPastDate();
  const [staff, setStaff] = useState([]);
  const [compilanceOfficer, setCompilanceOfficer] = useState([]);
  
  const [umbrella, setUmbrella] = useState([]);
  // for today date selection

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    date_of_birth: "",
    candidate_id: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    city: "",
    postcode: "",
    email: "",
    // candidate_referred_from:"",
    nationality: "",
    date_registered: materialDateInput,
    organization_id: "",
    nmc_dmc_pin: "",
    gender: "Male",
    registration_status: "No Restriction",
    payment_method: "PAYE",
    npm_revalidation_date: "",
    revalidation_due_date: "",
    apprisal_due_date: "",
    hrmc_engagement_reason: "",
    staff_id: "",
    name_of_bank: "",
    umbrella_id: "",
    account_number: "",
    sort_code: "",
    ni_number: "",
    speciality: [],
    name_of_kin: "",
    address_of_kin: "",
    contact_of_kin: "",
    econtact_of_kin: "",
    latitude:"",
    longitude:"",
    compilance_officer_id:"",
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleChangeDate = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
 const dateHandler = (name,date)=>{
    setData({ ...data, [name]: date });
  }
  const handleChangeReValidDueDate = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeAprisalDueDate = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleUmbrellaChange = (event) => {
    let umbrelaDetils = umbrella?.data.find((e) => e.id == event.target.value);
    if (umbrelaDetils != null && umbrelaDetils != undefined) {
      data.umbrella_id = umbrelaDetils.id;
      data.name_of_bank = umbrelaDetils.bank_name;
      data.sort_code = umbrelaDetils.bank_sort_code;
      data.account_number = umbrelaDetils.bank_account_number;
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const getCandidateId = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-candidate`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setData({
          ...data,
          candidate_id: parseFloat(response.data.data.candidate_id),
        });
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };

  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
        }
      })
    );
  };

  const getCompilanceOfficer = async () => {
    dispatch(
      getCompilanceOfficerDetail((result) => {
        if (result?.data) {
          setCompilanceOfficer(result);
        }
      })
    );
  };

  const getUmbrella = async () => {
    dispatch(
      getUmbrellDetail((result) => {
        if (result?.data) {
          setUmbrella(result);
        }
      })
    );
  };

  const signeeSubmitData = () => {
    let payload = {
      ...data,
       date_of_birth:data?.date_of_birth ? moment(data?.date_of_birth).format("YYYY-MM-DD") : "",
       npm_revalidation_date:data?.npm_revalidation_date ?  moment(data?.npm_revalidation_date).format("YYYY-MM-DD") :"",
       revalidation_due_date:data?.revalidation_due_date ?  moment(data?.revalidation_due_date).format("YYYY-MM-DD") :"",
       apprisal_due_date:data?.apprisal_due_date ?  moment(data?.apprisal_due_date).format("YYYY-MM-DD") :"",
date_registered:data?.date_registered ?  moment(data?.date_registered).format("YYYY-MM-DD") :""
      }
    dispatch(createSignee(payload));
    setSigneeNotify(true);
  };

  const backPage = () => {
    history.goBack();
  };
  const address1Ref = useRef();
  const googleAutoCompleteSvc = useGooglePlaceAutoCompleteForCandidate();
  let autoComplete = "";

  const handleAddressSelect = async () => {
      let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
      setData((prevData) => ({
      ...prevData,
        city: addressObj.locality,
        address_line_2: addressObj.address_line_2,
        postcode: addressObj.postcode,
        address_line_1: addressObj.address_line_1,
        latitude: addressObj.latitude,
        longitude: addressObj.longitude,
      }));
  };

  useEffect(() => {
    getCandidateId();
    getStaff();
    getCompilanceOfficer();
    getUmbrella();
    dispatch(getCandidateReferredFrom());
    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element.
      // eslint-disable-next-line
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(address1Ref.current, handleAddressSelect);
    }
    loadGoogleMaps();
  }, []);

  return (
    <>
      {signeeNotify && createSigneeSuccess?.message && (
        <Notification data={createSigneeSuccess?.message} status="success" />
      )}
      {signeeNotify && createSigneeError?.message && (
        <Notification data={createSigneeError?.message} status="error" />
      )}
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(signeeSubmitData)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="candidate_id"
                label="Candidate Id"
                variant="outlined"
                name="candidate_id"
                value={data?.candidate_id}
                onChange={handleChange}
                fullWidth
                // required
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="first_name"
                label="First name"
                variant="outlined"
                name="first_name"
                // value={data?.first_name}
                {...register("first_name", {
                  required: "Please enter first name",
                })}
                error={errors.first_name ? true : false}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="last_name"
                label="Last name"
                variant="outlined"
                name="last_name"
                // value={data?.last_name}
                {...register("last_name", {
                  required: "Please enter last name",
                })}
                error={errors.last_name ? true : false}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                name="email"
                // value={data?.email}
                {...register("email", {
                  required: "Please enter email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
                error={errors.email ? true : false}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="contact_number"
                label="Contact number"
                variant="outlined"
                name="contact_number"
                type="text"
                value={data?.contact_number}
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
                id="date_of_birth"
                value={data?.date_of_birth || "01-01-1970"}
                label="Date of birth"
                type="date"
                name="date_of_birth"
                variant="outlined"
                onChange={handleChangeDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                inputProps={{
                  max: disFutureDate,
                }}
                // required
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          id="date_of_birth"                         
                          value={data?.date_of_birth || "01-01-1970"}
                          label="Date of birth"
                          type="date"
                          name="date_of_birth"
                          variant="outlined"
                          onChange={(e) =>dateHandler("date_of_birth",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField  
                            fullWidth
                inputProps={{
                  max: disFutureDate,
                }} variant="outlined"  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="address_line_1"
                label="Address line 1"
                variant="outlined"
                type="text"
                name="address_line_1"
                className="form-field"
                {...register("address_line_1", { required: true })}
                value={data?.address_line_1}
                inputRef={address1Ref}
                fullWidth
                onChange={handleChange}
                error={errors.address_line_1 ? true : false}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                // required
                error={errors.gender ? true : false}
              >
                <InputLabel>Gender </InputLabel>
                <Select
                  value={data.gender}
                  name="gender"
                  label="gender Required"
                  onChange={handleChange}
                >
                  <MenuItem value="Male" key="Male">
                    {" "}
                    Male
                  </MenuItem>
                  <MenuItem value="Female" key="Female">
                    {" "}
                    Female
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="address_line_2"
                label="Address line 2"
                variant="outlined"
                name="address_line_2"
                {...register("address_line_2")}
                onChange={handleChange}
                fullWidth
                value={data?.address_line_2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="City"
                label="City"
                variant="outlined"
                name="city"
                {...register("city")}
                fullWidth
                value={data?.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="postcode"
                label="Postcode"
                variant="outlined"
                name="postcode"
                fullWidth
                {...register("postcode")}
                value={data?.postcode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="nationality"
                label="Nationality"
                variant="outlined"
                name="nationality"
                value={data?.nationality}
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <TextField
                id="date_registered"
                label="Date Registered"
                type="date"
                name="date_registered"
                variant="outlined"
                defaultValue={materialDateInput}
                value={data?.date_registered}
                onChange={handleChange}
                // InputProps={{
                //   readOnly: true,
                // }}
                fullWidth
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                         id="date_registered"
                label="Date Registered"
                type="date"
                name="date_registered"
                variant="outlined"                      
                          value={data?.date_registered}
                defaultValue={materialDateInput}
                         
                          onChange={(e) =>dateHandler("date_registered",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField  
                            fullWidth
                           variant="outlined"  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="nmc_dmc_pin"
                label="Registered Body Pin"
                variant="outlined"
                name="nmc_dmc_pin"
                value={data?.nmc_dmc_pin}
               
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.staff_id ? true : false}
              >
                <InputLabel>Consultant</InputLabel>
                <Select
                  value={data.staff_id}
                  label="Consultant"
                  name="staff_id"
                  {...register("staff_id", {
                    required: "The Staff field is required.",
                  })}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select Consultant</MenuItem>
                  {staff?.data &&
                    staff?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
                id="npm_revalidation_date"
                label="SOE Registration Date"
                type="date"
                name="npm_revalidation_date"
                variant="outlined"
                onChange={handleChangeDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          id="npm_revalidation_date"
                          label="SOE Registration Date"
                          type="date"
                          name="npm_revalidation_date"
                          variant="outlined"
                          value={data.npm_revalidation_date}
                          onChange={(e) =>dateHandler("npm_revalidation_date",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
                id="revalidation_due_date"
                label="Revalidation Due Date"
                type="date"
                name="revalidation_due_date"
                variant="outlined"
                onChange={handleChangeReValidDueDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          id="revalidation_due_date"
                          label="Revalidation Due Date"
                          type="date"
                          name="revalidation_due_date"
                          variant="outlined"
                          value={data.revalidation_due_date}
                          onChange={(e) =>dateHandler("revalidation_due_date",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"               
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <TextField
                id="apprisal_due_date"
                label="Appraisal Due Date"
                type="date"
                name="apprisal_due_date"
                variant="outlined"
                onChange={handleChangeAprisalDueDate}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          id="apprisal_due_date"
                          label="Appraisal Due Date"
                          type="date"
                          name="apprisal_due_date"
                          variant="outlined"
                          value={data.apprisal_due_date}
                          onChange={(e) =>dateHandler("apprisal_due_date",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"               
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                //required
                error={errors.registration_status ? true : false}
              >
                <InputLabel>Registration Status</InputLabel>
                <Select
                  value={data.registration_status}
                  name="registration_status"
                  label="Registration Status"
                  onChange={handleChange}
                >
                  <MenuItem value="No Restriction" key="No Restriction">
                    {" "}
                    No Restriction
                  </MenuItem>
                  <MenuItem value="Caution Order" key="Caution Order">
                    {" "}
                    Caution Order
                  </MenuItem>
                  <MenuItem value="Restricted" key="Restricted">
                    {" "}
                    Restricted
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={errors.compilance_officer_id ? true : false}
              >
                <InputLabel>Compliance Officer</InputLabel>
                <Select
                  value={data.compilance_officer_id}
                  label="Compliance Officer"
                  name="compilance_officer_id"
                  onChange={handleChange}
                >
                  <MenuItem value="">Select Compliance Officer</MenuItem>
                  {compilanceOfficer?.data &&
                    compilanceOfficer?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>                                                                                                                                                                                                                      
            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>
                Payroll Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box className="mt-3">
                <Typography>Payment Method</Typography>
                <RadioGroup
                  name="payment_method"
                  value={data?.payment_method || ""}
                  onChange={handleChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="PAYE"
                    control={<Radio />}
                    label="PAYE"
                  />
                  <FormControlLabel
                    value="Umbrella"
                    control={<Radio />}
                    label="Umbrella"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={errors.umbrella_id ? true : false}
              >
                <InputLabel>Umbrella Name</InputLabel>
                <Select
                  value={data.umbrella_id}
                  label="Umbrella Name"
                  name="umbrella_id"
                  {...register("umbrella_id", {
                    required:
                      data?.payment_method === "Umbrella" ? true : false,
                  })}
                  // required={data?.payment_method === "Umbrella" ? true : false}
                  disabled={data?.payment_method === "PAYE" ? true : false}
                  onChange={handleUmbrellaChange}
                >
                  <MenuItem value="">Select Umbrella</MenuItem>
                  {umbrella?.data &&
                    umbrella?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name_umbrella}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                // required
                // error={errors.hrmc_engagement_reason ? true : false}
              >
                <InputLabel>HRMC Engagement Reason </InputLabel>
                <Select
                  value={data.hrmc_engagement_reason}
                  name="hrmc_engagement_reason"
                  label="HRMC Engagement Reason  Required"
                  onChange={handleChange}
                >
                  <MenuItem value="Self Employed" key="Self Employed">
                    {" "}
                    Self Employed
                  </MenuItem>
                  <MenuItem value="Partnership" key="Partnership">
                    {" "}
                    Partnership
                  </MenuItem>
                  <MenuItem
                    value="Limited Liability Partner"
                    key="Limited Liability Partner"
                  >
                    {" "}
                    Limited Liability Partner
                  </MenuItem>
                  <MenuItem
                    value="Ltd co inclu Personal Service"
                    key="Ltd co inclu Personal Service"
                  >
                    {" "}
                    Ltd co inclu Personal Service
                  </MenuItem>
                  <MenuItem value="Non-UK engagement" key="Non-UK engagement">
                    {" "}
                    Non-UK engagement
                  </MenuItem>
                  <MenuItem value="Another party PAYE" key="Another party PAYE">
                    {" "}
                    Another party PAYE
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="name_of_bank"
                label="Name of Bank"
                variant="outlined"
                name="name_of_bank"
                type="text"
                value={data?.name_of_bank}
                disabled={data?.payment_method === "Umbrella" ? true : false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="account_number"
                label="Account Number"
                variant="outlined"
                name="account_number"
                value={data?.account_number || ""}
                type="text"
                disabled={data?.payment_method === "Umbrella" ? true : false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="sort_code"
                label="Sort Code"
                variant="outlined"
                name="sort_code"
                value={data?.sort_code || ""}
                type="text"
                disabled={data?.payment_method === "Umbrella" ? true : false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="ni_number"
                label="NI Number"
                variant="outlined"
                name="ni_number"
                type="text"
                value={data?.ni_number || ""}
                required
                {...register("ni_number", {
                  required: "Please enter ni number",
                })}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>
                Next of Kin
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="name_of_kin"
                label="Name"
                variant="outlined"
                name="name_of_kin"
                type="text"
                value={data?.name_of_kin}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="address_of_kin"
                label="Address"
                variant="outlined"
                name="address_of_kin"
                value={data?.address_of_kin || ""}
                type="text"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="contact_of_kin"
                label="Contact Number"
                variant="outlined"
                name="contact_of_kin"
                value={data?.contact_of_kin || ""}
                type="text"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="econtact_of_kin"
                label="Emergency Contact Number"
                variant="outlined"
                name="econtact_of_kin"
                type="text"
                value={data?.econtact_of_kin || ""}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            

          </Grid>
          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              formNoValidate
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default CreateSignee;
