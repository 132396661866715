import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import Config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../components/Notification/Notification";
import { updateNotificationPrefs,getNotificationPrefsById } from "../../store/action/notificationPreference/notificationPreferenceAction";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const UpdateNotificationPreference = ({ openUpdate, handleClose, id }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateMsg, setUpdateMsg] = useState(false);
  const { NotificationPrefsSuccess } = useSelector((state) => state.notificationPrefs);

  const [data, setData] = useState({
    title: "",
    pref_id: id,
  });

  
  const getData = async (id) => {
    if (id > 0) {
      try {
        dispatch(
          getNotificationPrefsById(id, (result) => {
            if (result?.status === true) {
              setData(result?.data);
            }
          })
        );
      } catch (error) {
        console.error("error.message", error.message);
      }
    }
  };
  useEffect(() => {
    setData("");
    getData(id);
  }, [id]);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const submitNotificationPrefs = () => {
    dispatch(updateNotificationPrefs(data));
    setUpdateMsg(true);
  };

  
  useEffect(() => {
    if (updateMsg && NotificationPrefsSuccess?.message) {
      handleClose("update");
    }
  }, [handleClose, updateMsg, NotificationPrefsSuccess]);

  return (
    <>
      {updateMsg && NotificationPrefsSuccess?.message && (
        <Notification data={NotificationPrefsSuccess?.message} status="success" />
      )}
      <Dialog
        open={openUpdate}
        onClose={(e) => handleClose("cancel")}
        classes={{ paper: classes.dialogWidth }}
      >
        <form>
          <DialogTitle id="form-dialog-title">
            <div>Update Notification Preference</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
         
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="title"
                  type="text"
                  label="Title"
                  variant="outlined"
                  name="title"
                  fullWidth
                  required
                  value={data.title || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={(e) => handleClose("cancel")} color="primary">
              Cancel
            </Button>
            <Button
              onClick={submitNotificationPrefs}
              color="secondary"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpdateNotificationPreference;
