import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import UtilService from "../../helper/service";
import { reScheduleShift } from "../../store/action";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const RescheduleShift = ({ open, id, handleClose }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [roleMsg, setRoleMsg] = useState(false);
  const { reScheduleShiftError, reScheduleShiftSuccess } = useSelector(
    (state) => state.booking
  );
  const [data, setData] = useState({
    date: "",
    booking_id: id,
  });
  const disPastDate = UtilService.disabledPastDate();

  const [formError, setError] = useState([]);
  const handleChange = (event) => {
    data.booking_id = id;
    data.date = event.target.value;
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSubmitreSechedule = async (event) => {
    event.preventDefault();
    if (data.date === "") {
      setError("err_date");
    } else {
      dispatch(reScheduleShift(data));
      setRoleMsg(true);
      handleClose();
      setData("");
    }
  };
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, date: "" });
  };
  return (
    <>
      {roleMsg && reScheduleShiftError?.message && (
        <Notification data={reScheduleShiftError?.message} status="error" />
      )}
      {roleMsg && reScheduleShiftSuccess?.message && (
        <Notification data={reScheduleShiftSuccess?.message} status="success" />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitreSechedule(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Re-schedule Shift</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="date"
                type="date"
                label="Date"
                variant="outlined"
                name="date"
                fullWidth
                required
                value={data.date || ""}
                error={formError.includes("err_date") ? true : false}
                // inputProps={{
                  // min: disPastDate,
                // }}
                helperText={
                  formError.includes("err_date")
                    ? "Please enter Booking date"
                    : false
                }
                onChange={handleChange}
              />
            </div>
          </DialogContent>

          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default RescheduleShift;
