import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";

import {
  DELETE_TEMP_TIMESHEET_ERROR,
  DELETE_TEMP_TIMESHEET_REQUEST,
  DELETE_TEMP_TIMESHEET_SUCCESS,
  GET_TIMESHEET_ERROR,
  GET_TIMESHEET_REQUEST,
  GET_TIMESHEET_SUCCESS,
  GET_TEMP_TIMESHEET_BY_ID_REQUEST,
}
  from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";


export const getTimesheet = ({ pageNo = 1, search = "",type="",column_name="" }) => {
  return async (dispatch) => {
    dispatch(getTimesheetRequest());
    await apiClient(true)
      .get(`api/organization/get-temp-timesheets?search=${search}&page=${pageNo}&type=${type}&column_name=${column_name}`)
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getTimesheetSuccess(dataItem));
        } else {
          dispatch(getTimesheetSuccess(""));
          dispatch(getTimesheetError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getTimesheetError(error));
      });
  };
};

const getTimesheetRequest = () => {
  return {
    type: GET_TIMESHEET_REQUEST,
  };
};

const getTimesheetSuccess = (data) => {
  return {
    type: GET_TIMESHEET_SUCCESS,
    payload: data,
  };
};

const getTimesheetError = (error) => {
  return {
    type: GET_TIMESHEET_ERROR,
    payload: error,
  };
};

// -------------------------

export const deleteTimesheet = (id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteTempTimesheetRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-temp-timesheet/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        
        if (data?.status === true) {
          dispatch(deleteTempTimesheetSuccess(data?.message));
          dispatch(notificationSuccess(data?.message));
        } else {
           dispatch(deleteTempTimesheetError(data?.message));
           dispatch(notificationFail(data?.message));
        }
      })
      .catch((error) => {
        dispatch(deleteTempTimesheetError(error));
      });
  };
};

const deleteTempTimesheetRequest = () => {
  return {
    type: DELETE_TEMP_TIMESHEET_REQUEST,
  };
};

const deleteTempTimesheetSuccess = (data) => {
  return {
    type: DELETE_TEMP_TIMESHEET_SUCCESS,
    payload: data,
  };
};

const deleteTempTimesheetError = (error) => {
  return {
    type: DELETE_TEMP_TIMESHEET_ERROR,
    payload: error,
  };
};

// --------------------------------
export const getTempTimesheetById = (id,sValue,result) => {
  return async (dispatch) => {
    dispatch(getTempTimesheetByIdRequest());
    await apiClient(true)
      .get(`api/organization/get-temp-timesheet-by-id/${id}/${sValue}`)
      .then((response) => {
        result(response.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

export const getTempTimesheetByIdRequest = () => {
  return {
    type: GET_TEMP_TIMESHEET_BY_ID_REQUEST,
  };
};
// --------------------------------