import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  bookingItem: [],
  bookingItemInvoice: [],

  bookingError: [],

  createBookingSuccess: [],
  createBookingError: [],

  updateBookingSuccess: [],
  updateBookingError: [],

  deleteBookingSuccess: [],
  deleteBookingError: [],
  deleteTimeSheetSuccess: [],
  deleteTimeSheetError: [],

  cancelInvoiceSuccess: [],
  cancelInvoiceError: [],

  confirmBookingSuccess: [],
  confirmBookingError: [],

  shiftStatusSuccess: [],
  shiftStatusError: [],

  invitationSuccess: [],
  invitationError: [],

  paymentStatusSuccess: [],
  paymentStatusError: [],

  reScheduleShiftError: [],
  reScheduleShiftSuccess: [],

  getFilterDataSuccess: [],
  getISFilterDataSuccess: [],

  expenseSuccess: [],
  expenseErrors: [],

  deleteError: [],
  deleteSuccess: [],

  getExpenseItem: [],
  getExpenseError: [],

};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKING_REQUEST:
      return updateObject(state, {
        loading: true,
      });

    case actionTypes.GET_BOOKING_SUCCESS:
      return updateObject(state, {
        loading: false,
        bookingItem: action.payload,
      });

    case actionTypes.GET_BOOKING_ERROR:
      return updateObject(state, {
        loading: false,
        bookingError: action.payload,
      });

      case actionTypes.GET_BOOKING_INVOICE_REQUEST:
      return updateObject(state, {
        loading: true,
      });

    case actionTypes.GET_BOOKING_INVOICE_SUCCESS:
      return updateObject(state, {
        loading: false,
        bookingItemInvoice: action.payload,
      });

    case actionTypes.GET_BOOKING_INVOICE_ERROR:
      return updateObject(state, {
        loading: false,
        bookingError: action.payload,
      });

    case actionTypes.CREATE_BOOKING_REQUEST:
      return updateObject(state, {
        loading: true,
        createBookingSuccess: "",
        createBookingError: "",
      });
    
    case actionTypes.CREATE_FILTER_REQUEST:
      return updateObject(state, {
        loading: true,
      });

    case actionTypes.DELETE_FILTER_REQUEST:
      return updateObject(state, {
        loading: true,
    });

    case actionTypes.GET_FILTER_DATA_REQUEST:
      return updateObject(state, {
        loading: true,
    });

    case actionTypes.GET_FILTER_DATA_SUCCESS:
      return updateObject(state, {
        loading: false,
        getFilterDataSuccess: action.payload,
      });
    
    case actionTypes.GET_IS_FILTER_DATA_SUCCESS:
      return updateObject(state, {
        loading: false,
        getISFilterDataSuccess: action.payload,
      });
    
    case actionTypes.CREATE_FILTER_SUCCESS:
      return updateObject(state, {
        loading: false,
        createFilterSuccess: action.payload,
      });

    case actionTypes.CREATE_BOOKING_SUCCESS:
      return updateObject(state, {
        loading: false,
        createBookingSuccess: action.payload,
      });
    case actionTypes.CREATE_BOOKING_ERROR:
      return updateObject(state, {
        loading: false,
        createBookingError: action.payload,
      });

    case actionTypes.UPDATE_BOOKING_REQUEST:
      return updateObject(state, {
        loading: true,
        updateBookingSuccess: "",
        updateBookingError: "",
      });

    case actionTypes.UPDATE_BOOKING_SUCCESS:
      return updateObject(state, {
        loading: false,
        updateBookingSuccess: action.payload,
      });

    case actionTypes.UPDATE_BOOKING_ERROR:
      return updateObject(state, {
        loading: false,
        updateBookingError: action.payload,
      });

    case actionTypes.DELETE_BOOKING_REQUEST:
      return updateObject(state, {
        loading: true,
        deleteBookingSuccess: "",
        deleteBookingError: "",
      });

    case actionTypes.DELETE_BOOKING_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteBookingSuccess: action.payload,
      });

    case actionTypes.DELETE_BOOKING_ERROR:
      return updateObject(state, {
        loading: false,
        deleteBookingError: action.payload,
      });

    // -------------------------------------

     case actionTypes.DELETE_TIMESHEET_REQUEST:
      return updateObject(state, {
        loading: true,
        deleteTimeSheetSuccess: "",
        deleteTimeSheetError: "",
      });

    case actionTypes.DELETE_TIMESHEET_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteTimeSheetSuccess: action.payload,
      });

    case actionTypes.DELETE_TIMESHEET_ERROR:
      return updateObject(state, {
        loading: false,
        deleteTimeSheetError: action.payload,
      });

      // -------------------------------------

     case actionTypes.CANCEL_INVOICE_REQUEST:
      return updateObject(state, {
        loading: true,
        cancelInvoiceSuccess: "",
        cancelInvoiceError: "",
      });

    case actionTypes.CANCEL_INVOICE_SUCCESS:
      return updateObject(state, {
        loading: false,
        cancelInvoiceSuccess: action.payload,
      });

    case actionTypes.CANCEL_INVOICE_ERROR:
      return updateObject(state, {
        loading: false,
        cancelInvoiceError: action.payload,
      });

    // -------------------------------------
      
    case actionTypes.CONFIRM_BOOKING_REQUEST:
      return updateObject(state, {
        loading: true,
        confirmBookingSuccess: "",
        confirmBookingError: "",
      });

    case actionTypes.CONFIRM_BOOKING_SUCCESS:
      return updateObject(state, {
        loading: false,
        confirmBookingSuccess: action.payload,
      });

    case actionTypes.CONFIRM_BOOKING_ERROR:
      return updateObject(state, {
        loading: false,
        confirmBookingError: action.payload,
      });

    // -------------------------------------

    case actionTypes.CHANGE_SHIFT_STATUS_REQUEST:
      return updateObject(state, {
        loading: true,
        shiftStatusSuccess: "",
        shiftStatusError: "",
      });

    case actionTypes.CHANGE_SHIFT_STATUS_SUCCESS:
      return updateObject(state, {
        loading: false,
        shiftStatusSuccess: action.payload,
      });

    case actionTypes.CHANGE_SHIFT_STATUS_ERROR:
      return updateObject(state, {
        loading: false,
        shiftStatusError: action.payload,
      });

    // -------------------------------------

    case actionTypes.USER_INVITATION_REQUEST:
      return updateObject(state, {
        loading: true,
        invitationSuccess: "",
        invitationError: "",
      });

    case actionTypes.USER_INVITATION_SUCCESS:
      return updateObject(state, {
        loading: false,
        invitationSuccess: action.payload,
      });

    case actionTypes.USER_INVITATION_ERROR:
      return updateObject(state, {
        loading: false,
        invitationError: action.payload,
      });

    // -------------------------------------

    case actionTypes.CHANGE_PAYMENT_STATUS_REQUEST:
      return updateObject(state, {
        loading: true,
        paymentStatusSuccess: "",
        paymentStatusError: "",
      });

    case actionTypes.CHANGE_PAYMENT_STATUS_SUCCESS:
      return updateObject(state, {
        loading: false,
        paymentStatusSuccess: action.payload,
      });

    case actionTypes.CHANGE_PAYMENT_STATUS_ERROR:
      return updateObject(state, {
        loading: false,
        paymentStatusError: action.payload,
      });

    // -------------------------------------

    case actionTypes.RESCHEDULE_BOOKING_REQUEST:
      return updateObject(state, {
        loading: true,
        reScheduleShiftError: "",
        reScheduleShiftSuccess: "",
      });

    case actionTypes.RESCHEDULE_BOOKING_SUCCESS:
      return updateObject(state, {
        loading: false,
        reScheduleShiftSuccess: action.payload,
      });

    case actionTypes.RESCHEDULE_BOOKING_ERROR:
      return updateObject(state, {
        loading: false,
        reScheduleShiftError: action.payload,
      });

    case actionTypes.GET_EXPENSE_REQUEST:
      return updateObject(state, {
        loading: true,
        expenseSuccess: "",
        expenseErrors: "",

        deleteSuccess: "",
        deleteError: "",
      });

    case actionTypes.GET_EXPENSE_SUCCESS:
      return updateObject(state, {
        loading: false,
        getExpenseItem: action.payload,
      });

    case actionTypes.GET_EXPENSE_ERROR:
      return updateObject(state, {
        loading: false,
        getExpenseError: action.payload,
      });

    case actionTypes.CREATE_EXPENSE_REQUEST:
      return updateObject(state, {
        loading: true,
        expenseSuccess: "",
        expenseErrors: "",
      });

    case actionTypes.CREATE_EXPENSE_SUCCESS:
      return updateObject(state, {
        loading: false,
        expenseSuccess: action.payload,
      });

    case actionTypes.CREATE_EXPENSE_ERROR:
      return updateObject(state, {
        loading: false,
        expenseErrors: action.payload,
      });

    default:
      return state;
  }
};

export default bookingReducer;
