import CandidatesList from "../../../pages/Bookings/CandidatesList";
import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  candidates:[],
  getSigneeItem: [],
  getSigneeError: [],

  getSingleSigneeItem: [],
  getSingleSigneeError: [],

  createSigneeSuccess: [],
  createSigneeError: [],

  enabledDisabledCandidateSuccess: [],
  enabledDisabledCandidateError: [],

  updateSigneeSuccess: [],
  updateSigneeError: [],

  deleteSigneeSuccess: [],
  deleteSigneeError: [],

  getCandidateReferrredForm: [],

  signeeProStatusSuccess: [],
  signeeProStatusError: [],

  signeeComStatusSuccess: [],
  signeeComStatusError: [],

  changeDocStatusSuccess: [],
  changeDocStatusError: [],

  getCandidateEventItem: [],
  getCandidateEventError: [],

  addCandidateEventSuccess: [],
  addCandidateEventError: [],

  addCandidateRemarkSuccess: [],
  addCandidateRemarkError: [],

  addDocExpireDateSuccess: [],
  addDocExpireDateError: [],
  deleteDocumentSuccess: [],
  deleteDocumentError: [],
  getCandidateError:[],
  getVCFilterDataSuccess: [],
};

const signeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CANDIDATES_REQUEST:
      return updateObject(state,{
        loading:true,
      });
    case actionTypes.FETCH_CANDIDATES_SUCCESS:
      return updateObject(state,{
        loading:false,
        candidates: action.payload,
        error: ''
      });
    case actionTypes.FETCH_CANDIDATES_FAILURE:
      return updateObject(state,{
        loading:false,
        candidates: [],
        getCandidateError: action.payload,
      });
    case actionTypes.GET_SIGNEE_REQUETS:
      return updateObject(state, {
        loading: true,
      });

    case actionTypes.GET_SIGNEE_SUCCESS:
      return updateObject(state, {
        loading: false,
        getSigneeItem: action.payload,
      });


    case actionTypes.GET_VC_FILTER_DATA_REQUEST:
      return updateObject(state, {
        loading: true,
    });

    case actionTypes.GET_VC_FILTER_DATA_SUCCESS:
      return updateObject(state, {
        loading: false,
        getVCFilterDataSuccess: action.payload,
    });
    case actionTypes.GET_SIGNEE_ERROR:
      return updateObject(state, {
        loading: false,
        getSigneeError: action.payload,
      });

    case actionTypes.CREATE_SIGNEE_REQUETS:
      return updateObject(state, {
        loading: true,
        createSigneeSuccess: "",
        createSigneeError: "",
      });

    case actionTypes.CREATE_SIGNEE_SUCCESS:
      return updateObject(state, {
        loading: false,
        createSigneeSuccess: action.payload,
      });

    case actionTypes.CREATE_SIGNEE_ERROR:
      return updateObject(state, {
        loading: false,
        createSigneeError: action.payload,
      });

    case actionTypes.UPDATE_SIGNEE_REQUETS:
      return updateObject(state, {
        loading: true,
        updateSigneeSuccess: "",
        updateSigneeError: "",
      });

      case actionTypes.ENABLED_DISABLED_CANDIDATE_SUCCESS:
        return updateObject(state, {
          loading: false,
          enabledDisabledCandidateSuccess: action.payload,
        });
  
      case actionTypes.ENABLED_DISABLED_CANDIDATE_ERROR:
        return updateObject(state, {
          loading: false,
          enabledDisabledCandidateError: action.payload,
        });

    case actionTypes.ENABLED_DISABLED_CANDIDATE_REQUEST:
        return updateObject(state, {
          loading: true,
          enabledDisabledCandidateSuccess: "",
          enabledDisabledCandidateError: "",
        });

    case actionTypes.UPDATE_SIGNEE_SUCCESS:
      return updateObject(state, {
        loading: false,
        updateSigneeSuccess: action.payload,
      });

    case actionTypes.UPDATE_SIGNEE_ERROR:
      return updateObject(state, {
        loading: false,
        updateSigneeError: action.payload,
      });

    case actionTypes.GET_SINGLE_SIGNEE_REQUETS:
      return updateObject(state, {
        loading: true,
        getSingleSigneeItem: "",
        getSingleSigneeError: "",
      });

    case actionTypes.GET_SINGLE_SIGNEE_SUCCESS:
      return updateObject(state, {
        loading: false,
        getSingleSigneeItem: action.payload,
      });

    case actionTypes.GET_SINGLE_SIGNEE_ERROR:
      return updateObject(state, {
        loading: false,
        getSingleSigneeError: action.payload,
      });

    case actionTypes.DELETE_SIGNEE_REQUETS:
      return updateObject(state, {
        loading: true,
        deleteSigneeSuccess: "",
        deleteSigneeError: "",
      });

    case actionTypes.DELETE_SIGNEE_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteSigneeSuccess: action.payload,
      });

    case actionTypes.DELETE_SIGNEE_ERROR:
      return updateObject(state, {
        loading: false,
        deleteSigneeError: action.payload,
      });

    case actionTypes.GET_CANDIDATE_REFERRED_FROM_SUCCESS:
      return updateObject(state, {
        loading: false,
        getCandidateReferrredForm: action.payload,
      });

    // -------------------------------------------

    case actionTypes.CHANGE_SIGNEE_PRO_STATUS_REQUEST:
      return updateObject(state, {
        loading: true,
        signeeProStatusSuccess: "",
        signeeProStatusError: "",
      });

    case actionTypes.CHANGE_SIGNEE_PRO_STATUS_SUCCESS:
      return updateObject(state, {
        loading: false,
        signeeProStatusSuccess: action.payload,
      });

    case actionTypes.CHANGE_SIGNEE_PRO_STATUS_ERROR:
      return updateObject(state, {
        loading: false,
        signeeProStatusError: action.payload,
      });

    // -------------------------------------------

    case actionTypes.CHANGE_SIGNEE_COMP_STATUS_REQUEST:
      return updateObject(state, {
        loading: false,
        signeeComStatusSuccess: "",
        signeeComStatusError: "",
      });

    case actionTypes.CHANGE_SIGNEE_COMP_STATUS_SUCCESS:
      return updateObject(state, {
        loading: false,
        signeeComStatusSuccess: action.payload,
      });

    case actionTypes.CHANGE_SIGNEE_COMP_STATUS_ERROR:
      return updateObject(state, {
        loading: false,
        signeeComStatusError: action.payload,
      });
    // -------------------------------------------

    case actionTypes.CHANGE_DOC_STATUS_REQUEST:
      return updateObject(state, {
        loading: false,
        changeDocStatusSuccess: "",
        changeDocStatusError: "",
      });

    case actionTypes.CHANGE_DOC_STATUS_SUCCESS:
      return updateObject(state, {
        loading: false,
        changeDocStatusSuccess: action.payload,
      });

    case actionTypes.CHANGE_DOC_STATUS_ERROR:
      return updateObject(state, {
        loading: false,
        changeDocStatusError: action.payload,
      });

    case actionTypes.GET_CONTACT_EVENT_REQUETS:
      return updateObject(state, {
        loading: true,
        getCandidateEventItem: "",
        getCandidateEventError: "",
      });

    case actionTypes.GET_CONTACT_EVENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        getCandidateEventItem: action.payload,
      });

    case actionTypes.GET_CONTACT_EVENT_ERROR:
      return updateObject(state, {
        loading: false,
        getCandidateEventError: action.payload,
      });

    case actionTypes.ADD_CONTACT_EVENT_REQUETS:
      return updateObject(state, {
        loading: true,
        addCandidateEventSuccess: "",
        addCandidateEventError: "",
      });

    case actionTypes.ADD_CONTACT_EVENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        addCandidateEventSuccess: action.payload,
      });

    case actionTypes.ADD_CONTACT_EVENT_ERROR:
      return updateObject(state, {
        loading: false,
        addCandidateEventError: action.payload,
      });

       case actionTypes.ADD_SIGNEE_REMARK_REQUETS:
        return updateObject(state, {
          loading: true,
          addCandidateRemarkSuccess: "",
          addCandidateRemarkError: "",
        });
  
      case actionTypes.ADD_SIGNEE_REMARK_SUCCESS:
        return updateObject(state, {
          loading: false,
          addCandidateRemarkSuccess: action.payload,
        });
  
      case actionTypes.ADD_SIGNEE_REMARK_ERROR:
        return updateObject(state, {
          loading: false,
          addCandidateRemarkError: action.payload,
        });

      case actionTypes.ADD_DOC_EXPIRE_DATE_REQUETS:
        return updateObject(state, {
          loading: true,
          addDocExpireDateSuccess: "",
          addDocExpireDateError: "",
        });
  
      case actionTypes.ADD_DOC_EXPIRE_DATE_SUCCESS:
        return updateObject(state, {
          loading: false,
          addDocExpireDateSuccess: action.payload,
        });
  
      case actionTypes.ADD_DOC_EXPIRE_DATE_ERROR:
        return updateObject(state, {
          loading: false,
          addDocExpireDateError: action.payload,
        });
        
      case actionTypes.DELETE_DOCUMENT_REQUETS:
        return updateObject(state, {
          loading: true,
          deleteDocumentError: "",
          deleteDocumentSuccess: "",
        });
  
      case actionTypes.DELETE_DOCUMENT_SUCCESS:
        return updateObject(state, {
          loading: false,
          deleteDocumentSuccess: action.payload,
        });
  
      case actionTypes.DELETE_DOCUMENT_ERROR:
        return updateObject(state, {
          loading: false,
          deleteDocumentError: action.payload,
        });

    default:
      return state;
  }
};

export default signeeReducer;
