import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import history from "../../../utils/HistoryUtils";
import {
  CREATE_TRUST_ERROR,
  CREATE_TRUST_REQUEST,
  CREATE_TRUST_SUCCESS,
  DELETE_TRUST_ERROR,
  DELETE_TRUST_REQUEST,
  DELETE_TRUST_SUCCESS,
  GET_ALL_TRUST_REQUEST,
  GET_SINGAL_TRUST_REQUEST,
  GET_TRUST_ERROR,
  GET_TRUST_REQUEST,
  GET_TRUST_SUCCESS,
  UPDATE_TRUST_ERROR,
  UPDATE_TRUST_REQUEST,
  UPDATE_TRUST_SUCCESS,
} from "../actiontypes";

export const getSingleTrustById = (id, result) => {
  return async (dispatch) => {
    dispatch(getSingleTrustRequest());
    await apiClient(true)
      .get(`api/organization/get-trust/${id}`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getSingleTrustRequest = () => {
  return {
    type: GET_SINGAL_TRUST_REQUEST,
  };
};

export const getAllTrust = (result) => {
  return async (dispatch) => {
    dispatch(getAllTrustRequest());
    await apiClient(true)
      .get(`api/organization/get-trusts`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getAllTrustRequest = () => {
  return {
    type: GET_ALL_TRUST_REQUEST,
  };
};
export const getTrust = ({ pageNo = 1, search = "",type="",column_name="" }) => {
  return async (dispatch) => {
    dispatch(getTrustRequest());
    await apiClient(true)
      .get(`api/organization/get-trust-list?search=${search}&page=${pageNo}&type=${type}&column_name=${column_name}`)
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getTrustSuccess(dataItem));
        } else {
          dispatch(getTrustSuccess(""));
          dispatch(getTrustError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getTrustError(error));
      });
  };
};

const getTrustRequest = () => {
  return {
    type: GET_TRUST_REQUEST,
  };
};

const getTrustSuccess = (data) => {
  return {
    type: GET_TRUST_SUCCESS,
    payload: data,
  };
};

const getTrustError = (error) => {
  return {
    type: GET_TRUST_ERROR,
    payload: error,
  };
};

export const createTrust = (data, addAnother) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createTrustRequest());
    await axios
      .post(`${Config.API_URL}api/organization/add-trust`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          if (addAnother === true) {
            dispatch(createTrustSuccess(data));
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            dispatch(createTrustSuccess(data));
            setTimeout(() => {
              history.goBack();
            }, 2000);
          }
        } else {
          // dispatch(createTrustFailure(data))
          dispatch(createTrustFailure(data.message));
        }
      })
      .catch((error) => {
        dispatch(createTrustFailure(error));
      });
  };
};

const createTrustRequest = () => {
  return {
    type: CREATE_TRUST_REQUEST,
  };
};

const createTrustSuccess = (data) => {
  return {
    type: CREATE_TRUST_SUCCESS,
    payload: data,
  };
};

const createTrustFailure = (error) => {
  return {
    type: CREATE_TRUST_ERROR,
    payload: error,
  };
};

// -------------------------

export const updateTrust = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateTrustRequest());
    await axios
      .post(`${Config.API_URL}api/organization/update-trust`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(updateTrustSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          dispatch(updateTrustFailure(data.message));
        }
      })
      .catch((error) => {
        dispatch(updateTrustFailure(error));
      });
  };
};

const updateTrustRequest = () => {
  return {
    type: UPDATE_TRUST_REQUEST,
  };
};

const updateTrustSuccess = (data) => {
  return {
    type: UPDATE_TRUST_SUCCESS,
    payload: data,
  };
};

const updateTrustFailure = (error) => {
  return {
    type: UPDATE_TRUST_ERROR,
    payload: error,
  };
};

// -------------------------

export const deleteTrust = (id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteTrustRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-trust/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(deleteTrustSuccess(data));
          setTimeout(() => {
            history.go(-2);
          }, 2000);
        } else {
          dispatch(deleteTrustFailure(data));
        }
      })
      .catch((error) => {
        dispatch(deleteTrustFailure(error));
      });
  };
};

const deleteTrustRequest = () => {
  return {
    type: DELETE_TRUST_REQUEST,
  };
};

const deleteTrustSuccess = (data) => {
  return {
    type: DELETE_TRUST_SUCCESS,
    payload: data,
  };
};

const deleteTrustFailure = (error) => {
  return {
    type: DELETE_TRUST_ERROR,
    payload: error,
  };
};
