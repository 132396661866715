import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getSystemLogsItem: [],
  getSystemLogsError: [],
  SystemLogsSuccess: [],
  SystemLogsErrors: [],

  deleteError: [],
  deleteSuccess: [],
};

const systemLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SYSTEM_LOGS_REQUEST:
      return updateObject(state, {
        loading: true,
        SystemLogsSuccess: "",
        SystemLogsErrors: "",

        deleteSuccess: "",
        deleteError: "",
      });

    case actionTypes.GET_SYSTEM_LOGS_SUCCESS:
      return updateObject(state, {
        loading: false,
        getSystemLogsItem: action.payload,
      });

    case actionTypes.GET_SYSTEM_LOGS_ERROR:
      return updateObject(state, {
        loading: false,
        getSystemLogsError: action.payload,
      });

    default:
      return state;
  }
};

export default systemLogsReducer;
