import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getCategoryItem: [],
  getCategoryError: [],
  categorySuccess: [],
  categoryErrors: [],
  docTypesSuccess: [],
  docTypesErrors: [],

  deleteError: [],
  deleteSuccess: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        categorySuccess: "",
        categoryErrors: "",

        deleteSuccess: "",
        deleteError: "",
      });

    case actionTypes.GET_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        getCategoryItem: action.payload,
      });

    case actionTypes.GET_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        getCategoryError: action.payload,
      });

    case actionTypes.CREATE_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        categorySuccess: "",
        categoryErrors: "",
      });

    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        categorySuccess: action.payload,
      });

    case actionTypes.CREATE_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        categoryErrors: action.payload,
      });

    case actionTypes.UPDATE_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        categorySuccess: "",
        categoryErrors: "",
      });

    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        categorySuccess: action.payload,
      });

    case actionTypes.UPDATE_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        categoryErrors: action.payload,
      });

    case actionTypes.DELETE_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        deleteError: "",
        deleteSuccess: "",
      });

    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteSuccess: action.payload,
      });

    case actionTypes.DELETE_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        deleteError: action.payload,
      });

    case actionTypes.UPDATE_DOC_TYPES_REQUEST:
      return updateObject(state, {
        loading: true,
        docTypesSuccess: "",
        docTypesErrors: "",
      });

    case actionTypes.UPDATE_DOC_TYPES_SUCCESS:
      return updateObject(state, {
        loading: action.payload.loading,
        docTypesSuccess: action.payload.data,
      });

    case actionTypes.UPDATE_DOC_TYPES_ERROR:
      return updateObject(state, {
        loading: action.payload.loading,
        docTypesErrors: action.payload.data,
      });

    default:
      return state;
  }
};

export default categoryReducer;
