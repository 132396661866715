import React from 'react'
import { Route } from "react-router-dom";
import CreateUmbrella from './CreateUmbrella';
import DetailUmbrella from './DetailUmbrella';
import UpdateUmbrella from './UpdateUmbrella';
import ViewUmbrella from './ViewUmbrella';

const Staff = ({ match }) => {
    return (
        <>
            <Route exact path={`${match.url}/`} component={ViewUmbrella} />
            <Route exact path={`${match.url}/create`} component={CreateUmbrella} />
            <Route exact path={`${match.url}/:id/detail`} component={DetailUmbrella} />
            <Route exact path={`${match.url}/:id/update`} component={UpdateUmbrella} />
        </>
    )
}

export default Staff
