import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { updateUmbrella } from "../../store/action";
import history from "../../utils/HistoryUtils";
import Notification from "../../components/Notification/Notification";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  formControl: {
    width: "100%",
    "& > .MuiFormHelperText-root": {
      color: "red",
    },
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const UpdateUmbrella = ({ match }) => {
  const classes = useStyle();
  const user_id = match.params.id;
  const dispatch = useDispatch();
  const [roleItem, setRoleItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [umbrellaNotify, setUmbrellaNotify] = useState(false);
  const [designationItem, setDesignationItem] = useState([]);
  const { umbrellaUpdateError, umbrellaUpdateSuccess } = useSelector(
    (state) => state.umbrellaReducer
  );
  // const [items, setItems] = useState([])
  const [data, setData] = useState({
    name_umbrella: "",
    address: "",
    email: "",
    bank_name: "",
    bank_account_number: "",
    bank_sort_code: "",
    vat: "",
    vat_number: "",
  });
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const getUmbrell = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    setLoading(true);
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/umbrella-company/${user_id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error.message", error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUmbrell();
  }, [user_id]);

  const umbrellaUpdateSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUmbrella(data));
    setUmbrellaNotify(true);
  };
  const backPage = () => {
    history.goBack();
  };
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {umbrellaNotify && umbrellaUpdateSuccess?.message && (
        <Notification
          data={
            umbrellaUpdateSuccess?.message
              ? "Umbrella member updated successfully"
              : ""
          }
          status="success"
        />
      )}

      {/* {umbrellaNotify && umbrellaUpdateError?.message &&
                <Notification
                    data={umbrellaUpdateError?.message}
                    status="error"
                />
            } */}
      <Paper className={classes.root}>
        {/* <form onSubmit={handleSubmit(umbrellaSubmit())}> */}
        <form onSubmit={(e) => umbrellaUpdateSubmit(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="name_umbrella"
                label="Name Of Umbrella"
                variant="outlined"
                name="name_umbrella"
                onChange={handleChange}
                fullWidth
                value={data?.name_umbrella}
                helperText={umbrellaUpdateError.message?.name_umbrella}
                error={!!umbrellaUpdateError.message?.name_umbrella}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                name="address"
                value={data?.address}
                onChange={handleChange}
                fullWidth
                helperText={umbrellaUpdateError.message?.address}
                error={!!umbrellaUpdateError.message?.address}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="email"
                label="Contact Email"
                variant="outlined"
                name="email"
                value={data?.email}
                onChange={handleChange}
                fullWidth
                helperText={umbrellaUpdateError.message?.email}
                error={!!umbrellaUpdateError.message?.email}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="bank_name"
                label="Bank Name"
                variant="outlined"
                name="bank_name"
                value={data?.bank_name}
                onChange={handleChange}
                fullWidth
                helperText={umbrellaUpdateError.message?.bank_name}
                error={!!umbrellaUpdateError.message?.bank_name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="bank_account_number"
                label="Bank Account Number"
                variant="outlined"
                name="bank_account_number"
                value={data?.bank_account_number}
                onChange={handleChange}
                fullWidth
                helperText={umbrellaUpdateError.message?.bank_account_number}
                error={!!umbrellaUpdateError.message?.bank_account_number}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="bank_sort_code"
                label="Bank Sort Code"
                variant="outlined"
                name="bank_sort_code"
                value={data?.bank_sort_code}
                onChange={handleChange}
                fullWidth
                helperText={umbrellaUpdateError.message?.bank_sort_code}
                error={!!umbrellaUpdateError.message?.bank_sort_code}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="vat"
                label="VAT(%)"
                variant="outlined"
                name="vat"
                value={data?.vat}
                onChange={handleChange}
                fullWidth
                // required
                helperText={umbrellaUpdateError.message?.vat}
                error={!!umbrellaUpdateError.message?.vat}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="vat_number"
                label="Vat Number"
                variant="outlined"
                name="vat_number"
                value={data?.vat_number}
                onChange={handleChange}
                fullWidth
                required
                helperText={umbrellaUpdateError.message?.vat_number}
                error={!!umbrellaUpdateError.message?.vat_number}
              />
            </Grid>
          </Grid>

          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              formNoValidate
            >
              Update
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default UpdateUmbrella;
