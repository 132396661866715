import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { createBooking } from "../../store/action";
import { useForm } from "react-hook-form";
import Notification from "../../components/Notification/Notification";
import history from "../../utils/HistoryUtils";
import {
  getBookingSpeciality,
  getBookingTrust,
  getHospitalList,
  getWardByHospital,
} from "../../store/action/booking/bookingAction";
import { getStaffDetail } from "../../store/action/staff/staffAction";
import CandidatesList from "./CandidatesList";
// import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment"; 
import { CloseOutlined } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  formControl: {
    width: "100%",
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },
  
}));

const CreateBooking = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [speciality, setSpeciality] = useState([]);
  const [trust, setTrust] = useState([]);
  const [hours, setHours] = useState([]);
  const [getTrustId, setTrustId] = useState('');
  const [getHospitalId, setHospitalId] = useState('');
  const [hospitalList, setHospitalList] = useState('');
  const [wardList, setWardList] = useState([]);
  const [gradeId, setGradeId] = useState([]);
  const [specialityId, setSpecialityId] = useState([]);
  const [shiftTypeList, setShiftTypeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [shiftTime, setShiftTime] = useState([]);
  const [booking_id , setBookingId] = useState(0);
  const [candidateAssignDetails,setCandidateAssignDetails]  = useState({})
  const [searchingVal, setSearchingVal] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm();
  const [addAnother, setAddAnother] = useState(false);
  const [isNonAwr, setIsNonAwr] = useState(true);
  const [trustNotify, setTrustNotify] = useState(false);
  const [rateNotify, setRateNotify] = useState(false);
  const [specError, setSpecError] = useState(false);
  const [referenceId, setReferenceId] = useState([]);
  const [breakDateErr, setBreakDateErr] = useState({
    date: false,
    break: false,
  });
  const { createBookingSuccess, createBookingError, loading } = useSelector(
    (state) => state.booking
  );
  const [staff, setStaff] = useState([]);
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );

  const [bookingDetail, setBookingDetail] = useState([]);
  const [initialData,setInitialData]=useState([])
  const [confirmBtn, setConfirmBtn] = useState(false);
  // let startTime = data?.start_time ? dayjs('2014-08-18T'+data.start_time) : dayjs('00:00', 'HH:mm');
  const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }
  const [data, setData] = useState({
    reference_id: "",
    trust_id: "",
    ward_id: "",
    grade_id: "",
    date: getFormattedDate(),
    shift_id: "",
    start_time: "",
    end_time: "",
    payable: "",
    shift_type_id: "",
    hospital_id: "",
    chargeable: "",
    speciality: "",
    booking_reference: "",
    booking_type: "",
    staff_id: (loginUserInfo.role !== "ORGANIZATION") ? loginUserInfo.id : "" ,
    break: "",
    total_hours: "",
    awr_paye: "",
    non_awr_paye_chargeble: "",
    awr_umbrella_chargeble: "",
    non_awr_umbrella_chargeble: "",
    non_awr_paye_payable: "",
    awr_umbrella_payable: "",
    non_awr_umbrella_payable: "",
    total_holiday_hour: "",
    total_saturday_hour: "",
    total_day_hour: "",
    total_night_hour: "",
    purchase_order: "",
    payment_scheme_type: "",
  });
  const [startTime,setStartTime] = useState('00:00')
  const  [endTime,setEndTime] = useState('00:00');

  const handleChangeStartTime = (newValue) => {
    // const sHours = newValue?.$H;
    // const sMinutes = newValue?.$m; 
    // let startTime = `${sHours}:${sMinutes}`;
    // data.start_time = startTime;
    // setData({ ...data, start_time: startTime });
    getCharge();
    getSearchBookingDetail();
  };


 const handleChangeEndTime = (newValue) => {
    // setData({ ...data, end_time: newValue });
    // getCharge();
    // getSearchBookingDetail();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const intValue = parseInt(value) || "";
    if (intValue >=0 && intValue <= 120) {
      handleChange(event);
      setValue(name, intValue, { shouldValidate: true });

    }
  };
  
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
   
    
    if (event.target.name === "date") {
      data.date = event.target.value;
      getCharge(event.target.name,event.target.value);
      setIsNonAwr(true);
    }
    if (event.target.name === "break") {
      data.break = event.target.value;
      getCharge();
      setIsNonAwr(true);
    }
    if (event.target.name === "booking_type") {
      data.booking_type = event.target.value;
      getCharge();
      getSearchBookingDetail();
      setIsNonAwr(true);
    }
    if (event.target.name === "speciality") {
      // setGradeList([]);
      data.speciality = event.target.value
      setSpecialityId(event.target.value);
      setData({ ...data, ['break']: ''});
      setIsNonAwr(true);

      setGradeId("");
      data.grade_id = "";
      // getSearchBookingDetail();
    }
    if (event.target.name === "grade_id") {
      data.grade_id = event.target.value;
      setGradeId(event.target.value);
      setData({ ...data, ['break']: ''});
      setIsNonAwr(true);

      // getSearchBookingDetail();
    }
    if (event.target.name === "trust_id") {
      setGradeId("");
      setSpecialityId("");
      getSearchBookingDetail();
      setIsNonAwr(true);
    }

    if (event.target.name === "ward_id") {
      setSpeciality([]);
       data.ward_id = event.target.value
       setData({ ...data, ['break']: ''});
       setIsNonAwr(true);
       getSpecialities();

       getSearchBookingDetail();
    }
  };

    const getSearchBookingDetail = async () => {
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      if(getTrustId !== '' && getTrustId !== undefined && getTrustId !== null){
        let formData = {
          'booking_id' : booking_id ? booking_id : 0,
          'trust_id' : getTrustId,
          'hospital_id' : getHospitalId,
          'grade_id' : gradeId,
          'speciality_id' : specialityId,
          'ward_id' : data.ward_id,
          'start_time' : data.start_time,
          'end_time' : data.end_time,
          'date': moment(data.date).format("YYYY-MM-DD"),
          'search': searchingVal ? searchingVal :'',
        }
    
    await axios
      .post(`${apiConfigs.API_URL}api/organization/get-search-booking`,formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setBookingDetail(response.data);
        setInitialData(response.data)
      })
      .catch((error) => {
        console.log("error.message", error.message);
      });}
  };

  const getSpecialities = async () => {
    dispatch(
      getBookingSpeciality(getTrustId, (result) => {
        if (result?.status === true) {
          setSpeciality(result);
        }
      })
    );
  };
  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
          if (loginUserInfo.role !== "ORGANIZATION") {
            data.staff_id = loginUserInfo.id;
          }
        }
      })
    );
  };
  useEffect(() => {
    if (getTrustId !== undefined) {
      getSpecialities();
      getSearchBookingDetail();
    }
  }, [getTrustId]);

 useEffect(() =>{
  if(getHospitalId || specialityId || gradeId){
      getSearchBookingDetail();
  }
 },[getHospitalId, specialityId, gradeId])

  useEffect(() => {
    if (getTrustId !== undefined && specialityId !== undefined) {
      getGradeList();
    }
  }, [getTrustId, specialityId]);

  useEffect(()=>{
    if(searchingVal.length >= 0){
       getSearchBookingDetail();
  }
  },[searchingVal])

  const getTrust = async () => {
    dispatch(
      getBookingTrust((result) => {
        if (result?.status === true) {
          setTrust(result);
        }
      })
    );
  };
  const trustHandleChange = (event) => {
    setHospitalList('');
    reset({
      hospital_id:"",
      ward_id : "",
      speciality : "",
      grade_id : "",
      break : "",
    })
    setIsNonAwr(true);
    data.hospital_id = "";
    data.ward_id = "";
    data.speciality = ""
    data.grade_id = ""
    data.break = ""
    setSpecialityId('')
    setGradeId('')
    setData({ ...data, [event.target.name]: event.target.value });
    setTrustId(event.target.value);
    getCharge();
    getSearchBookingDetail();
  };



  const gethospital = async () => {
    if (getTrustId) {
      dispatch(
        getHospitalList(getTrustId, (result) => {
          if (result?.status === true) {
            setHospitalList(result);
          }
        })
      );
    }
  };
  const hospitalHandleChange = (event) => {
    setWardList([]);
    setIsNonAwr(true);
    reset({
      ward_id:"",
      break:""
    })
    data.ward_id = "";
    data.break = ""

    setHospitalId(event.target.value);
    setData({ ...data, [event.target.name]: event.target.value });
    getWardType(event.target.value);
  };

   useEffect(() => {
    getShiftType();
    getHoursList();
    getTrust();
    getStaff();
    getReference();
    getShift();
  }, []);
  
const getCharge = async () => {
  // Reset notification
  setRateNotify("");
  let updatedEndTime;

  // Check if all required data is present
  const isDataValid =
    (data.start_time || startTime) &&
    (data.end_time || endTime) &&
    data.trust_id &&
    data.grade_id &&
    data.date &&
    data.break;

  if (!isDataValid) return;
  // Format start time
  // if (startTime?.$H <= 24 || startTime?.$m <= 60) {
  //   data.start_time = `${startTime?.$H ? startTime.$H : '00'}:${startTime?.$m ? startTime.$m : '00'}`;
  // } else {
  //   data.start_time = startTime;
  // }

  if (startTime?.$H <= 24 || startTime?.$m <= 60) {
    const hours = startTime?.$H !== undefined ? String(startTime.$H).padStart(2, '0') : '00';
    const minutes = startTime?.$m !== undefined ? String(startTime.$m).padStart(2, '0') : '00';
    data.start_time = `${hours}:${minutes}`;
  } else {
    data.start_time = startTime;
  }
  

  // Format end time
  // if (endTime?.$H <= 24  || endTime?.$m <= 60) {
  //   updatedEndTime = `${endTime?.$H ? endTime.$H : '00'}:${endTime?.$m ? endTime.$m : '00'}`; 
  //   data.end_time = updatedEndTime;
  // } else {
  //   data.end_time = endTime;
  // }
  if (endTime?.$H <= 24 || endTime?.$m <= 60) {
    const hours = endTime?.$H !== undefined ? String(endTime.$H).padStart(2, '0') : '00';
    const minutes = endTime?.$m !== undefined ? String(endTime.$m).padStart(2, '0') : '00';
    updatedEndTime = `${hours}:${minutes}`;
    data.end_time = updatedEndTime;
  } else {
    data.end_time = endTime;
  }
  


  try {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");

    const response = await axios.post(
      `${apiConfigs.API_URL}api/organization/get-rate`,
      {
        ...data,
        end_time: updatedEndTime ? updatedEndTime : data.end_time,
        date: moment(data.date).format("YYYY-MM-DD"),
      },
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      }
    );

    const output = response.data.data;

    if (output?.status === false) {
      // Error handling for invalid output
      setBreakDateErr({
        date: true,
        break: true,
      });
      resetData();
      setRateNotify(output.message);
    } else {
      // Update data with valid output
      setBreakDateErr({
        date: false,
        break: false,
      });
      setIsNonAwr(false);
      updateData(output);
    }
  } catch (error) {
    console.error("error: ", error);
  }
};

// Helper function to reset data
const resetData = () => {
  const newData = {
    ...data,
    chargeable: "",
    payable: "",
    date: "",
    break: "",
    non_awr_paye_chargeble: "",
    awr_umbrella_chargeble: "",
    awr_umbrella_chargeble: "",
    non_awr_umbrella_chargeble: "",
    non_awr_paye_payable: "",
    awr_umbrella_payableAmount: "",
    non_awr_umbrella_payable: "",
    total_hours: "",
    total_holiday_hour: "",
    total_saturday_hour: "",
    total_day_hour: "",
    total_night_hour: "",
  };
  setData(newData);
};

// Helper function to update data
const updateData = (output) => {
  const newData = {
    ...data,
    chargeable: Number(output.chargebleAmount),
    payable: Number(output.payableAmount),
    non_awr_paye_chargeble: output.non_awr_paye_chargebleAmount,
    awr_umbrella_chargeble: output.awr_umbrella_chargebleAmount,
    non_awr_umbrella_chargeble: output.non_awr_umbrella_chargebleAmount,
    non_awr_paye_payable: output.non_awr_paye_payableAmount,
    awr_umbrella_payable: output.awr_umbrella_payableAmount,
    non_awr_umbrella_payable: output.non_awr_umbrella_payableAmount,
    total_hours: output.totalhours,
    total_holiday_hour: output.holiDayhour,
    total_saturday_hour: output.saturDayhour,
    total_day_hour: output.dayhour,
    total_night_hour: output.nighthour,
    payment_scheme_type: output.payment_scheme_type,
  };
  setData(newData);
};

  // const getCharge = async () => {
  //   setRateNotify("");
  //   if (
  //   (data.start_time ||
  //     startTime) &&
  //     (data?.end_time|| endTime) &&    
  //     data.trust_id &&
  //     data.grade_id &&
  //     data.date &&
  //     data.break
  //   ) {

  //    if (startTime?.$H <= 24 || startTime?.$m <= 60) {
  //     data.start_time = `${startTime?.$H ? startTime?.$H :'00'}:${startTime?.$m ? startTime?.$m :'00'}`
  //    }else {
  //     data.start_time = startTime 
  //    }

  //    if(endTime?.$H || endTime?.$m){
  //     data.end_time = `${endTime?.$H ? endTime?.$H :''}:${endTime?.$m ? endTime?.$m :''}`
  //    }else{
  //     data.end_time = endTime
  //    }
  //     const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  //     await axios
  //       .post(`${apiConfigs.API_URL}api/organization/get-rate`, {...data, date: moment(data.date).format("YYYY-MM-DD")}, {
  //         headers: {
  //           "content-type": "application/json",
  //           Authorization: `Bearer ${loggedInUser}`,
  //         },
  //       })
  //       .then((response) => {
  //         const output = response.data.data;
  //         if (output?.status === false) {
  //           setBreakDateErr({
  //             date: true,
  //             break: true,
  //           });
  //           data.chargeable = data.payable = "";
  //           data.date = "";
  //           data.break = "";
  //           data.non_awr_paye_chargeble = "";
  //           data.awr_umbrella_chargeble = "";
  //           data.awr_umbrella_chargeble = "";
  //           data.non_awr_umbrella_chargeble = "";
  //           data.non_awr_paye_payable = "";
  //           output.awr_umbrella_payableAmount = "";
  //           data.non_awr_umbrella_payable = "";
  //           data.total_hours = "";
  //           data.total_holiday_hour = "";
  //           data.total_saturday_hour = "";
  //           data.total_day_hour = "";
  //           data.total_night_hour = "";
  //           setData({ ...data });
  //           setRateNotify(output.message);
  //         } else {
  //           setBreakDateErr({
  //             date: false,
  //             break: false,
  //           });
  //           data.chargeable = Number(output.chargebleAmount);
  //           data.payable = Number(output.payableAmount);
  //           data.non_awr_paye_chargeble = output.non_awr_paye_chargebleAmount;
  //           data.awr_umbrella_chargeble = output.awr_umbrella_chargebleAmount;
  //           data.non_awr_umbrella_chargeble =
  //             output.non_awr_umbrella_chargebleAmount;
  //           data.non_awr_paye_payable = output.non_awr_paye_payableAmount;
  //           data.awr_umbrella_payable = output.awr_umbrella_payableAmount;
  //           data.non_awr_umbrella_payable =
  //             output.non_awr_umbrella_payableAmount;
  //           data.total_hours = output.totalhours;
  //           data.total_holiday_hour = output.holiDayhour;
  //           data.total_saturday_hour = output.saturDayhour;
  //           data.total_day_hour = output.dayhour;
  //           data.total_night_hour = output.nighthour;
  //           data.payment_scheme_type = output.payment_scheme_type;
  //           setData({ ...data });
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("error: ", error);
  //       });
  //   }
  // };

  const getHoursList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-hours`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setHours(response.data);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  useEffect(() => {
    gethospital();
  }, [getTrustId]);

  const getWardType = async (hospitalId) => {
    dispatch(
      getWardByHospital(hospitalId, getTrustId, (result) => {
        if (result?.status === true) {
          setWardList(result);
        }
      })
    );
  };

  const getShiftType = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-all-shift-type`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setShiftTypeList(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };
 

  const getGradeList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/gradelist?trustId=${getTrustId}&specialityId=${specialityId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === true && response?.data?.data.length > 0) {
          data.grade_id = response?.data?.data[0]?.id;
        }
        setGradeList(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };

  const getShift = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-shifts`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setShiftTime(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };


  const getReference = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-reference`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setData({
          ...data,
          reference_id: parseFloat(response.data.data.reference_id),
        });
        setReferenceId(response.data.data.reference_id);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };


  const submitData = async (e) => {
    if (data.speciality > 0 && data?.date && data?.break) {
      setSpecError(false);
      if(startTime?.$H || startTime?.$m){
        data.start_time = `${startTime?.$H ? startTime?.$H : '00'}:${startTime?.$m ? startTime?.$m : '00'}`
      }
      if(endTime?.$H || endTime?.$m){
        data.end_time = `${endTime?.$H ? endTime?.$H : '00'}:${endTime?.$m ? endTime?.$m : '00'}`
      }
      if (addAnother === true) {
        dispatch(createBooking({...data,superAssignId:candidateAssignDetails?.signeeId ? candidateAssignDetails?.signeeId : '' }, addAnother));
      } else {
        dispatch(createBooking({...data, superAssignId:candidateAssignDetails?.signeeId ? candidateAssignDetails?.signeeId : '' }, addAnother));
      }
      setTrustNotify(true);
      reset();
    } else {
      setSpecError(true);
    }
  };
  const backPage = () => {
    history.goBack();
  };
  const dateHandler = (name,date)=>{
     setData({ ...data, [name]:date });
      data.date = date;
      getSearchBookingDetail();
      getCharge(name,date);
    
  }

useEffect(()=>{
 const sapId = JSON.parse(localStorage.getItem('sapId') || '{}')
 const tempObj = bookingDetail?.data?.matching?.find((x) => {
  return x?.signeeId === sapId?.signeeId})
 if(tempObj) {
    setCandidateAssignDetails(tempObj)
  } else {
    setCandidateAssignDetails({});
    localStorage.removeItem('sapId')
  }
},[bookingDetail?.data?.matching])

let typingTimeout = null;

const handleEndTimeInputChange = (newValue) => {
  // console.log("newValuewwww", newValue);
  // setEndTime(newValue);
  // clearTimeout(typingTimeout);
  // typingTimeout = setTimeout(() => {
  //   getCharge();
  //   getSearchBookingDetail();
  // }, 500); // Adjust the delay as needed
};
   
useEffect(() =>{
  if(endTime){
  getCharge();
  getSearchBookingDetail();
}
if(startTime){
  getCharge();
  getSearchBookingDetail();
}
},[endTime,startTime])


  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {trustNotify && createBookingSuccess?.message && (
        <Notification data={createBookingSuccess?.message} status="success" />
      )}
      {trustNotify && createBookingError?.message && (
        <Notification data={createBookingError?.message} status="error" />
      )}
      {rateNotify && rateNotify && (
        <Notification data={rateNotify} status="error" />
      )}
      {specError && speciality?.data === undefined && (
        <Notification
          // key={Date.now()}
          data={"speciality not found please add it."}
          status="error"
        />
      )}

      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(submitData)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="reference_id"
                label="Reference Id"
                variant="outlined"
                name="reference_id"
                value={referenceId || ""}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.staff_id ? true : false}
              >
                <InputLabel>Consultant Name</InputLabel>
                <Select
                  value={data.staff_id}
                  label="Consultant Name"
                  name="staff_id"
                  {...register("staff_id", {
                    required: "The Consultant field is required.",
                  })}
                  // error={(errors.staff_id ? true : false)}
                  onChange={trustHandleChange}
                >
                  <MenuItem value="">Select Consultant</MenuItem>
                  {staff?.data !== undefined &&
                    staff?.data &&
                    staff?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="booking_reference"
                label="Booking Reference"
                type="text"
                name="booking_reference"
                value={data?.booking_reference}
                className={classes.textField}
                variant="outlined"
                {...register("booking_reference", {
                  required: "The Booking Reference field is required.",
                })}
                error={errors.booking_reference ? true : false}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                inputProps={{
                  min: 60,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.trust_id ? true : false}
              >
                <InputLabel>Customer Name</InputLabel>
                <Select
                  value={data.trust_id}
                  label="Customer Name"
                  name="trust_id"
                  {...register("trust_id", {
                    required: "The Customer field is required.",
                  })}
                  // error={(errors.trust_id ? true : false)}
                  onChange={trustHandleChange}
                >
                  <MenuItem value="">Select Customers</MenuItem>
                  {trust?.data !== undefined &&
                    trust?.data &&
                    trust?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={
                  errors.hospital_id
                    ? true
                    : createBookingError?.message?.hospital_id
                    ? true : data?.hospital_id === "" ? false :
                     false 
                }
              >
                <InputLabel>Site Name</InputLabel>
                <Select
                  value={data.hospital_id}
                  label="Site Name"
                  name="hospital_id"
                  {...register("hospital_id", {
                    required: "The site field is required.",
                  })}
                  // error={(errors.hospital_id ? true : createBookingError?.message?.hospital_id ? true : false)}
                  onChange={hospitalHandleChange}
                >
                  <MenuItem value="">Select Hospital</MenuItem>
                  {hospitalList?.data !== undefined &&
                    hospitalList?.data &&
                    hospitalList?.data.map((List, index) => {
                      return (
                        <MenuItem value={List.id} key={index}>
                          {List.hospital_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={
                  errors.ward_id
                    ? true
                    : createBookingError?.message?.ward_id
                    ? true
                    : false
                }
              >
                <InputLabel>Ward Name</InputLabel>
                <Select
                  value={data?.ward_id}
                  name="ward_id"
                  label="Ward Name"
                  {...register("ward_id", {
                    required: "The ward field is required.",
                  })}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a ward</MenuItem>
                  {wardList?.data !== undefined &&
                    wardList?.data &&
                    wardList?.data.map((list, index) => {
                      return (
                        <MenuItem value={list.id} key={index}>
                          {list.ward_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.speciality ? true : false}
              >
                <InputLabel>Speciality </InputLabel>
                <Select
                  value={data.speciality}
                  label="Speciality"
                  name="speciality"
                  {...register("speciality", {
                    required: "The speciality field is required.",
                  })}
                  // onChange={handleChangeCheck}
                  onChange={handleChange}
                >
                  {speciality?.data !== undefined &&
                    speciality?.data &&
                    speciality?.data.map((items, index) => {
                      return (
                        <MenuItem value={items.id} key={index}>
                          {items.speciality_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.grade_id ? true : false}
              >
                <InputLabel>Grade Required </InputLabel>
                <Select
                  value={gradeId}
                  label="Grade Required"
                  name="grade_id"
                  {...register("grade_id", {
                    required: "The grade field is required.",
                  })}
                  displayEmpty
                  // onChange={handleChangeCheck}
                  onChange={handleChange}
                >
                <MenuItem value="">Select a grade</MenuItem>
                  {gradeList?.data !== undefined &&
                    gradeList?.data &&
                    gradeList?.data.map((items, index) => {
                      return (
                        <MenuItem value={items.id} key={index}>
                          {items.grade_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.grade_id ? true : false || gradeId === '' ? false : false}
              >
                <InputLabel>Grade Required</InputLabel>
                <Select
                  value={gradeId}
                  // value={data.grade_id}  //Old value
                  name="grade_id"
                  label="Grade Required"
                  {...register("grade_id", {
                    required: "The grade field is required.",
                  })}
                  defaultValue={""}
                  // error={(errors.grade_id ? true : false)}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a grade</MenuItem>
                  {gradeList?.data !== undefined &&
                    gradeList?.data &&
                    gradeList?.data.map((list, index) => {
                      return (
                        <MenuItem value={list.id} key={index}>
                          {list.grade_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
             
              {/* <TextField
                id="date"
                label="Date--"
                type="date"
                name="date"
                value={data?.date}
                className={classes.textField}
                variant="outlined"
                {...register("date", {
                  required: "The date field is required.",
                })}
                error={errors.date ? true : breakDateErr?.date ? true : false}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                  <DatePicker
                  id="date"
                  label="Date"
                  type="date"
                  name="date"
                  value={data.date}
                  className={classes.textField}
                  onChange={(e) =>{dateHandler("date",e)}}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  error={errors.date ? true : breakDateErr?.date ? true : false}
                  style={{ width: "100%" }}
                  renderInput={props => <TextField variant="outlined" required fullWidth {...props}  />}
                  inputFormat="dd-MMM-yyyy"                          
                  required
                  firstDayOfWeek={1} 
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <TimePicker
                    label="Start Time"
                    value={startTime}
                    // onChange={setStartTime}
                    onChange={(newValue) => {
                      setStartTime(newValue)
                       getCharge();
                       getSearchBookingDetail();
                      }}
                    renderInput={(params) => <TextField {...params} variant="outlined"  onFocus={handleChangeStartTime}/>}
                    // className="customClock"
                    ampm={false}  
                    hours12={false}
                    InputLabelProps={{ shrink: true }} 
                  />
                  {/* <TimePicker
                   label="Start Time"
                   value={startTime}
                  //  className="customClock"
                   renderInput={(params) => <TextField {...params}  variant="outlined" />}
                   onChange={(newValue) => {
                   setStartTime(newValue)
                    getCharge();
                    getSearchBookingDetail();
                   }}
                    hours12={false}
                    ampm={false}  
        /> */}
                
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <TimePicker
                      label="End Time"
                      value={endTime}
                      // onChange={setEndTime}
                      onChange={(newValue) => {
                        // handleEndTimeInputChange(newValue);
                        setEndTime(newValue);
                        //  getCharge("end_time",newValue);
                        //  getSearchBookingDetail();
                        }}
                      renderInput={(params) => <TextField {...params} variant="outlined" onFocus={handleChangeEndTime}/>}
                      // className="customClock"
                      ampm={false}  
                      hours12={false}
                      InputLabelProps={{ shrink: true}} 
                    />
                        {/* <TimePicker
                   label="End Time"
                   value={endTime}
                  //  className="customClock"
                   renderInput={(params) => <TextField {...params} variant="outlined"  />}
                   onChange={(newValue) => {
                   setEndTime(newValue)
                    getCharge("end_time",newValue);
                    getSearchBookingDetail();
                   }}
                    hours12={false}
                    ampm={false}  
        /> */}
                  
                  </Stack>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="break"
                label="Break (In Minutes)"
                type="number"
                name="break"
                value={data?.break}
                maxLength={2}
                className={classes.textField}
                variant="outlined"
                {...register("break", {
                  required: "The break field is required.",
                  validate: (value) => value <= 120 || "Maximum value is 120"
                })}
                error={errors.break ? true : breakDateErr?.break ? true : false}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                  maxLength: 3,
                }}
                inputProps={{
                  max: 120,
                  onWheel: (event) => event.target.blur(),
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="total_hours"
                label="Total Hours"
                type="text"
                name="total_hours"
                // defaultValue="2017-05-24"
                className={classes.textField}
                variant="outlined"
                // {...register("total_hours", {
                //   required: "The Total hours field is required.",
                // })}
                error={errors.total_hours ? true : false}
                onChange={handleChange}
                disabled={true}
                value={data?.total_hours}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={errors.booking_type ? true : false}
                disabled={data?.payment_scheme_type == "Fixed" ? false : true}
              >
                <InputLabel>Booking Types</InputLabel>
                <Select
                  value={data?.booking_type}
                  label="Booking Types"
                  name="booking_type"
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a Booking Types</MenuItem>
                  <MenuItem value="Day">Day</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                  <MenuItem value="Saturday">Saturday</MenuItem>
                  <MenuItem value="Sunday">Sunday</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="purchase_order"
                label="Purchase Order"
                type="text"
                name="purchase_order"
                className={classes.textField}
                variant="outlined"
                error={errors.purchase_order ? true : false}
                onChange={handleChange}
                readOnly={true}
                value={data?.purchase_order}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>Amount</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="non_awr_paye_payable"
                  label="NON-AWR - PAYE"
                  variant="outlined"
                  name="non_awr_paye_payable"
                  type="number"
                  value={data?.non_awr_paye_payable}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="payable"
                  label="AWR PAYE"
                  variant="outlined"
                  name="payable"
                  type="number"
                  value={data?.payable}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="non_awr_umbrella_payable"
                  label="NON-AWR - UMBRELLA"
                  variant="outlined"
                  name="non_awr_umbrella_payable"
                  type="number"
                  value={data?.non_awr_umbrella_payable}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="awr_umbrella_payable"
                  label="AWR -UMBRELLA"
                  variant="outlined"
                  name="awr_umbrella_payable"
                  type="number"
                  value={data?.awr_umbrella_payable}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
          <Box className={classes.footerBtn}>
              {candidateAssignDetails && (candidateAssignDetails?.first_name || candidateAssignDetails?.last_name) &&
              <div style={{
                display:"flex",
                flex:'auto'
              }}>
              <TextField
                  id="NAME_Super_assign"
                  label="Name"
                  size="small"
                  variant="outlined"
                  name="NAME_Super_assign"
                  type="text"
                  value={`${candidateAssignDetails?.first_name} ${candidateAssignDetails?.last_name}`}
                  disabled
                  inputProps={{
                    shrink:true
                  }}
                  sx={{
                    height: '15px'
                  }}
                />
                <Button onClick={() =>{
                  setCandidateAssignDetails({})
                  localStorage.removeItem("sapId")
              }}>
                <CloseOutlined />
                </Button>
              </div>
              }
            <Button size="medium" color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={getSearchBookingDetail}
            >
              Refresh
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant="contained"
              type="submit"
              formNoValidate
              disabled={isNonAwr}
              onClick={() => {
                  setAddAnother(false)
                
              }}
            >
              Save & Confirm
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              formNoValidate
              disabled={isNonAwr}
              onClick={() => setAddAnother(true)}
            >
              Save & Add another
            </Button>
          </Box>
        </form>
      </Paper>
       { <CandidatesList
          bookingDetail={bookingDetail}
          booking_id={booking_id}
          getBookingDetail={getSearchBookingDetail}
          setConfirmBtn={setConfirmBtn}
          setBookingDetail={setBookingDetail}
          initialData={initialData}
          setCandidateAssignDetails={setCandidateAssignDetails}
          searchingVal={searchingVal}
          setSearchingVal={setSearchingVal}
          actionType={'create'}
        />}
    </>
  );
};

export default CreateBooking;
