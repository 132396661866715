import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Divider,
  Button,
  Backdrop,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import apiConfigs from "../../config/config";
import history from "../../utils/HistoryUtils";
import AlertDialog from "../../components/Alert/AlertDialog";
import Notification from "../../components/Notification/Notification";
import { deletePaymentScheme } from "../../store/action";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  desc: {
    fontSize: 16,
  },
  descBreak: {
    fontSize: 16,
    wordBreak: "break-word",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },

  mainWrapper: {
    paddingBottom: "0 !important",
    paddingTop: "24px !important",
  },

  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },

  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },
  hospitalBox: {
    background: "#f8fafc",
    margin: "30px 0",
    // padding:"16px",
    borderRadius: 8,
    width: "100%",
  },
}));

const DetailPaymentScheme = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const id = match.params.id;
  const [Id, setId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentItems, setPaymentSch] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);
  const { deletePaymentSchemeSuccess, deletePaymentSchemeError } = useSelector(
    (state) => state.paymentScheme
  );
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const loginUserInfo = JSON.parse(window.localStorage.getItem("loginUserInfo"));
  
  const getSignlePaymentScheme = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    setLoading(true);
    await axios
      .get(`${apiConfigs.API_URL}api/organization/payment-scheme/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setPaymentSch(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error.message", error.message);
        setLoading(false);
      });
  };

  const upadateLink = () => {
    history.push(`update`);
  };
  const backPage = () => {
    history.go(-2);
  };

  const deletePaymentClose = () => {
    setDeleteOpen(false);
  };

  const alertResponse = (id) => {
    dispatch(deletePaymentScheme(id));
    setStaffNotify(true);
  };

  const deleteStaffItem = (id) => {
    setDeleteOpen(true);
    setId(id);
  };

  useEffect(() => {
    getSignlePaymentScheme();
  }, [id]);
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {staffNotify && deletePaymentSchemeSuccess?.message && (
        <Notification
          data={deletePaymentSchemeSuccess?.message}
          status="success"
        />
      )}{" "}
      {staffNotify && deletePaymentSchemeError?.message && (
        <Notification data={deletePaymentSchemeError?.message} status="error" />
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.mainWrapper}>
            <Typography className={classes.mainTitle}>
              Payment Scheme
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Customers Name
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Speciality:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.speciality_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Grade Name:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.grade_name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Remark:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.remark}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Start Date:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.start_date_show}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              End Date:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.end_date_show}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Payment Scheme Type:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.payment_scheme_type}
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              NHS Product Group:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems?.nhs_product_group}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              LPP Product Group:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems?.lpp_product_group}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              LPP Job Title:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems?.lpp_job_title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              LPP Job Code:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems?.lpp_job_code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              LPP/HTE Job Family:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems?.lpp_hte_job_family}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              MISO Job Title:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems?.miso_job_title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              WTR:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {paymentItems.wtr}
            </Typography>
          </Grid>
          
          <Grid item xs={12} className={classes.mainWrapper}>
            <Typography className={classes.mainTitle}>Rates</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Day Shift</Typography>
              <Box>
                <Typography variant="body2" className={classes.heading}>
                  From To Time
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {paymentItems.payment_scheme_type !== "Fixed" && (
                    <>
                      {paymentItems.day_from} : {paymentItems.day_to}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Pay Rate</TableCell>
                  <TableCell align="left">Charge Rate</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_payable_day_rate) ? ((Math.round(paymentItems?.non_awr_paye_payable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_chargeable_day_rate) ? ((Math.round(paymentItems?.non_awr_paye_chargeable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell scope="row">
                    {(paymentItems?.payable_day_rate) ? ((Math.round(paymentItems?.payable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.chargeable_day_rate) ? ((Math.round(paymentItems?.chargeable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_payable_day_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_payable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_chargeable_day_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_chargeable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_payable_day_rate) ? ((Math.round(paymentItems?.awr_umbrella_payable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_chargeable_day_rate) ? ((Math.round(paymentItems?.awr_umbrella_chargeable_day_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Night Shift</Typography>
              <Box>
                <Typography variant="body2" className={classes.heading}>
                  From-To Time
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {paymentItems.payment_scheme_type !== "Fixed" && (
                    <>
                      {paymentItems.night_from} : {paymentItems.night_to}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Pay Rate</TableCell>
                  <TableCell align="left">Charge Rate</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_payable_night_rate) ? ((Math.round(paymentItems?.non_awr_paye_payable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_chargeable_night_rate) ? ((Math.round(paymentItems?.non_awr_paye_chargeable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell scope="row">
                    {(paymentItems?.payable_night_rate) ? ((Math.round(paymentItems?.payable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.chargeable_night_rate) ? ((Math.round(paymentItems?.chargeable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_payable_night_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_payable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_chargeable_night_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_chargeable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_payable_night_rate) ? ((Math.round(paymentItems?.awr_umbrella_payable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_chargeable_night_rate) ? ((Math.round(paymentItems?.awr_umbrella_chargeable_night_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Saturday Shift</Typography>
              <Box>
                <Typography variant="body2" className={classes.heading}>
                  Saturday From-To Time
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {paymentItems.payment_scheme_type !== "Fixed" && (
                    <>
                      {paymentItems.saturday_from} : {paymentItems.saturday_to}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Pay Rate</TableCell>
                  <TableCell align="left">Charge Rate</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_payable_saturday_rate) ? ((Math.round(paymentItems?.non_awr_paye_payable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_chargeable_saturday_rate) ? ((Math.round(paymentItems?.non_awr_paye_chargeable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell scope="row">
                    {(paymentItems?.payable_saturday_rate) ? ((Math.round(paymentItems?.payable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.chargeable_saturday_rate) ? ((Math.round(paymentItems?.chargeable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_payable_saturday_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_payable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_chargeable_saturday_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_chargeable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_payable_saturday_rate) ? ((Math.round(paymentItems?.awr_umbrella_payable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_chargeable_saturday_rate) ? ((Math.round(paymentItems?.awr_umbrella_chargeable_saturday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Holiday/Sunday Shift</Typography>
              <Box>
                <Typography variant="body2" className={classes.heading}>
                  Holiday/Sunday From-To Time
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {paymentItems.payment_scheme_type !== "Fixed" && (
                    <>
                      {paymentItems.holiday_from} : {paymentItems.holiday_to}
                    </>
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Pay Rate</TableCell>
                  <TableCell align="left">Charge Rate</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_payable_holiday_rate) ? ((Math.round(paymentItems?.non_awr_paye_payable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_paye_chargeable_holiday_rate) ? ((Math.round(paymentItems?.non_awr_paye_chargeable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell scope="row">
                    {(paymentItems?.payable_holiday_rate) ? ((Math.round(paymentItems?.payable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.chargeable_holiday_rate) ? ((Math.round(paymentItems?.chargeable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">PAYE</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_payable_holiday_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_payable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.non_awr_umbrella_chargeable_holiday_rate) ? ((Math.round(paymentItems?.non_awr_umbrella_chargeable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">Non AWR</TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_payable_holiday_rate) ? ((Math.round(paymentItems?.awr_umbrella_payable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">
                    {(paymentItems?.awr_umbrella_chargeable_holiday_rate) ? ((Math.round(paymentItems?.awr_umbrella_chargeable_holiday_rate * 100)/ 100).toFixed(2)) : "0.00"}
                  </TableCell>
                  <TableCell scope="row">Umbrella</TableCell>
                  <TableCell scope="row">AWR</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.btnContainer}
            >
              <Button color="primary" onClick={backPage}>
                Back
              </Button>
              {staffDetail !== "Booking" && staffDetail !== "Finance" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={upadateLink}
                  >
                    <EditIcon className="mr-2" />
                    Edit
                  </Button>
                </>
              )}
              {(staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION")  && (
                   <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => deleteStaffItem(paymentItems.id)}
                  >
                    <DeleteIcon className="mr-2" />
                    Delete
                  </Button>
                )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <AlertDialog
        id={Id}
        open={deleteOpen}
        close={deletePaymentClose}
        response={alertResponse}
        title="Delete Payment Scheme"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />
    </>
  );
};

export default DetailPaymentScheme;
