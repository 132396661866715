import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserNotificationPrefs,activeOrDeactiveNotification } from "../../store/action/userNotificationPreference/userNotificationPreferenceAction";
import EnhancedTableHead from './EnhancedTableHead'
import { headCells } from './Headcells'
import AlertDialog from "../../components/Alert/AlertDialog";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

const UserNotificationPreference = ({user_id}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('id')
  const { getUserNotificationPrefsItem, loading } = useSelector((state) => state.userNotificationPrefs);
  const [activeOpen, setActiveOpen] = useState(false);
  const [deActiveOpen, setDeActiveOpen] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);
  const [prefId, setPrefId] = React.useState(0)

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order,
  ) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
  }
  
  const aActiveClose = () => {
    setActiveOpen(false);
  };
  const dActiveClose = () => {
    setDeActiveOpen(false);
  };
  const alertActiveResponse = (id) => {
    dispatch(activeOrDeactiveNotification(user_id,id,1));
    setStaffNotify(true);
    dispatch(getUserNotificationPrefs(user_id,orderBy,order));
  };
   const alertDeActiveResponse = (id) => {
    dispatch(activeOrDeactiveNotification(user_id,id,0));
    setStaffNotify(true);
    dispatch(getUserNotificationPrefs(user_id,orderBy,order));
  };

  const onHandlActive = (id) => {
    setActiveOpen(true);
    setPrefId(id);
  };

  const onHandlDeActive = (id) => {
    setDeActiveOpen(true);
    setPrefId(id);
  };
  const onChangeSwitch = (event, rowId) => {
    if (event.target.checked) {
      onHandlActive(rowId);
    } else {
      onHandlDeActive(rowId);
    }
    dispatch(getUserNotificationPrefs(user_id,orderBy,order));
  }


  useEffect(() => {
    dispatch(getUserNotificationPrefs(user_id,orderBy,order));
  }, [dispatch,user_id,orderBy,order]);

  
  return (
    <>
      <Paper className={`${classes.root} mb-6`}>
        <Box
          className="mb-5"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <div className="ml-5">
            
          </div>

          <div className="ml-5">
          </div>
        </Box>

        <Table className={classes.table}>
            <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, property) => {
                handleRequestSort(property, setOrder, setOrderBy, orderBy, order)
                }}
                rowCount={getUserNotificationPrefsItem?.data?.data?.length}
                headCells={headCells[0]}
            />
          
          <TableBody>
            {getUserNotificationPrefsItem?.data &&
              getUserNotificationPrefsItem.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell scope="row">{index + 1}</TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="right">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      > <Switch
                          color="secondary"
                          onChange={(e) => onChangeSwitch(e, row.id)}
                          checked={!row?.pref_id > 0}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {!getUserNotificationPrefsItem?.data && (
              <TableRow>
                <TableCell scope="row" colSpan="15">
                  <div className="" align="center">
                    Sorry,User Notification Preference not available!
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}

      <AlertDialog
        id={prefId}
        open={activeOpen}
        close={aActiveClose}
        response={alertActiveResponse}
        title="Active Notification Preference"
        description="Are you sure you want to active this Notification Preference?"
        buttonName="Active"
      />

       <AlertDialog
        id={prefId}
        open={deActiveOpen}
        close={dActiveClose}
        response={alertDeActiveResponse}
        title="Deactive Notification Preference"
        description="Are you sure you want to deactive this Notification Preference?"
        buttonName="Deactive"
      />
    </>
  );
};

export default UserNotificationPreference;
