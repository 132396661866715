
const apiConfigs = {
    // API_URL: (window.location.hostname === "localhost")? process.env.REACT_APP_PLUTO_ADMIN_API_LOCAL_URL : process.env.REACT_APP_PLUTO_ADMIN_API_URL,
    API_URL: (window.location.hostname === "localhost") ? process.env.REACT_APP_PLUTO_ADMIN_API_LOCAL_URL : 
    (window.location.hostname === "adminbooking.kanhasoftdev.com")  ? process.env.REACT_APP_PLUTO_ADMIN_API_DEV_URL : process.env.REACT_APP_PLUTO_ADMIN_API_LIVE_URL,

    PAYPAL_CLIENT_ID : (window.location.hostname === "localhost") ? 'AcMUBoMDEGRqVJ-57REw2aixWaghjJUcj0JzX8Mgr9hb2PBlWx1y6LHmw5zRMuiqbDvNG69F2IxeJeTy' : 
    (window.location.hostname === "adminbooking.kanhasoftdev.com")  ? 'AcMUBoMDEGRqVJ-57REw2aixWaghjJUcj0JzX8Mgr9hb2PBlWx1y6LHmw5zRMuiqbDvNG69F2IxeJeTy' : 'AcqP8AdQfhOV_DMoUXnqOYhkQGlNcL_htvuRwjiou5bm7DFV8MSQTltQUGBB6nKXoqk4skr73GlUY9Mz',
  
  };
  
  export default apiConfigs;