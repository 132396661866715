import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { addContactEventForSignee,fetchCandidates } from "../../store/action";
const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const CreateContactEvent = ({ open, handleClose, signee_id }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [eventMsg, seteventMsg] = useState(false);  
  const { addCandidateEventSuccess, addCandidateEventError,candidates ,getCandidateError} = useSelector(
    (state) => state.signee
  );
  const [data, setData] = useState({
    comment: "",
    signee_id: signee_id,
  });
  const [staff,setStaff] = useState([]);
  const [formError, setError] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
   const handleChange = (event) => {
     setInputValue(event.target.value);
     if (event.target.value) {
       setError([]);
     } else {
       setError("err_comment");
     }
     const mentionRegex = /@(\w+)/g;
     const mentions = [];
     let match;

     while ((match = mentionRegex.exec(event.target.value)) !== null) {
         mentions.push(match[1]); // Push the matched name (without '@') into the mentions array
     }
     const mentionName = mentions.join(', ');
     if (mentionName) {
       dispatch(fetchCandidates(mentionName)); // Fetch candidates based on the query
       setShowDropdown(true);
     } else {
         setShowDropdown(false); // Hide the dropdown if no mention
     }
     setData({ ...data, [event.target.name]: event.target.value });
   };

   const handleCandidateSelect = (candidate) => {
     const mentionRegex = /@(\w*)$/;
     const candidateName = `${candidate.first_name} ${candidate.last_name},`;    
     const updatedValue = inputValue.replace(mentionRegex, candidateName);
    setStaff((prevStaff) => {
       if (!prevStaff.includes(candidate.id)) {
         return [...prevStaff, candidate.id]; // Add the candidate ID if it's not already present
       }
       return prevStaff; //
     });
     setInputValue(updatedValue);
     setShowDropdown(false); 
   };
  
    const onSubmitEvent = async (event) => {
      event.preventDefault();
      data.signee_id = signee_id;
      data.comment = inputValue;
      data.staff = staff;    
      data.url = window.location.href;
      if (data.comment === "") {
        setError("err_comment");
      } else {
        dispatch(addContactEventForSignee(data));
        seteventMsg(true);
        handleClose();
        setData("");
        setStaff([]);
        setInputValue("");
      }
    };
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, comment: "" });
  };
  return (
    <>
      {getCandidateError?.message && (
        <Notification data={getCandidateError?.message} status="error" />
      )}
      {eventMsg && addCandidateEventError?.message && (
        <Notification data={addCandidateEventError?.message} status="error" />
      )}
      {eventMsg && addCandidateEventSuccess?.message && (
        <Notification
          data={addCandidateEventSuccess?.message}
          status="success"
        />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitEvent(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Create Contact Event</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
               <TextareaAutosize
                margin="dense"
                variant="outlined"
                minRows={4}
                aria-label="Please Enter Comment"
                placeholder="Please Enter Comment"
                defaultValue=""
                name="comment"
                value={inputValue}
                required={true}   
                style={{ width: "100%",  }}
                onChange={handleChange}
              />
              {showDropdown && candidates?.data?.length > 0 && (
                    <table>
                        <tbody>
                            {candidates?.data?.map((candidate) => (
                                <tr key={candidate.id} onClick={() => handleCandidateSelect(candidate)}>
                                    <td style={{width:"10rem"}}>{candidate.first_name} {candidate.last_name}</td>
                                    <td style={{paddingRight:"3rem"}}>{candidate.email}</td>
                                    <td>
                                        <Button onClick={() => handleCandidateSelect(candidate)} color="secondary" variant="contained" type="submit">Select</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateContactEvent;
