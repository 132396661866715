import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Divider,
  Box
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { editContactEventForSignee } from "../../store/action/signee/signeeAction";
import { fetchCandidates } from "../../store/action";
const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const EditContactDialog = ({ open, handleClose, data, title, setData }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [eventMsg, seteventMsg] = useState(false);
  const { editCandidateEventSuccess, editCandidateEventError,candidates } = useSelector(
    (state) => state.signee
  );
  const [mergedIds, setMergedIds] = useState();
  const [formError, setError] = useState([]);
  const [staff,setStaff] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
   useEffect(() => {
     let staffIds = data?.staff_id;
      if (typeof staffIds === 'string') {
        const splitString = staffIds.split(",");
        setMergedIds(splitString);
      } else if (Array.isArray(staffIds)) {
        setMergedIds(staffIds);
      } else {
        setMergedIds([]);
      }      
   },[data?.staff_id]);


   const handleChange = (event) => {
   
     const mentionRegex = /@(\w+)/g; 
     const mentions = [];

     let match;
     const textValue = event.target.value;
     while ((match = mentionRegex.exec(textValue)) !== null) {
         mentions.push(match[1]); // Push the matched name (without '@') into the mentions array
     }
     const mentionName = mentions.join(', ');

     if (mentionName) {
       dispatch(fetchCandidates(mentionName)); // Fetch candidates based on the query
       setShowDropdown(true); // Show the dropdown
     } else {
         setShowDropdown(false); // Hide the dropdown if no mention
     }

       setData({...data, comment: textValue});
       if (textValue) {
         setError([]);
       } else {
         setError("err_comment");
       }
   };
   const handleCandidateSelect = (candidate) => {
     const mentionRegex = /@\w+/g;
     const candidateName = `${candidate.first_name} ${candidate.last_name},`; 
     const updatedValue = data.comment.replace(mentionRegex, candidateName); 

     setStaff((prevStaff) => {
       if (!prevStaff.includes(candidate.id)) {
         return [...prevStaff, candidate.id];
       }
       return prevStaff;
     });
     setData((prevData) => ({
       ...prevData,
       comment: updatedValue, // Update the comment with the replaced value
     }));  
     setShowDropdown(false); 
   };
   const onSubmitEvent = async (event) => {
     const staffData = {
       staff_ids:data.staff_id
     };

     event.preventDefault();
     if (data.comment === "") {
       setError("err_comment");
       data.comment = ""; 
       data.staff_id = null;
     } else {
       data.comment = data.comment;
       data.staff = staff;
       data.staff_id = [...mergedIds,...staff];
       data.url = window.location.href;
       dispatch(editContactEventForSignee(data))
       setData("");
       setStaff([]);
       handleClose();
       seteventMsg(true)
       setTimeout(()=>{
         seteventMsg(false)
       },1000)
     }
   };
  const closeModal = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setError([]);
  };
  return (
    <Box>
      {eventMsg && editCandidateEventError?.message && (
        <Notification data={editCandidateEventError?.message} status="error" />
      )}
   
          
           {eventMsg && <Notification
            data={'Contact Event Updated Successfully'}
            status="success"
            />}
           
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
        >
          
        <form onSubmit={(event) => onSubmitEvent(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Edit Contact Event</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
                <input type='hidden' value={data.id} />
              <TextareaAutosize
                margin="dense"
                variant="outlined"
                minRows={4}
                aria-label="Please Update Comment"
                placeholder="Please Update Comment"
                value={data?.comment}
                name="comment"
                required={true}
                style={{ width: "100%" }}
                onChange={handleChange}
             />
                {showDropdown && candidates?.data?.length > 0 && (
                <table>
                    <tbody>
                        {candidates?.data?.map((candidate) => (
                            <tr key={candidate.id} onClick={() => handleCandidateSelect(candidate)}>
                                <td style={{width:"10rem"}}>{candidate.first_name} {candidate.last_name}</td>
                                <td style={{paddingRight:"3rem"}}>{candidate.email}</td>
                                <td>
                                    <Button onClick={() => handleCandidateSelect(candidate)} color="secondary" variant="contained" type="submit">Select</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                )}
                </div>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default EditContactDialog;
