import axios from "axios"
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config"
import history from "../../../utils/HistoryUtils";
import {
    CREATE_PAYMENT_SCHEME_ERROR, CREATE_PAYMENT_SCHEME_REQUEST, CREATE_PAYMENT_SCHEME_SUCCESS,
    DELETE_PAYMENT_SCHEME_ERROR, DELETE_PAYMENT_SCHEME_REQUEST, DELETE_PAYMENT_SCHEME_SUCCESS,
    GET_PAYMENT_SCHEME_ERROR, GET_PAYMENT_SCHEME_REQUEST, GET_PAYMENT_SCHEME_SUCCESS,
    UPDATE_PAYMENT_SCHEME_ERROR, UPDATE_PAYMENT_SCHEME_REQUEST, UPDATE_PAYMENT_SCHEME_SUCCESS
} from "../actiontypes";

 
export const getPaymentScheme = ({pageNo=1, search = '',type="",column_name=""}) => {
    return async (dispatch) => {
        dispatch(getPaymentSchemeRequest())
        await apiClient(true).get(`api/organization/payment-scheme?search=${search}&page=${pageNo}&type=${type}&column_name=${column_name}`)
       .then(response => {
            const dataItem = response.data
            if (dataItem.status === true) {
                dispatch(getPaymentSchemeSuccess(dataItem))
            } else {
                dispatch(getPaymentSchemeSuccess(''))
                dispatch(getPaymentSchemeError(dataItem))
            }
        }).catch(error => {
            dispatch(getPaymentSchemeError(error))
        })
    }
}

const getPaymentSchemeRequest = () => {
    return {
        type: GET_PAYMENT_SCHEME_REQUEST
    }
}

const getPaymentSchemeSuccess = (data) => {
    return {
        type: GET_PAYMENT_SCHEME_SUCCESS,
        payload: data
    }
}

const getPaymentSchemeError = (error) => {
    return {
        type: GET_PAYMENT_SCHEME_ERROR,
        payload: error
    }
}

export const createPaymentScheme = (data, addAnother) => {
    const loggedInUser = localStorage.getItem('token').replace(/['"]+/g, '');
    return async (dispatch) => {
        dispatch(createPaymentSchemeRequest())
        await axios.post(`${Config.API_URL}api/organization/payment-scheme`, data, {
            'headers': {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser
            }
        }).then(response => {
            const data = response.data
            if (data && data.status === true) {
                if (addAnother === true) {
                    dispatch(createPaymentSchemeSuccess(data))
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                } else {
                    dispatch(createPaymentSchemeSuccess(data))
                    setTimeout(() => {
                        history.goBack();
                    }, 2000);
                }

            } else {
                // dispatch(createPaymentSchemeFailure(data))
                dispatch(createPaymentSchemeFailure(data.message))
            }
        }).catch(error => {
            dispatch(createPaymentSchemeFailure(error))
        })
    }
}

const createPaymentSchemeRequest = () => {
    return {
        type: CREATE_PAYMENT_SCHEME_REQUEST
    }
}

const createPaymentSchemeSuccess = (data) => {
    return {
        type: CREATE_PAYMENT_SCHEME_SUCCESS,
        payload: data
    }
}

const createPaymentSchemeFailure = (error) => {
    return {
        type: CREATE_PAYMENT_SCHEME_ERROR,
        payload: error
    }
}

// -------------------------

export const updatePaymentScheme = (data) => {
    const loggedInUser = localStorage.getItem('token').replace(/['"]+/g, '');
    return async (dispatch) => {
        dispatch(updatePaymentSchemeRequest())
        await axios.put(`${Config.API_URL}api/organization/payment-scheme`, data, {
            'headers': {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser
            }
        }).then(response => {
            const data = response.data
            if (data && data.status === true) {
                dispatch(updatePaymentSchemeSuccess(data))
                setTimeout(() => {
                    history.goBack();
                }, 2000);
            } else {
                dispatch(updatePaymentSchemeFailure(data))
            }
        }).catch(error => {
            dispatch(updatePaymentSchemeFailure(error))
        })
    }
}

const updatePaymentSchemeRequest = () => {
    return {
        type: UPDATE_PAYMENT_SCHEME_REQUEST
    }
}

const updatePaymentSchemeSuccess = (data) => {
    return {
        type: UPDATE_PAYMENT_SCHEME_SUCCESS,
        payload: data
    }
}

const updatePaymentSchemeFailure = (error) => {
    return {
        type: UPDATE_PAYMENT_SCHEME_ERROR,
        payload: error
    }
}

// -------------------------

export const deletePaymentScheme = (id) => {
    const loggedInUser = localStorage.getItem('token').replace(/['"]+/g, '');
    return async (dispatch) => {
        dispatch(deletePaymentSchemeRequest())
        await axios.delete(`${Config.API_URL}api/organization/payment-scheme/${id}`, {
            'headers': {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser
            }
        }).then(response => {
            const data = response.data
            if (data && data.status === true) {
                dispatch(deletePaymentSchemeSuccess(data))
                setTimeout(() => {
                    history.go(-2);
                }, 2000);
            } else {
                dispatch(deletePaymentSchemeFailure(data))
            }
        }).catch(error => {
            dispatch(deletePaymentSchemeFailure(error))
        })
    }
}

const deletePaymentSchemeRequest = () => {
    return {
        type: DELETE_PAYMENT_SCHEME_REQUEST
    }
}

const deletePaymentSchemeSuccess = (data) => {
    return {
        type: DELETE_PAYMENT_SCHEME_SUCCESS,
        payload: data
    }
}

const deletePaymentSchemeFailure = (error) => {
    return {
        type: DELETE_PAYMENT_SCHEME_ERROR,
        payload: error
    }
}
