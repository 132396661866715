export const headCells = [
  //for zero(0) index for Staff
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'first_name',
    label: 'Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'contact_number',
    label: 'Contact Number',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'designation_name',
    label: 'Designation',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'role',
    label: 'Role',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },],

  
]
