import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import history from "../../utils/HistoryUtils";
import axios from "axios";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { deleteUmbrella } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import AlertDialog from "../../components/Alert/AlertDialog";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  desc: {
    fontSize: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },
  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },
}));

const DetailUmbrella = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const user_id = match.params.id;
  const [Id, setId] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [umbrellaNotify, setStaffNotify] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const loginUserInfo = JSON.parse(window.localStorage.getItem("loginUserInfo"));
  const { umbrellaDeleteSuccess, umbrellaDeleteError } = useSelector(
    (state) => state.umbrellaReducer
  );
  const upadateLink = () => {
    history.push(`update`);
  };

  const getSingleStaff = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    setLoading(true);
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/umbrella-company/${user_id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error.message", error.message);
        setLoading(false);
      });
  };

  const deleteUmbrellaItem = (id) => {
    setDeleteOpen(true);
    setId(id);
  };

  const alertResponse = (id) => {
    dispatch(deleteUmbrella(id));
    setStaffNotify(true);
  };

  const deleteRoleClose = () => {
    setDeleteOpen(false);
  };

  const backPage = () => {
    history.go(-2);
  };

  useEffect(() => {
    getSingleStaff();
  }, [user_id]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {umbrellaNotify && umbrellaDeleteSuccess?.message && (
        <Notification
          data={
            umbrellaDeleteSuccess?.message
              ? "Umbrella company deleted successfully"
              : ""
          }
          status="success"
        />
      )}

      {umbrellaNotify && umbrellaDeleteError?.message && (
        <Notification data={umbrellaDeleteError?.message} status="error" />
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Name Of Umbrella
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.name_umbrella}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Bank Name
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.bank_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Bank Account Number
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.bank_account_number}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Email
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Bank Sort Code{" "}
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.bank_sort_code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              VAT(%):
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.vat === 0 ? "" : items?.vat}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              VAT Number
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.vat_number}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Addess
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.address}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.btnContainer}
            >
              <Button color="primary" onClick={backPage}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={upadateLink}>
                <EditIcon className="mr-2" />
                Edit
              </Button>
             
              {(staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION")  && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => deleteUmbrellaItem(items.id)}
                  >
                  <DeleteIcon className="mr-2" />
                  Delete
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <AlertDialog
        id={Id}
        open={deleteOpen}
        close={deleteRoleClose}
        response={alertResponse}
        title="Delete Umbrella company"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />
    </>
  );
};

export default DetailUmbrella;
