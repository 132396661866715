import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  Checkbox,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { createTrust } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import CloseIcon from "@material-ui/icons/Close";
import history from "../../utils/HistoryUtils";
import GoogleClientAddress from "./googleClientAddress";


const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  formControl: {
    width: "100%",
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  hospitalBox: {
    padding: 8,
    width: "100%",
  },
  lightGray: {
    background: "#f4f5f6",
    width: "100%",
    margin: 0,
    padding: "16px 12px",
  },
  wardBox: {
    margin: 0,
    width: "100%",
    position: "relative",
  },

  addWards: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      width: 18,
      height: "auto",
    },
  },
  removeWard: {
    position: "absolute",
    top: 24,
    right: 24,
    cursor: "pointer",
  },
  removeTraining: {
    position: "absolute",
    top: 24,
    right: "20px",
    cursor: "pointer",
  },
  removehospital: {
    position: "absolute",
    top: -9,
    right: -9,
    cursor: "pointer",
    background: "#ff8b46",
    borderRadius: "50%",
    width: 26,
    height: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: 16,
    },
  },
  showError: {
    color: "red",
  },
  disFeild: {
    color: "#757575",
  },
}));

const CreateTrust = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { createTrustError, createTrustSuccess } = useSelector(
    (state) => state.trust
  );
  const [trustNotify, setTrustNotify] = useState(false);
  const [addAnother, setAddAnother] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [data, setData] = useState({
    name: "",
    code: "",
    preference_invoice_method: "",
    email_address: "",
    
    address_line_1: "",
    address_line_2: "",
    city: "",
    post_code: "",
    trust_portal_url: "",
    portal_email: "",
    portal_password: "",
    contact: [
      {
        first_name: "",
        last_name: "",
        contact_email_address: "",
        phone_number: "",
        client: "",
        department: "",
      },
    ],
    framework: "",
    vat: "standard",
    llp_status: "outside LPP",
    llp_trust_code: "",
    cost_center_code: "",
    contract: "",
    cas_connect_no: "",
    awr: "",
    training: [
      {
        training_name: "",
      },
    ],

    hospital: [
      {
        hospital_name: "",
        client_type: "",
        charge_area: "",
        client_address: "",
        ward: [
          {
            ward_name: "",
            phone_number: "",
            ward_type_id: null,
            ward_type: "",
            ward_number: "",
          },
        ],
        latitude: "",
        longitude: ""
      },
    ],
    ward: [
      {
        ward_name: "",
        phone_number: "",
        ward_type_id: null,
        ward_type: "",
        ward_number: "",
      },
    ],
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleCheckboxClick = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setIsChecked(true);
      setData({ ...data, [event.target.name]: "Yes" });
    } else {
      setIsChecked(false);
      setData({ ...data, [event.target.name]: "No" });
    }
  };

  const handleChangeHospital = (index, event, key) => {
    data[key][index][event.target.name] = event.target.value;
    setData({ ...data });
  };

  const handleChangeWardOFHospital = (hIndex, wIndex, event) => {
    data.hospital[hIndex].ward[wIndex][event.target.name] = event.target.value;
    setData({ ...data });
  };

  const addTraining = () => {
    const trainingData = JSON.parse(JSON.stringify(data));
    trainingData.training.push({
      training_name: "",
    });
    setData(trainingData);
  };

  const removeTraining = (index) => {
    const trainingData = JSON.parse(JSON.stringify(data));
    if (trainingData.training.length > 1) {
      trainingData.training.splice(index, 1);
      setData(trainingData);
    }
  };

  const handleChangeContactInfo = (index, event, key) => {
    data[key][index][event.target.name] = event.target.value;
    setData({ ...data });
  };

  const addContactInfo = () => {
    const contactInfoData = JSON.parse(JSON.stringify(data));
    contactInfoData.contact.push({
      first_name: "",
      last_name: "",
      contact_email_address: "",
      phone_number: "",
      client: "",
      department: "",
    });
    setData(contactInfoData);
  };

  const removeContactInfo = (index) => {
    const contactInfoData = JSON.parse(JSON.stringify(data));
    if (contactInfoData.contact.length > 1) {
      contactInfoData.contact.splice(index, 1);
      setData(contactInfoData);
    }
  };

  const addHospital = (e, index) => {
    const hos = JSON.parse(JSON.stringify(data));
    hos.hospital.push({
      hospital_name: "",
      client_type: "",
      charge_area: "",
      client_address: "",
      ward: [
        {
          ward_name: "",
          phone_number: "",
          ward_type_id: "",
          ward_number: "",
        },
      ],
    });
    setData(hos);
  };

  const wards = (id) => {
    const wards1 = JSON.parse(JSON.stringify(data));
    wards1.hospital[id].ward.push({
      ward_name: "",
      phone_number: "",
      ward_type_id: "",
      ward_number: "",
    });
    setData(wards1);
  };

  const removehospital = (index) => {
    const hos = JSON.parse(JSON.stringify(data));
    if (hos.hospital.length > 1) {
      hos.hospital.splice(index, 1);
      setData(hos);
    }
  };

  const removeWards = (index, wIndex) => {
    const wards1 = JSON.parse(JSON.stringify(data));
    if (wards1.hospital[index].ward.length > 1) {
      wards1.hospital[index].ward.splice(wIndex, 1);
      setData(wards1);
    }
  };

  const submitData = async (e) => {
    if (addAnother === true) {
      dispatch(createTrust(data, addAnother));
    } else {
      dispatch(createTrust(data, addAnother));
    }
    setTrustNotify(true);
  };
  const backPage = () => {
    history.goBack();
  };

 
  return (
    <>
      {trustNotify && createTrustSuccess?.message && (
        <Notification data={createTrustSuccess?.message} status="success" />
      )}

      {trustNotify &&
        (createTrustError?.message?.code || createTrustError?.message) && (
          <Notification
            data={
              createTrustError?.message?.code
                ? createTrustError?.message?.code
                : createTrustError?.message
            }
            status="error"
          />
        )}
      {trustNotify &&
        (Object.values(createTrustError)?.length > 0 || createTrustError) && (
          <Notification
            data={Object.values(createTrustError)[0]}
            status="error"
          />
        )}
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(submitData)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="name"
                label="Customer Name"
                variant="outlined"
                name="name"
                {...register("name", {
                  required: "The name field is required.",
                })}
                error={errors.name ? true : false}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="code"
                label="Customer Code"
                variant="outlined"
                name="code"
                // value={data?.code}
                {...register("code", {
                  required: "Please enter code",
                })}
                error={errors.code ? true : false}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="framework"
                label="Framework"
                variant="outlined"
                name="framework"
                error={errors.framework ? true : false}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className="mt-3">
                <Typography>VAT</Typography>
                <RadioGroup
                  name="vat"
                  value={data?.vat || ""}
                  onChange={handleChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="standard"
                    control={<Radio />}
                    label="Standard"
                  />
                  <FormControlLabel
                    value="exempt"
                    control={<Radio />}
                    label="Exempt"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className="mt-3">
                <Typography>LLP Status</Typography>
                <RadioGroup
                  name="llp_status"
                  value={data?.llp_status || ""}
                  onChange={handleChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="outside LPP"
                    control={<Radio />}
                    label="Outside LPP"
                  />
                  <FormControlLabel
                    value="inside LPP"
                    control={<Radio />}
                    label="Inside LPP"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Checkbox
                checked={isChecked}
                label="AWR"
                name="awr"
                value={data?.awr}
                // onChange={handleChange}
                onClick={(event) => handleCheckboxClick(event)}
                className="mt-2"
              />{" "}
              AWR
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                id="llp_trust_code"
                label="LPP Trust Code"
                variant="outlined"
                name="llp_trust_code"
                value={data?.llp_trust_code}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                id="cost_center_code"
                label="Cost Center Code"
                variant="outlined"
                name="cost_center_code"
                value={data?.cost_center_code}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                id="cas_connect_no"
                label="Cash Connect No"
                variant="outlined"
                name="cas_connect_no"
                value={data?.cas_connect_no}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                id="contract"
                label="Contract"
                variant="outlined"
                name="contract"
                value={data?.contract}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {data.hospital.map((item, index) => {
              return (
                <div className={classes.hospitalBox} key={index}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.lightGray}
                    style={{ position: "relative" }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        id="hospital_name"
                        label="Site Name"
                        variant="outlined"
                        name="hospital_name"
                        value={item?.hospital_name || ""}
                        error={
                          errors.trust_portal_url
                            ? true
                            : createTrustError &&
                              createTrustError?.[
                                `hospital.${index}.hospital_name`
                              ]
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleChangeHospital(index, e, "hospital")
                        }
                        fullWidth
                        required
                      />
                      {index !== 0 && (
                        <div className={classes.removehospital}>
                          <CloseIcon onClick={() => removehospital(index)} />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="client_type"
                        label="Client Type"
                        variant="outlined"
                        name="client_type"
                        value={item?.client_type || ""}
                        error={
                          errors.client_type
                            ? true
                            : createTrustError &&
                              createTrustError?.[
                                `hospital.${index}.client_type`
                              ]
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleChangeHospital(index, e, "hospital")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="charge_area"
                        label="Charge Area"
                        variant="outlined"
                        name="charge_area"
                        value={item?.charge_area || ""}
                        error={
                          errors.charge_area
                            ? true
                            : createTrustError &&
                              createTrustError?.[
                                `hospital.${index}.charge_area`
                              ]
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleChangeHospital(index, e, "hospital")
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <GoogleClientAddress 
                        item={item} 
                        index={index}
                        register={register}
                        errors={errors}
                        createTrustError={createTrustError}
                        data={data}
                        setData={setData}
                      />

                      {/* <TextField
                        id={`client_address_${index}`}
                        label="Client Address"
                        variant="outlined"
                        name="client_address"
                        value={item?.client_address || ""}
                        {...register("client_address", { required: true })}
                        inputRef={caddress1Ref}
                        error={
                          errors.client_address
                            ? true
                            : createTrustError &&
                              createTrustError?.[
                                `hospital.${index}.client_address`
                              ]
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleChangeHospital(index, e, "hospital")
                        }
                        fullWidth
                        required
                      /> */}
                    </Grid>

                    {item.ward.map((wardsField, wIndex) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          key={wIndex}
                          className={classes.wardBox}
                        >
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="ward_name"
                              label="Ward Name"
                              variant="outlined"
                              name="ward_name"
                              value={wardsField?.ward_name}
                              // {...register('ward_name', {
                              //     required: "Please enter ward name",
                              // })}
                              error={
                                // (errors.ward_name ? true : (createTrustError && createTrustError?.message) ? (createTrustError?.message[`hospital.${index}.ward.${wIndex}.ward_name`] ? true :false) : false)
                                errors.trust_portal_url
                                  ? true
                                  : createTrustError &&
                                    createTrustError?.[
                                      `hospital.${index}.ward.${wIndex}.ward_name`
                                    ]
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                handleChangeWardOFHospital(index, wIndex, e)
                              }
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="ward_type"
                              label="Ward Type"
                              variant="outlined"
                              name="ward_type"
                              type="text"
                              value={wardsField?.ward_type}
                              error={
                                errors.trust_portal_url
                                  ? true
                                  : createTrustError &&
                                    createTrustError?.[
                                      `hospital.${index}.ward.${wIndex}.ward_type`
                                    ]
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                handleChangeWardOFHospital(index, wIndex, e)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              id="phone_number"
                              label="Phone Number"
                              variant="outlined"
                              name="phone_number"
                              type="text"
                              value={wardsField?.phone_number}
                              error={
                                errors.phone_number
                                  ? true
                                  : createTrustError &&
                                    createTrustError?.[
                                      `hospital.${index}.ward.${wIndex}.phone_number`
                                    ]
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                handleChangeWardOFHospital(index, wIndex, e)
                              }
                              fullWidth
                            />
                          </Grid>
                          {wIndex !== 0 ? (
                            <CloseIcon
                              className={classes.removeWard}
                              onClick={() => removeWards(index, wIndex)}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                      );
                    })}

                    <Grid item xs={12}>
                      <Button
                        onClick={() => wards(index)}
                        color="secondary"
                        className={classes.addWards}
                      >
                        <AddCircleOutlineIcon className="mr-3" />
                        <span>Add Wards</span>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
            {/* {data.hospital[0].hospital_name !== "" &&
            data.hospital[0].ward[0].ward_name !== "" &&
            data.hospital[0].ward[0].ward_type !== "" ? ( */}
              <Grid item xs={12}>
                <Button onClick={() => addHospital()} color="secondary">
                  <AddCircleOutlineIcon className="mr-3" />
                  <Typography>Add Site</Typography>
                </Button>
              </Grid>
           

            <Grid item xs={12}>
              <Box className="mt-3">
                <FormControl
                  required
                  error={errors?.preference_invoice_method ? true : false}
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel
                    component="legend"
                    className={
                      createTrustError &&
                      createTrustError.preference_invoice_method &&
                      createTrustError.preference_invoice_method[0]
                        ? classes.showError
                        : classes.disFeild
                    }
                  >
                    Preferred Invoice Method
                  </FormLabel>
                  <RadioGroup
                    name="preference_invoice_method"
                    onChange={handleChange}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="BYPost"
                      control={<Radio />}
                      label="By Post"
                    />
                    <FormControlLabel
                      value="BYEmail"
                      control={<Radio />}
                      label="By Email"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="email_address"
                label="Customer Email"
                variant="outlined"
                name="email_address"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Invoicing Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address_line_1"
                label="Address line 1"
                variant="outlined"
                name="address_line_1"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address_line_2"
                label="Address line 2"
                variant="outlined"
                name="address_line_2"
                value={data.address_line_2}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="city"
                label="Town / City"
                variant="outlined"
                name="city"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="post_code"
                label="Postcode"
                variant="outlined"
                name="post_code"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>

            <Grid item xs={12}>
              <div className="pt-5 pb-4">
                <Divider />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography>Portal Login Detail</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="trust_portal_url"
                label="Customer Portal URl"
                variant="outlined"
                name="trust_portal_url"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="portal_email"
                label="Email Address"
                variant="outlined"
                name="portal_email"
                autoComplete="new-password"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="portal_password"
                type="password"
                label="Password"
                variant="outlined"
                name="portal_password"
                autoComplete="new-password"
                onChange={handleChange}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12}>
              <div className="pt-5 pb-4">
                <Divider />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography>Notes</Typography>
            </Grid>

            {data.training.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  style={{ position: "relative" }}
                >
                  <TextField
                    id="training_name"
                    label="Notes"
                    variant="outlined"
                    name="training_name"
                    value={item?.training_name || ""}
                    onChange={(e) => handleChangeHospital(index, e, "training")}
                    fullWidth
                  />
                  {index !== 0 ? (
                    <CloseIcon
                      className={classes.removeTraining}
                      onClick={() => removeTraining(index)}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6} lg={4}>
              <Button color="secondary" onClick={addTraining}>
                <AddCircleOutlineIcon className="mr-3" />
                <Typography>Add Notes </Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className="pt-5 pb-4">
                <Divider />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography>Contact Information</Typography>
            </Grid>
            {data.contact.map((item, index) => {
              return (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  className={classes.wardBox}
                >
                  <Grid item xs={11}>
                    {index !== 0 && (
                      <div className="pt-5 pb-4">
                        <Divider />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={11} sm={11} lg={11}>
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      className={classes.wardBox}
                    >
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="first_name"
                          label="First Name"
                          variant="outlined"
                          name="first_name"
                          value={item.first_name}
                          onChange={(e) =>
                            handleChangeContactInfo(index, e, "contact")
                          }
                          fullWidth
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="last_name"
                          label="Last Name"
                          variant="outlined"
                          name="last_name"
                          value={item.last_name}
                          onChange={(e) =>
                            handleChangeContactInfo(index, e, "contact")
                          }
                          fullWidth
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="contact_email_address"
                          label="Email"
                          variant="outlined"
                          name="contact_email_address"
                          value={item.contact_email_address}
                          onChange={(e) =>
                            handleChangeContactInfo(index, e, "contact")
                          }
                          fullWidth
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="phone_number"
                          label="Contact Number"
                          variant="outlined"
                          name="phone_number"
                          // type="number"
                          value={item.phone_number}
                          onChange={(e) =>
                            handleChangeContactInfo(index, e, "contact")
                          }
                          fullWidth
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="client"
                          label="Client"
                          variant="outlined"
                          name="client"
                          value={item.client}
                          onChange={(e) =>
                            handleChangeContactInfo(index, e, "contact")
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="department"
                          label="Department"
                          variant="outlined"
                          name="department"
                          value={item.department}
                          onChange={(e) =>
                            handleChangeContactInfo(index, e, "contact")
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1}>
                    {index !== 0 ? (
                      <CloseIcon
                        className={classes.removeWard}
                        onClick={() => removeContactInfo(index)}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6} lg={4}>
              <Button color="secondary" onClick={addContactInfo}>
                <AddCircleOutlineIcon className="mr-3" />
                <Typography>Add Contact Info</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <div className="pt-5 pb-4">
                <Divider />
              </div>
            </Grid>
          </Grid>

          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              name="btn1"
              formNoValidate
              onClick={() => setAddAnother(false)}
            >
              Save & Confirm
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              name="btn2s"
              formNoValidate
              onClick={() => setAddAnother(true)}
            >
              Save & Add another
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default CreateTrust;
