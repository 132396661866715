import { apiClient } from "../../../config/apiClient";

import {
  GET_INVOICE_ERROR,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
}
  from "../actiontypes";


export const getInvoice = ({ pageNo = 1, search = "",customer="",
            consultants = "",candidate="",start_date="",end_date="",
            provisional_status="",type="",column_name="" }) => {
  return async (dispatch) => {
    dispatch(getInvoiceRequest());
    await apiClient(true)
      .get(`api/organization/get-invoice?search=${search}&customer=${customer}&consultant=${consultants}&candidate=${candidate}&start_date=${start_date}&end_date=${end_date}&provisional_status=${provisional_status}&page=${pageNo}&type=${type}&column_name=${column_name}`)
      .then((response) => {
        const dataItem = response?.data;
        if (dataItem.status === true) {
          dispatch(getInvoiceSuccess(dataItem));
        } else {
          dispatch(getInvoiceSuccess(""));
          dispatch(getInvoiceError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getInvoiceError(error));
      });
  };
};

const getInvoiceRequest = () => {
  return {
    type: GET_INVOICE_REQUEST,
  };
};

const getInvoiceSuccess = (data) => {
  return {
    type: GET_INVOICE_SUCCESS,
    payload: data,
  };
};

const getInvoiceError = (error) => {
  return {
    type: GET_INVOICE_ERROR,
    payload: error,
  };
};

// -------------------------
