export const headCells = [
  //for three(0) index
  [
    {
      id: "name",
      numeric: false,
      label: "Customer Code",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "hospital_name",
      numeric: false,
      label: "Site",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "ward_name",
      numeric: false,
      label: "Ward",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "candidate_name",
      label: "Candidate",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "speciality_name",
      label: "Speciality",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "reference_id",
      label: "Reference Number",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "date",
      label: "Shift Date",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "start_time",
      label: "Start Time",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },

    {
      id: "end_time",
      label: "End Time",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },

    {
      id: "break",
      label: "Break",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "total_hours",
      label: "Total Hours",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },

    {
      id: "non_awr_umbrella_chargeble",
      label: "Non Awr Umbrella Chargeble",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },

    {
      id: "awr_umbrella_chargeble",
      label: "Awr Umbrella Chargeble",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },

    {
      id: "non_awr_paye_chargeble",
      label: "Non Awr Paye Chargeble",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "rate_calculation_type",
      label: "Rate Calculation Type",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "payable",
      label: "Pay Amount",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "total_holiday_hour",
      label: "Total Hours Sundays/Bank Holidays",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "total_saturday_hour",
      label: "Total Hours Saturdays",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "total_night_hour",
      label: "Total Hours - Nights",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "total_day_hour",
      label: "Total Hours - Days",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "Invoice",
      label: "Action",
      sortable: false,
      align: "right",
      numIndex: 0,
      activeIndexOnly: true,
    },
  ],
];
