import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import history from "../../../utils/HistoryUtils";
import {
  CREATE_UMBRELLA_ERROR,
  CREATE_UMBRELLA_REQUEST,
  CREATE_UMBRELLA_SUCCESS,
  DELETE_UMBRELLA_ERROR,
  DELETE_UMBRELLA_REQUEST,
  DELETE_UMBRELLA_SUCCESS,
  GET_UMBRELLA_DETAIL_REQUEST,
  GET_UMBRELLA_ERROR,
  GET_UMBRELLA_REQUEST,
  GET_UMBRELLA_SUCCESS,
  UPDATE_UMBRELLA_ERROR,
  UPDATE_UMBRELLA_REQUEST,
  UPDATE_UMBRELLA_SUCCESS,
} from "../actiontypes";

export const getUmbrellDetail = (result) => {
  return async (dispatch) => {
    dispatch(getUmbrellaDetailRequest());
    await apiClient(true)
      .get(`api/organization/get-umbrella-company`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getUmbrellaDetailRequest = () => {
  return {
    type: GET_UMBRELLA_DETAIL_REQUEST,
  };
};

export const getUmbrell = ({ pageNo = 1, search,type="",column_name="" }) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getUmbrellaRequest());
    await axios
      .get(
        `${Config.API_URL}api/organization/get-umbrella-company?search=${search}&page=${pageNo}&type=${type}&column_name=${column_name}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getUmbrellaSuccess(dataItem));
        } else {
          dispatch(getUmbrellaSuccess(""));
          dispatch(getUmbrellaError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getUmbrellaError(error));
      });
  };
};

const getUmbrellaRequest = () => {
  return {
    type: GET_UMBRELLA_REQUEST,
  };
};

const getUmbrellaSuccess = (data) => {
  return {
    type: GET_UMBRELLA_SUCCESS,
    payload: data,
  };
};

const getUmbrellaError = (error) => {
  return {
    type: GET_UMBRELLA_ERROR,
    payload: error,
  };
};

export const createUmbrella = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createUmbrellaRequest());
    await axios
      .post(`${Config.API_URL}api/organization/umbrella-company`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(createUmbrellaSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          dispatch(createUmbrellaFailure(data));
        }
      })
      .catch((error) => {
        dispatch(createUmbrellaFailure(error));
      });
  };
};

const createUmbrellaRequest = () => {
  return {
    type: CREATE_UMBRELLA_REQUEST,
  };
};

const createUmbrellaSuccess = (data) => {
  return {
    type: CREATE_UMBRELLA_SUCCESS,
    payload: data,
  };
};

const createUmbrellaFailure = (error) => {
  return {
    type: CREATE_UMBRELLA_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const updateUmbrella = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateUmbrellaRequest());
    await axios
      .put(
        `${Config.API_URL}api/organization/umbrella-company/${data.id}`,
        data,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + loggedInUser,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(updateUmbrellaSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          dispatch(updateUmbrellaFailure(data));
        }
      })
      .catch((error) => {
        dispatch(updateUmbrellaFailure(error));
      });
  };
};

const updateUmbrellaRequest = () => {
  return {
    type: UPDATE_UMBRELLA_REQUEST,
  };
};

const updateUmbrellaSuccess = (data) => {
  return {
    type: UPDATE_UMBRELLA_SUCCESS,
    payload: data,
  };
};

const updateUmbrellaFailure = (error) => {
  return {
    type: UPDATE_UMBRELLA_ERROR,
    payload: error,
  };
};

// --------------------------

export const deleteUmbrella = (id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteUmbrellaRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/umbrella-company/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(deleteUmbrellaSuccess(data));
          setTimeout(() => {
            history.go(-2);
          }, 2000);
        } else {
          dispatch(deleteUmbrellaFailure(data));
        }
      })
      .catch((error) => {
        dispatch(deleteUmbrellaFailure(error));
      });
  };
};

const deleteUmbrellaRequest = () => {
  return {
    type: DELETE_UMBRELLA_REQUEST,
  };
};

const deleteUmbrellaSuccess = (data) => {
  return {
    type: DELETE_UMBRELLA_SUCCESS,
    payload: data,
  };
};

const deleteUmbrellaFailure = (error) => {
  return {
    type: DELETE_UMBRELLA_ERROR,
    payload: error,
  };
};
