import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import {
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  UPDATE_DOC_TYPES_SUCCESS,
  UPDATE_DOC_TYPES_ERROR,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_DOC_TYPES_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  GET_CATEGORY_BY_ID_REQUEST,
  GET_DOC_BY_CATEGORY_ID_REQUEST,
} from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";

export const getCategory = (column_name="",type="") => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getCategoryRequest());
    await axios
      .get(`${Config.API_URL}api/organization/get-categories?column_name=${column_name}&type=${type}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(getCategorySuccess(data));
        } else {
          dispatch(getCategorySuccess([]));
          dispatch(getCategoryError(data));
        }
      })
      .catch((error) => {
        dispatch(getCategoryError(error));
      });
  };
};

export const getCategoryRequest = () => {
  return {
    type: GET_CATEGORY_REQUEST,
  };
};
export const getCategorySuccess = (data) => {
  return {
    type: GET_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const getCategoryError = (error) => {
  return {
    type: GET_CATEGORY_ERROR,
    payload: error,
  };
};

// --------------------------------
export const getCategoryById = (id, result) => {
  return async (dispatch) => {
    dispatch(getCategoryByIdRequest());
    await apiClient(true)
      .get(`api/organization/get-category/${id}`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

export const getCategoryByIdRequest = () => {
  return {
    type: GET_CATEGORY_BY_ID_REQUEST,
  };
};
// --------------------------------

// --------------------------------
export const getDocTypesByCategoryId = (id, result) => {
  return async (dispatch) => {
    dispatch(getDocTypesByCategoryIdRequest());
    await apiClient(true)
      .get(`api/organization/get-docs-by-category/${id}`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

export const getDocTypesByCategoryIdRequest = () => {
  return {
    type: GET_DOC_BY_CATEGORY_ID_REQUEST,
  };
};
// --------------------------------

export const createCategory = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createCategoryRequest());
    await axios
      .post(`${Config.API_URL}api/organization/add-category`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(createCategorySuccess(data));
          setTimeout(() => {
            dispatch(getCategory());
          }, 2000);
        } else {
          dispatch(createCategoryError(data));
        }
      })
      .catch((error) => {
        dispatch(createCategoryError(error));
      });
  };
};

export const createCategoryRequest = () => {
  return {
    type: CREATE_CATEGORY_REQUEST,
  };
};
export const createCategorySuccess = (data) => {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const createCategoryError = (error) => {
  return {
    type: CREATE_CATEGORY_ERROR,
    payload: error,
  };
};

export const updateDocTypesSuccess = (data) => {
  return {
    type: UPDATE_DOC_TYPES_SUCCESS,
    payload: data,
  };
};
export const updateDocTypesError = (error) => {
  return {
    type: UPDATE_DOC_TYPES_ERROR,
    payload: error,
  };
};

// -------------------------------------

export const deleteCategory = (category_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteCategoryRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-category/${category_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(deleteCategorySuccess(data));
          dispatch(notificationSuccess(data?.message));
          setTimeout(() => {
            dispatch(getCategory());
          }, 2000);
        } else {
          dispatch(deleteCategoryError(data?.message));
          dispatch(notificationFail(data?.message));
        }
      })
      .catch((error) => {
        dispatch(deleteCategoryError(error.response?.data));
        dispatch(notificationFail(error.response?.data));
      });
  };
};

export const deleteCategoryRequest = () => {
  return {
    type: DELETE_CATEGORY_REQUEST,
  };
};
export const deleteCategorySuccess = (data) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const deleteCategoryError = (error) => {
  return {
    type: DELETE_CATEGORY_ERROR,
    payload: error,
  };
};

// --------------------------------
export const updateCategory = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateCategoryRequest());
    await axios
      .post(`${Config.API_URL}api/organization/edit-category`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(updateCategorySuccess(data));
          setTimeout(() => {
            dispatch(getCategory());
          }, 2000);
        } else {
          dispatch(updateCategoryError(data));
        }
      })
      .catch((error) => {
        dispatch(updateCategoryError(error));
      });
  };
};

export const updateDocTypes = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateDocTypesRequest());
    await axios
      .post(`${Config.API_URL}api/organization/update-doc-types`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(updateDocTypesSuccess({loading: false, data: data}));
          setTimeout(() => {
            dispatch(updateDocTypesSuccess({loading: false, data: ""}));
          }, 2000);
        } else {
          dispatch(updateDocTypesError(data));
          setTimeout(() => {
            dispatch(updateDocTypesError({loading: false, data: ""}));
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch(updateDocTypesError(error));
      });
  };
};

export const updateCategoryRequest = () => {
  return {
    type: UPDATE_CATEGORY_REQUEST,
  };
};

export const updateDocTypesRequest = () => {
  return {
    type: UPDATE_DOC_TYPES_REQUEST,
  };
};

export const updateCategorySuccess = (data) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const updateCategoryError = (error) => {
  return {
    type: UPDATE_CATEGORY_ERROR,
    payload: error,
  };
};
