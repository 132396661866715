import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Button,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateNotificationPreference from "./CreateNotificationPreference";
import UpdateNotificationPreference from "./UpdateNotificationPreference";
import AlertDialog from "../../components/Alert/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../components/Notification/Notification";
import EditIcon from "@material-ui/icons/Edit";
import { deleteNotificationPrefs, getNotificationPrefs } from "../../store/action/notificationPreference/notificationPreferenceAction";
import EnhancedTableHead from './EnhancedTableHead'
import { headCells } from './Headcells'

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

const NotificationPreference = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [Id, setId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('title')

  const { getNotificationPrefsItem, loading } = useSelector((state) => state.notificationPrefs);

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleClose = (action) => {
    setOpen(false);
    if (action === "update") {
      getNotificationPrefs(page);
    }
    setOpenUpdate(false);
  };

  const deleteNotificationPref = (pref_id) => {
    setDisabled(true);
    setId(pref_id);
    setDeleteOpen(true);
  };
  const deleteNotificationPrefsClose = () => {
    setDeleteOpen(false);
  };
  const alertResponse = (id) => {
    if (disabled === true) {
      dispatch(deleteNotificationPrefs(id));
    }
    setDisabled(false);
  };

  const handleUpdateClickOpen = (id) => {
    setOpenUpdate(true);
    setId(id);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order,
  ) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
  }
  
  useEffect(() => {
    dispatch(getNotificationPrefs(orderBy,order));
  }, [dispatch,orderBy,order]);

  return (
    <>
      <Paper className={`${classes.root} mb-6`}>
        <Box
          className="mb-5"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <div className="ml-5">
            <Button
              variant="contained"
              style={{ textTransform: "capitalize" }}
              color="secondary"
              onClick={handleClickOpen}
            >
              <AddIcon className="mr-2" />
              Add Notification Preference
            </Button>
          </div>

          <div className="ml-5">
          </div>
        </Box>

        <Table className={classes.table}>
            <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, property) => {
                handleRequestSort(property, setOrder, setOrderBy, orderBy, order)
                }}
                rowCount={getNotificationPrefsItem?.data?.data?.length}
                headCells={headCells[0]}
            />
          
          <TableBody>
            {getNotificationPrefsItem?.data &&
              getNotificationPrefsItem.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell scope="row">{index + 1}</TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="right">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                    
                        <IconButton
                          onClick={() => handleUpdateClickOpen(row.id)}
                          style={{ padding: 6 }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                        <IconButton
                          onClick={() => deleteNotificationPref(row.id)}
                          style={{ padding: 6 }}
                        >
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {!getNotificationPrefsItem?.data && (
              <TableRow>
                <TableCell scope="row" colSpan="15">
                  <div className="" align="center">
                    Sorry, Notification Preference not available!
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      <CreateNotificationPreference open={open} handleClose={handleClose} />
      <UpdateNotificationPreference
        openUpdate={openUpdate}
        handleClose={handleClose}
        id={Id}
      />
    
      <AlertDialog
        id={Id}
        response={alertResponse}
        open={deleteOpen}
        close={deleteNotificationPrefsClose}
        title="Delete Notification Preference"
        description="Are you sure you want to delete Notification Preference?"
        buttonName="Delete"
      />

      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
    </>
  );
};

export default NotificationPreference;
