import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AlertDialog from "../../components/Alert/AlertDialog";
import { deleteStaff } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import {getStaffDetail} from '../../store/action/staff/staffAction';
import { useForm } from "react-hook-form";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const AsigneeStaffEvent = ({ open, handleClose,asigneeId,asigneeName }) => {
const classes = useStyle();
const dispatch = useDispatch();
const [data, setData] = useState({
  staff_id: 0,
});
const { staffDeleteSuccess, staffDeleteError } = useSelector(
    (state) => state.staff
  );
const [deleteOpen, setDeleteOpen] = useState(false);
const [staffNotify, setStaffNotify] = useState(false);
const [staff, setStaff] = useState([]);
const {
  register,
  formState: { errors },
} = useForm();

const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result)
        }
      }),
    )
  }

  useEffect(()=>{
   getStaff();
},[])

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const onSubmitEvent = async (event) => {
      event.preventDefault();
      if (data.staff_id > 0) {
      handleClose();
      setDeleteOpen(true);
      }
  };
  const closeModal = () => {
    setData("");
    handleClose();
  };

   const alertResponse = (id) => {
    dispatch(deleteStaff(id,data.staff_id));
    setStaffNotify(true);
  };

  const deleteRoleClose = () => {
    setDeleteOpen(false);
  };
  return (
    <>
    {staffNotify && staffDeleteSuccess?.message && (
        <Notification
          data={
            staffDeleteSuccess?.message
              ? "Staff member deleted successfully"
              : ""
          }
          status="success"
        />
      )}

      {staffNotify && staffDeleteError?.message && (
        <Notification data={staffDeleteError?.message} status="error" />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitEvent(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Staff</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
              <div>Please transfer all assigned records for {asigneeName} to [Select existing user - dropdown]</div>
               <Grid item xs={12} sm={12}>
                     <Select
                      name="staff_id"
                      id="staff_id"
                       onChange={(e) => handleChange(e)}
                    >
                      <MenuItem value="" >Select Staff</MenuItem>
                      {staff?.data != undefined &&
                      staff?.data &&
                      staff?.data.map((staffDetail, index) => {
                        if(asigneeId !== staffDetail.id)
                        {
                          return (
                            <MenuItem value={staffDetail.id} key={index}>
                              {staffDetail.name}
                            </MenuItem>
                          );
                        }
                        
                      })}
                    </Select>
               </Grid>
            </div>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
        
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              SUBMIT
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AlertDialog
        id={asigneeId}
        open={deleteOpen}
        close={deleteRoleClose}
        response={alertResponse}
        title="Delete Staff Member"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />
    </>
  );
};

export default AsigneeStaffEvent;
