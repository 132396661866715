export const headCells = [
  //for zero(0) index for Umbrella-Company
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'name_umbrella',
    label: 'Name Of Umbrella',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'bank_name',
    label: 'Bank Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'bank_account_number',
    label: 'Bank Account Number',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'bank_sort_code',
    label: 'Bank Sort Code',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'vat',
    label: 'VAT(%)',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'vat_number',
    label: 'VAT Number',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },],

  
]
