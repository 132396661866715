import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox

} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updateDocTypes } from "../../store/action/category/categoryAction";
import Notification from "../../components/Notification/Notification";
import { getDocTypesByCategoryId } from "../../store/action/category/categoryAction";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const DocType = ({ openDocType, handleDocTypeClose, id }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateMsg, setUpdateMsg] = useState(false);
  const { docTypesSuccess } = useSelector((state) => state.category);
  const [data, setData] = useState({
    category_id: id,
  });

  const [docTypes, setDocTypes] = React.useState([]);

  useEffect(() =>{
    if(data.length) {
      const ids = data.filter(val => val.is_selected === 1).map(val => val.sub_category_id)
      setDocTypes([...ids]);
    }
  },[data])
  
  const handleChangeCheckbox = (id) => {
    let arr = [...docTypes]
    let index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    setDocTypes([...arr]);
  };

  const getData = async (id) => {
    if (id > 0) {
      try {
        dispatch(
          getDocTypesByCategoryId(id, (result) => {
            if (result?.status === true) {
              setData(result?.data);
            }
          })
        );
      } catch (error) {
        console.error("error.message", error.message);
      }
    }
  };
  useEffect(() => {
    setData("");
    getData(id);
  }, [id]);

  const submitDocTypes = () => {
    let temp = {
    doc_types : docTypes,
    id : id
    }
    
    dispatch(updateDocTypes(temp));
    setUpdateMsg(true);
  };

  useEffect(() => {
    if (updateMsg && docTypesSuccess?.message) {
      handleDocTypeClose("update");
    }
  }, [handleDocTypeClose, updateMsg, docTypesSuccess]);

  return (
    <>
      {updateMsg && docTypesSuccess?.message && (
        <Notification data={docTypesSuccess?.message} status="success" />
      )}
      <Dialog
        open={openDocType}
        onClose={(e) => handleDocTypeClose("cancel")}
        classes={{ paper: classes.dialogWidth }}
      >
        <form>
          <DialogTitle id="form-dialog-title">
            <div>Add Doc Types</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2}>
              {data.length > 0 && data?.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                      <FormControlLabel onChange={() => handleChangeCheckbox(item.sub_category_id)} control={<Checkbox checked={docTypes.includes(item.sub_category_id)}  />} label={item.title} />
                </Grid>
            
              ))}
            </Grid>

          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={(e) => handleDocTypeClose("cancel")} color="primary">
              Cancel
            </Button>
            <Button
              onClick={submitDocTypes}
              color="secondary"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DocType;
