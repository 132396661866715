import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  TextField,
  Menu,
  Tooltip,
  InputBase,
  Typography,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { changeShiftStatus, getBooking } from "../../store/action";
import history from "../../utils/HistoryUtils";
import Notification from "../../components/Notification/Notification";
import apiConfigs from "../../config/config";
import axios from "axios";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { getStaffDetail, getCandidateList } from "../../store/action/staff/staffAction";
import {
  getBookingTrust,
  getExpense,
  getHospitalList,
  getWardByHospital,
} from "../../store/action/booking/bookingAction";
import { getSpecShiftCreate } from "../../store/action/specialities/specialitiesAction";
import AddExpense from "../InvoiceStudio/AddExpense";
import { notificationSuccess } from "../../store/action/notificationMsg";
import { customEnLocale } from "../../helper/customLocale";
const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "800px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  formControl: {
    width: "24%",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,
    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: 140,
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const SingleCandidateList = ({ match,user_id }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const tabList = [
    "CONFIRMED",
    "CANCEL",
    "PROVISIONAL",
    "COMPLETED",
    "EXPENSES"
  ];
  const displayTabList = ['BOOKED', 'CANCEL', 'PROVISIONAL', 'COMPLETED','EXPENSES']
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState({
    search: "",
    specialty: "",
    customer: "",
    site: "",
    ward: "",
    consultants: "",
    candidate: user_id,
    start_date: null,
    end_date: null,
  });
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [bookingNotify, setBookingNotify] = useState(false);
  const [getTrustId, setTrustId] = useState();
  const [getHospitalId, setHospitalId] = useState();
  const [speciality, setSpeciality] = useState([]);
  const [trust, setTrust] = useState([]);
  const [hospitalList, setHospitalList] = useState();
  const [wardList, setWardList] = useState([]);
  const [staff, setStaff] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const { bookingItem, loading, shiftStatusSuccess } = useSelector(
    (state) => state.booking
  );
  const [activeTab, setActiveTab] = useState("");
  const [open, setOpen] = useState(false);
  const [bookingStatus, setBookingStatus] = useState({
    booking_id: "",
    status: "",
  });
  const { getExpenseItem } = useSelector((state) => state.booking);

  const [pdfData, setPdfData] = useState({
    id: [],
  });

  const trustHandleChange = (event) => {
    searchData.site = "";
    searchData.ward = "";
    setTrustId(event.target.value);
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const hospitalHandleChange = (event) => {
    searchData.ward = "";
    setHospitalId(event.target.value);
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
    getWardType(event.target.value);
  };

  const getSpecialities = async () => {
    dispatch(
      getSpecShiftCreate((result) => {
        if (result?.status === true) {
          setSpeciality(result);
        }
      })
    );
  };

  const getTrust = async () => {
    dispatch(
      getBookingTrust((result) => {
        if (result?.status === true) {
          setTrust(result);
        }
      })
    );
  };
  const gethospital = async () => {
    if (getTrustId) {
      dispatch(
        getHospitalList(getTrustId, (result) => {
          if (result?.status === true) {
            setHospitalList(result);
          }
        })
      );
    }
  };
  const getWardType = async (hospitalId) => {
    dispatch(
      getWardByHospital(hospitalId, getTrustId, (result) => {
        if (result?.status === true) {
          setWardList(result);
        }
      })
    );
  };
  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
        }
      })
    );
  };

  const getExpenseData = async (user_id) => {
    dispatch(
      getExpense(user_id)
    );
  };

  const getCandidates = async () => {
    dispatch(
      getCandidateList((result) => {
        if (result?.data) {
          setCandidates(result);
        }
      })
    );
  };

  const getBookingList = (
    pageNo = 1,
    search = "",
    status="CONFIRMED",
    specialty = "",
    customer = "",
    site = "",
    ward = "",
    consultants = "",
    candidate = user_id,
    start_date = "",
    end_date = ""
  ) => {

    dispatch(

      getBooking({
        pageNo,
        search,
        status,
        specialty,
        customer,
        site,
        ward,
        consultants,
        candidate,
        start_date,
        end_date,
      })
    );
  };

  const onhandlClick = (id) => {
    history.push(`/admin/bookings/${id}/detail`);
  };

  const handleChangePage = (pageNo, value) => {
    let status = tabList[activeIndex];
    setPage(value);
    setPdfData(pdfData);
    setTimeout(
      getBookingList(
        value,
        searchData.search,
        status,
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null ? "" : searchData?.start_date,
        searchData?.end_date === null ? "" : searchData?.start_date
      ),
      2000
    );
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    let status = tabList[activeIndex];
    if (d1) {
      setTimeout(
        getBookingList(
          page,
          d1,
          status,
          searchData?.specialty,
          searchData?.customer,
          searchData?.site,
          searchData?.ward,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date === null
            ? ""
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? ""
            : moment(searchData?.end_date).format("DD-MM-yyyy")
        ),
        100
      );
    } else {
      setTimeout(
        getBookingList(
          page,
          "",
          status,
          searchData?.specialty,
          searchData?.customer,
          searchData?.site,
          searchData?.ward,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date === null
            ? ""
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? ""
            : moment(searchData?.end_date).format("DD-MM-yyyy")
        ),
        100
      );
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };
  const handleClickSearch = (event, value) => {

    let status = tabList[activeIndex];
    setPage(value);
    handleMenuClose(true);
    setTimeout(
      getBookingList(
        page,
        searchData.search,
        status,
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? ""
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? ""
          : moment(searchData?.end_date).format("DD-MM-yyyy")
      ),
      1000
    );

  };

  const handleReset = () => {
    let status = tabList[activeIndex];
    let data = {
      search: "",
      specialty: "",
      candidate: user_id,
      customer: "",
      site: "",
      ward: "",
      consultants: "",
      start_date: null,
      end_date: null,
    };
    setSearchData(data);
    handleMenuClose(true);
    setTimeout(getBookingList(page, "", status), 100);
  };

  useEffect(() => {
    getBookingList();
    getSpecialities();
    getTrust();
    getStaff();
    getCandidates();
    if(user_id){
      getExpenseData(user_id);
    }
  }, [user_id]);

  useEffect(() => {
    if (getTrustId) {
      gethospital();
    }
  }, [getTrustId]);

  const tabChange = (index, list) => {
    pdfData.id = []
    setPage(1);
    setSearchData({ ...searchData });
    setActiveIndex(index);
    setActiveTab(list);
    setTimeout(
      getBookingList(
        1,
        searchData?.search,
        list,
        searchData?.specialty,
        searchData?.customer,
        searchData?.site,
        searchData?.ward,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? ""
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? ""
          : moment(searchData?.end_date).format("DD-MM-yyyy")
      ),
      100
    );
  };

  const downloadPdf = async (booking_id, reference_id) => {
    setLoadings(true);
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/generate-invoice/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", reference_id + ".pdf");
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const dublicateBooking = async (paymentSchemeId) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/dublicate-booking/${paymentSchemeId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          dispatch(notificationSuccess(response?.data?.message));
          setTimeout(() => {
            getBookingList();
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };
  const multipleInvoiceDownload = async () => {
    setLoadings(true);
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(`${apiConfigs.API_URL}api/organization/multiple-invoice`, pdfData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice.pdf");
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        // setTimesheetError("Sorry timesheet upload failed");
      });
  };
  const handleCheckboxClick = (event, ids) => {
    const multiplePdf = JSON.parse(JSON.stringify(pdfData));
    const isChecked = event.target.checked;
    if (isChecked) {
      multiplePdf.id.push(parseFloat(ids));
      setPdfData(multiplePdf);
    } else {
      const newData = multiplePdf.id.filter((item) => item !== parseFloat(ids));
      multiplePdf.id = newData;
      setPdfData(multiplePdf);
    }
  };
  const handleCheckboxAllClick = (event) => {
    const multiplePdf = JSON.parse(JSON.stringify(pdfData));
    const isChecked = event.target.checked;
    if (isChecked && bookingItem?.data?.data.length > 0) {
      const createArray = [];
      bookingItem?.data?.data.map((row, index) => {
        createArray.push(row.id);
      })
      multiplePdf.id = createArray;
      setPdfData(multiplePdf);
    } else {
      multiplePdf.id = [];
      setPdfData(multiplePdf);
    }

  };
  useEffect(() => {
    if (bookingStatus.booking_id !== "") {
      dispatch(changeShiftStatus(bookingStatus));
      setBookingNotify(true);
      setTimeout(() => {
        getBookingList();
      }, 4000);
    }
  }, [bookingStatus]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  let loggedInRole = localStorage.getItem("role");
  loggedInRole = loggedInRole && loggedInRole.replace(/['"]+/g, "");

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleClose = (action) => {
    setOpen(false);
  };
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {loadings ? (
        <Backdrop className={classes.backdrop} open={loadings}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {bookingNotify && shiftStatusSuccess?.message && (
        <Notification data={shiftStatusSuccess?.message} status="success" />
      )}
    <Typography variant="h5" style={{ marginBottom: 16, marginTop: 15 }}>
        Booking list
      </Typography>
      <Paper className={`${classes.root} mb-6`}>
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          
          <Box sx={{ display: "flex", alignItems: "center", width: "24%" }}>
            <SearchIcon
              className={classes.searchIcondet}
              onClick={handleClickSearch}
            />
            <div className={classes.search}>
              <InputBase
                name="search"
                placeholder="Search…"
                onChange={handleSearchChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "end" }}>
            {activeTab === "EXPENSES" &&
              <Button
                variant="contained"
                style={{ textTransform: "capitalize" }}
                color="secondary"
                onClick={handleClickOpen}
                justifyContent="space-between"
                className={classes.buttonAlign}
              >
                Add Expense
              </Button>
            }
            <Tooltip title="Filters">
              <Button onClick={handleMenu}>
                <FilterListIcon />
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={openMenu}
              onClose={handleMenuClose}
              className={classes.filterBookingSearch}
            >
              <div className={classes.filterBox}>
                <Box className={classes.radioGroup}>
                  <Box mt={3} mb={3} display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Specialty</InputLabel>
                        <Select
                          // value={data.trust_id}
                          label="Specialty"
                          name="specialty"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.specialty}
                        >
                          <MenuItem value="">Select Specialty</MenuItem>
                          {speciality?.data != undefined &&
                            speciality?.data &&
                            speciality?.data.map((items, index) => {
                              return (
                                <MenuItem value={items.id} key={index}>
                                  {items.speciality_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Customer</InputLabel>
                        <Select
                          label="Customer"
                          name="customer"
                          onChange={trustHandleChange}
                          value={searchData?.customer}
                        >
                          <MenuItem value="">Select Customer</MenuItem>
                          {trust?.data != undefined &&
                            trust?.data &&
                            trust?.data.map((trustList, index) => {
                              return (
                                <MenuItem value={trustList.id} key={index}>
                                  {trustList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Site</InputLabel>
                        <Select
                          label="Site"
                          name="site"
                          onChange={hospitalHandleChange}
                          value={searchData?.site}
                        >
                          <MenuItem value="">Select Site</MenuItem>
                          {hospitalList?.data != undefined &&
                            hospitalList?.data &&
                            hospitalList?.data.map((List, index) => {
                              return (
                                <MenuItem value={List.id} key={index}>
                                  {List.hospital_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Ward</InputLabel>
                        <Select
                          label="Ward"
                          name="ward"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.ward}
                        >
                          <MenuItem value="">Select Ward</MenuItem>
                          {wardList?.data != undefined &&
                            wardList?.data &&
                            wardList?.data.map((list, index) => {
                              return (
                                <MenuItem value={list.id} key={index}>
                                  {list.ward_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box mt={2} mb={1} display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Consultants</InputLabel>
                        <Select
                          label="Consultants"
                          name="consultants"
                          onChange={(e) =>
                            setSearchData({
                              ...searchData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={searchData?.consultants}
                        >
                          <MenuItem value="">Select Consultants</MenuItem>
                          {staff?.data != undefined &&
                            staff?.data &&
                            staff?.data.map((trustList, index) => {
                              return (
                                <MenuItem value={trustList.id} key={index}>
                                  {trustList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <Box className={classes.formControl}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={customEnLocale}
                          localeText={{ start: "Check-in", end: "Check-out" }}
                        >
                          <DatePicker
                            label="Start Date"
                            inputFormat="dd/MM/yyyy"
                            firstDayOfWeek={1}

                            onChange={(newValue) =>
                              setSearchData({
                                ...searchData,
                                start_date: newValue,
                              })
                            }
                            value={searchData?.start_date}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box className={classes.formControl}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={customEnLocale}
                          localeText={{ start: "Check-in", end: "Check-out" }}
                        >
                          <DatePicker
                            label="End Date"
                            inputFormat="dd/MM/yyyy"
                                                        firstDayOfWeek={1}

                            onChange={(newValue) =>
                              setSearchData({
                                ...searchData,
                                end_date: newValue,
                              })
                            }
                            value={searchData?.end_date}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                                variant="outlined"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    
                      <Box
                      sx={{ display: "flex", alignItems: "flex-end" }}
                      className={classes.formControl}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: "16px", marginLeft: "16px" }}
                        className={classes.searchIcondet}
                        onClick={handleClickSearch}
                      >
                        Search
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Box>
                    </Box>
                
                  </Box>

                </Box>
              </div>
            </Menu>
            {pdfData.id.length > 0 && (
              <div className="ml-5">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={multipleInvoiceDownload}
                  className={classes.downloadButton}
                >
                  <span className="material-icons mr-2">download</span> Download
                  PDF
                </Button>
              </div>
            )}
            {/* {staffDetail !== "Finance" && (
              <div className="ml-5">
                <Link to={`${match.url}/create`} className="btn btn-secondary">
                  <AddIcon className="mr-2" />
                  Add Booking
                </Link>
              </div>
            )} */}
          </Box>
        </Box>

        <Box className={classes.statusButton}>
          {tabList.map((list, index) => (
            <span
              style={{ textTransform: "capitalize" }}
              className={`btn ${activeIndex === index ? "active" : ""}`}
              key={index}
              onClick={() => tabChange(index, list)}
            >
              {displayTabList[index].toLowerCase()}
            </span>
          ))}
        </Box>
        <Table className={classes.table}>
          <TableHead>
            {activeTab === "EXPENSES" ?
              <TableRow>
                <TableCell align="left">Type of Expense</TableCell>
                <TableCell align="left">Expense Unit</TableCell>
                <TableCell align="left">Pay Rate</TableCell>
                <TableCell align="left">Charge Rate</TableCell>
                <TableCell align="left">Amount Paid</TableCell>
                <TableCell align="left">Amount charged</TableCell>
                <TableCell align="left">Booking Refs.</TableCell>
              </TableRow>
            :<TableRow>
                {activeIndex === 4 && (
                  <TableCell scope="row">
                    <Checkbox
                      checked={(bookingItem?.data?.data.length > 0 && pdfData.id.length === bookingItem?.data?.data.length ? true : false)}
                      onClick={(event) =>
                        handleCheckboxAllClick(event)
                      }
                      className="selectCheckbox"
                    />
                  </TableCell>
                )}
                <TableCell>Id</TableCell>
                <TableCell align="left">Customer Name</TableCell>
                <TableCell align="left">Site Name</TableCell>
                <TableCell align="left">Ward Name</TableCell>
                <TableCell align="left">Grade</TableCell>
                <TableCell align="left">Date</TableCell>
                {activeIndex === 4 && <TableCell>Invoice Date</TableCell>}
                <TableCell align="left">Shift Time</TableCell>
                <TableCell align="left">Created By</TableCell>
                {activeIndex === 0 && <TableCell></TableCell>}
                {activeIndex === 4 && <TableCell align="left">Invoice</TableCell>}
                <TableCell align="right"></TableCell>
              </TableRow>
            }
            
          </TableHead>
          <TableBody>
            {activeTab === "EXPENSES" ?
              <>
                {getExpenseItem?.data?.map((expense, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell align="left">{expense.expense_type}</TableCell>
                        <TableCell align="left">{expense.expense_unit}</TableCell>
                        <TableCell align="left">{expense.pay_rate}</TableCell>
                        <TableCell align="left">{expense.charge_rate}</TableCell>
                        <TableCell align="left">{expense.amount_paid}</TableCell>
                        <TableCell align="left">{expense.amount_charged}</TableCell>
                        <TableCell align="left">{expense.booking_ref ? expense.booking_ref : "N/A"}</TableCell>
                      </TableRow>
                    </>
                  )
                })}
                {getExpenseItem?.data?.length < 0 && (
                  <TableRow>
                    <TableCell scope="row" colSpan="15">
                      <div className="" align="center">
                        Sorry, Expenses not available!
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </>
              :
              <>
                {bookingItem?.data?.data &&
                  bookingItem?.data?.data.map((row, index) => {
                    let userRole = bookingItem?.data?.data[0]['loggedUserDesignation'];
                    userRole = userRole && userRole.replace(/['"]+/g, "");
                    const dateFormate = row.date
                      .toString()
                      .split("-")
                      .reverse()
                      .join("-");
                    const originalDate = row.invoice_date || null;
                    let newdate;
                    if(originalDate){
                      const parts = originalDate.split(" ");
                      const datePart = parts[0];
                      const timePart = parts[1];
                      const dateParts = datePart.split("-");
                      const day = dateParts[2];
                      const month = dateParts[1];
                      const year = dateParts[0];
                      newdate = `${day}-${month}-${year} ${timePart}`;
                    }
                  
                    return (
                      <>
                        {activeTab === "EXPENSES" ?
                        <>
                        <TableRow key={index}>
                          <TableCell align="left">sdcscd</TableCell>
                        </TableRow>
                          
                        </>
                        :
                        <>
                        <TableRow key={index}>
                          {activeIndex === 4 && (
                            <TableCell scope="row">
                              <Checkbox
                                value={row.id}
                                checked={pdfData.id.includes(row.id)}
                                onClick={(event) =>
                                  handleCheckboxClick(event, row.id)
                                }
                                className="selectCheckbox"
                              />
                            </TableCell>
                            )}
                            <TableCell scope="row">
                              {bookingItem?.data?.from + index}
                            </TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.hospital_name}</TableCell>
                            <TableCell align="left">{row.ward_name}</TableCell>
                            <TableCell align="left">{row.grade_name}</TableCell>
                            <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                              {dateFormate}
                            </TableCell>
                            {activeIndex === 4 && (
                              <TableCell align="right">
                                {newdate}
                              </TableCell>
                            )}
                            <TableCell align="left">
                              {row.start_time} <br /> {row.end_time}
                            </TableCell>
                            <TableCell align="left">{row.createdByName} </TableCell>

                            {activeIndex === 4 && (
                              <TableCell align="right">
                                {(loggedInRole === 'ORGANIZATION' || userRole === 'Admin' || userRole === 'Finance') ?
                                  <Link
                                    to="#"

                                    onClick={() => downloadPdf(row.id, row.invoice_number)}
                                    className="btn btn-secondary"
                                  >
                                    Download
                                  </Link> :
                                  <Link
                                    to="#"

                                    component="button"

                                    variant="contained"
                                    disabled
                                    color="secondary"
                                    style={{ cursor: "no-drop" }}
                                    onClick={() => downloadPdf(row.id, row.invoice_number)}
                                    className="btn "
                                  >
                                    Download
                                  </Link>
                                }

                              </TableCell>
                            )}
                            {activeIndex === 0 && (
                              <TableCell className="mr-0">
                                <Link to="#" onClick={() => dublicateBooking(row.id)}>
                                  <FileCopyOutlinedIcon />
                                </Link>
                              </TableCell>
                            )}

                            <TableCell className="ml-0" align="right">
                              <Link
                                to="#"
                                onClick={() => onhandlClick(row.id)}
                                className="btn btn-secondary"
                              >
                                View
                              </Link>
                            </TableCell>
                            </TableRow>
                          </>
                        }
                      </>
                    );
                  })}
                {!bookingItem?.data && (
                  <TableRow>
                    <TableCell scope="row" colSpan="15">
                      <div className="" align="center">
                        Sorry, booking not available!
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </>
            }
            
          </TableBody>
        </Table>
        {/* <Box className="mt-5" display="flex" justifyContent="flex-end">
                    <Pagination onChange={()=>handleChangePage(bookingItem?.data?.current_page)} page={page} count={bookingItem?.data?.last_page} showFirstButton showLastButton />
                </Box> */}
        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={bookingItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
      <AddExpense open={open} handleClose={handleClose} type={"candidate-bookinglist"} candidateId={user_id} setActiveTab={() =>setActiveTab("EXPENSES")}/>
    </>
  );
};

export default SingleCandidateList;
