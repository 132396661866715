import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getTimesheetItem: [],
  getTimesheetError: [],
  deleteError: [],
  deleteSuccess: [],
};

const timesheetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TIMESHEET_REQUEST:
      return updateObject(state, {
        loading: true,
          getTimesheetItem: '',
          getTimesheetError: '',
          deleteSuccess: '',
          deleteError: ''
      });

    case actionTypes.GET_TIMESHEET_SUCCESS:
      return updateObject(state, {
        loading: false,
        getTimesheetItem: action.payload,
      });

    case actionTypes.GET_TIMESHEET_ERROR:
      return updateObject(state, {
        loading: false,
        getTimesheetError: action.payload,
      });

  
    case actionTypes.DELETE_TEMP_TIMESHEET_REQUEST:
      return updateObject(state, {
        loading: true,
        deleteSuccess: "",
        deleteError: "",
      });

    case actionTypes.DELETE_TEMP_TIMESHEET_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteSuccess: action.payload,
      });

    case actionTypes.DELETE_TEMP_TIMESHEET_ERROR:
      return updateObject(state, {
        loading: false,
        deleteError: action.payload,
      });

    default:
      return state;
  }
};

export default timesheetReducer;
