import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Select,
  MenuItem
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "../../store/action/category/categoryAction";
import UtilService from "../../helper/service";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const CreateCategory = ({ open, handleClose }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [roleMsg, setRoleMsg] = useState(false);
  const { categoryErrors, categorySuccess } = useSelector(
    (state) => state.category
  );
  const [data, setData] = useState({
    is_active: "",
    title: "",
  });

  const statusList = [
    {
      id : 1,
      status_name : 'Active',
    },
    {
      id : 0,
      status_name : 'Inactive',
    }
  ];

  const [formError, setError] = useState([]);
  const handleChange = (event) => {
    if (event.target.value) {
      setError([]);
    } else {
      setError("err_is_active");
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSubmitRoles = async (event) => {
    event.preventDefault();
    if (data.is_active === "") {
      setError("err_is_active");
    } else if (data.title === "") {
      setError("err_title");
    } else {
      dispatch(createCategory(data));
      setRoleMsg(true);
      handleClose();
    }
  };
  const closeModal = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, title: "" });
  };

  return (
    <>
      {roleMsg && categoryErrors?.message && (
        <Notification data={categoryErrors?.message} status="error" />
      )}
      {roleMsg && categorySuccess?.message && (
        <Notification data={categorySuccess?.message} status="success" />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitRoles(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Create Compliance Category</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="title"
                  type="text"
                  label="Category Title"
                  variant="outlined"
                  name="title"
                  fullWidth
                  required
                  value={data.title || ""}
                  error={formError.includes("err_title") ? true : false}
                  helperText={
                    formError.includes("err_title")
                      ? "Please enter title"
                      : false
                  }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                  <Select
                    value={data?.is_active}
                    label="Status"
                    name="is_active"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    required
                    onChange={handleChange}
                  >
                    {statusList &&
                      statusList.map((items, index) => {
                        return (
                          <MenuItem value={items.id} key={index}>
                            {items.status_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateCategory;
