import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  InputBase,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getUmbrell } from "../../store/action";
import history from "../../utils/HistoryUtils";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },
  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ViewUmbrella = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name_umbrella");

  const { loading, getUmbrellaItem } = useSelector(
    (state) => state.umbrellaReducer
  );
  const [searchData, setSearchData] = useState({ search: "" });
  const onhandlClick = (id) => {
    history.push(`${match.url}/${id}/detail`);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(getUmbrell1(value), 2000);
  };

  const getUmbrell1 = (
    pageNo = 1,
    search = "",
    type = order,
    column_name = orderBy
  ) => {
    dispatch(getUmbrell({ pageNo, search, type, column_name }));
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(getUmbrell1(page, d1), 100);
    } else {
      setTimeout(getUmbrell1(page, ""), 100);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setTimeout(getUmbrell1(page, searchData.search), 1000);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    getUmbrell1();
  }, [orderBy, order]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Box className="mb-5" display="flex" alignItems="center">
          <SearchIcon
            className={classes.searchIcondet}
            onClick={handleClickSearch}
          />
          <div className={classes.search}>
            <InputBase
              name="search"
              placeholder="Search…"
              onChange={handleSearchChange}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <div className="ml-5">
            <Link to={`${match.url}/create`} className="btn btn-secondary">
              <AddIcon className="mr-2" />
              Add Umbrella Company
            </Link>
          </div>
        </Box>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={getUmbrellaItem?.data?.data?.length}
              headCells={headCells[0]}
            />

            <TableBody>
              {getUmbrellaItem?.data &&
                getUmbrellaItem?.data.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">{index + 1}</TableCell>
                      <TableCell align="left">{`${row.name_umbrella}`}</TableCell>
                      <TableCell align="left">{row.bank_name}</TableCell>
                      <TableCell align="left">
                        {row.bank_account_number}
                      </TableCell>
                      <TableCell align="left">{row.bank_sort_code}</TableCell>
                      <TableCell align="left">
                        {row.vat === 0 ? "" : row.vat}
                      </TableCell>
                      <TableCell align="left">{row.vat_number}</TableCell>
                      <TableCell align="right">
                        <Link
                          to="#"
                          className="btn btn-secondary btn-sm ml-auto"
                          onClick={(e) => onhandlClick(row.id)}
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {getUmbrellaItem?.data?.data == "" && (
                <TableRow>
                  <TableCell scope="row" colSpan="6">
                    <div className="" align="center">
                      Sorry, staff not available!
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={getUmbrellaItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
    </>
  );
};

export default ViewUmbrella;
