import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Drawer,
  ListItemIcon,
  Icon,
  makeStyles,
  Box,
} from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import apiConfigs from "../../config/config";
import "../../assets/css/sidebar.css";

const useStyle = makeStyles({
  logoContainer: {
    // padding: "24px 8px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  drawer: {
    background: "#184a7b",
    boxShadow: "3px 0px 12px rgba(24, 74, 123,0.42)",
  },
  listItemIcon: {
    minWidth: 40,
    color: "#7897b4",
  },
  navLink: {
    color: "#fff",
    display: "block",
    width: "90%",
    margin: "auto auto",
    // margin: "12px auto",
    borderRadius: 4,
    "&.active": {
      background: "#ff8b46",
      "& .activeIcon": {
        color: "#fff",
      },
    },
  },
  bottomLogoContainer: {
    display: "flex",
    // maxWidth:"90%",
    alignItems: "cener",
    justifyContent: "center",
    marginBottom: 10,
  },
  imgTag: {
    borderRadius: "20px",
    maxWidth: "120px",
  },
  navLinkSubMenu: {
    color: "#fff",
    display: "block",
    width: "90%",
    margin: "auto auto",
    // margin: "12px auto",
    borderRadius: 4,
    "&.active": {
      background: "",
      "& .activeIcon": {
        color: "#fff",
      },
    },
  },

  menuToggle: {
    width: "26px",
    position: "absolute",
    top: "6px",
    right: "8px",
    cursor: "pointer",
    "& span": {
      display: "inline-block",
      width: "100%",
      height: "2px",
      backgroundColor: "#174776",
      float: "left",
      margin: "3px 0",
    },
  },
});

const Sidebar = (props) => {
  const { routes } = props;
  const classes = useStyle();
  const [url, setUrl] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [finMenu, setFinMenu] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  let authAdmin = localStorage.getItem("admin");
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  authAdmin = loginUserInfo.role === "SUPERADMIN" ? false : true;

  const getAuthLogin = loginUserInfo.role;

  useEffect(() => {
    if (getAuthLogin.toLowerCase() === "organization") {
      setUrl("admin");
    } else if (getAuthLogin.toLowerCase() === "superadmin") {
      setUrl("super-admin");
    } else {
      setUrl("staff");
    }
  }, [getAuthLogin]);

  const handleStorageData = () => {
    setShowMenu(false);
    setFinMenu(false);

    localStorage.setItem("tabStatus", 0);
    localStorage.setItem("pageNo", 1);
  };

  const togleSubMenu = (bFlag) => {
    if (bFlag === true) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const openSubMenu = (bFlag) => {
    setShowMenu(true);
  };

  const togleFinMenu = (bFlag) => {
    if (bFlag === true) {
      setFinMenu(false);
    } else {
      setFinMenu(true);
    }
  };

  const openFinMenu = (bFlag) => {
    setFinMenu(true);
  };

  // State to manage drawer visibility

  const menuHide = () => {
    setIsDrawerOpen((prevState) => !prevState);
    props.custom_fullwindow(); // Toggle the state when clicking
  };

  const drawerClass = isDrawerOpen ? "hideSidebar" : "";

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: `${classes.drawer} ${drawerClass}` }}
    >
      <Box className={classes.menuToggle} onClick={menuHide}>
        <span className="one"></span>
        <span className="two"></span>
        <span className="three"></span>
      </Box>
      <Box className={classes.logoContainer}>
        <Link
          to={!authAdmin ? "/super-admin/dashboard" : "/admin/bookings"}
          className="logo"
        >
          <img src={logo} alt="Pluto logo" hight={100} width={100} />
        </Link>
        {/* {loginUserInfo.role === "ORGANIZATION" ? ( */}
        <p
          className="mt-3"
          style={{
            backgroundColor: "white",
            padding: "10px",
            margin: "10px",
            textAlign: "center",
            marginTop: 0,
          }}
        >
          {loginUserInfo.organization_name}!
        </p>
        {/* ) : (
          <p
            className="mt-3"
            style={{
              backgroundColor: "white",
              padding: "10px",
              margin: "10px",
              textAlign: "center",
              marginTop: 0,
            }}
          >
            {loginUserInfo?.first_name} {loginUserInfo?.last_name}!
          </p>
        )}{" "} */}
      </Box>

      <List className={props.sidebarWidth}>
        {routes
          .filter(
            (route) =>
              route.role.split(",").includes(getAuthLogin.toLowerCase()) &&
              route.sidebar !== false
          )
          .map((route, index) => {
            let childList = route.child;
            return (
              <>
                {!childList ? (
                  <NavLink
                    to={`/${url}/${route.path}`}
                    key={index}
                    className={classes.navLink}
                    activeClassName="active"
                    onClick={handleStorageData}
                  >
                    <ListItem button>
                      <ListItemIcon
                        className={`${classes.listItemIcon} activeIcon`}
                      >
                        <Icon>{route.icon}</Icon>
                      </ListItemIcon>
                      {route.name === "Compliance-document" ? (
                        <ListItemText
                          className="menuName"
                          primary="Compliance Document List"
                        />
                      ) : (
                        <ListItemText
                          className="menuName"
                          primary={route.name}
                        />
                      )}
                    </ListItem>
                  </NavLink>
                ) : (
                  <NavLink
                    to={`/${url}/${route.path}`}
                    className={classes.navLinkSubMenu}
                    key={index}
                  >
                    <ListItem button>
                      <ListItemIcon
                        className={`${classes.listItemIcon} activeIcon`}
                      >
                        <Icon>{route.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText className="menuName" primary={route.name} />{" "}
                      {route.name === "Settings" ? (
                        <Icon
                          className={`sub-menu-icon ${
                            showMenu ? "active" : ""
                          }`}
                          onClick={() => togleSubMenu(showMenu)}
                        >
                          expand_more
                        </Icon>
                      ) : (
                        <Icon
                          className={`sub-menu-icon ${finMenu ? "active" : ""}`}
                          onClick={() => togleFinMenu(finMenu)}
                        >
                          expand_more
                        </Icon>
                      )}
                    </ListItem>
                    <Box className="sidebarSubMenu">
                      {childList &&
                        route.name === "Settings" &&
                        childList.map((item, keys) => {
                          return (
                            item.sidebar !== false && (
                              <div
                                className={`sub-menu ${
                                  showMenu ? "active" : ""
                                }`}
                              >
                                <NavLink
                                  to={`/${url}/${item.path}`}
                                  className={classes.navLink}
                                  activeClassName="active"
                                  key={keys}
                                >
                                  <ListItem button>
                                    <ListItemIcon
                                      className={`${classes.listItemIcon} activeIcon`}
                                      onClick={() => openSubMenu(showMenu)}
                                    >
                                      <Icon>{item.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                  </ListItem>
                                </NavLink>
                              </div>
                            )
                          );
                        })}
                      {childList &&
                        route.name === "Finance" &&
                        childList.map((item, keys) => {
                          return (
                            item.sidebar !== false && (
                              <div
                                className={`sub-menu ${
                                  finMenu ? "active" : ""
                                }`}
                              >
                                <NavLink
                                  to={`/${url}/${item.path}`}
                                  className={classes.navLink}
                                  activeClassName="active"
                                  key={keys}
                                >
                                  <ListItem button>
                                    <ListItemIcon
                                      className={`${classes.listItemIcon} activeIcon`}
                                      onClick={() => openFinMenu(finMenu)}
                                    >
                                      <Icon>{item.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        item.name === "Invoice-Studio"
                                          ? "Invoicing Studio"
                                          : item.name
                                      }
                                    />
                                  </ListItem>
                                </NavLink>
                              </div>
                            )
                          );
                        })}
                    </Box>
                  </NavLink>
                )}
              </>
            );
          })}
      </List>
      {loginUserInfo.role === "ORGANIZATION" && (
        <Box className={classes.bottomLogoContainer}>
          <img
            className={`${classes.imgTag} csslogo`}
            src={
              loginUserInfo?.profile_pic
                ? apiConfigs.API_URL +
                  "uploads/org_logo/" +
                  loginUserInfo?.profile_pic
                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtNWVnKZZfy-1CLo75eO5vLhTWFZyeyc7QaI6GgdSalXDIJOCA6t0DSdDDMabrTOdjdYs&usqp=CAU"
            }
            alt="Organization logo"
          />
        </Box>
      )}
    </Drawer>
  );
};

export default Sidebar;
