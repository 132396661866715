import axios from "axios";
import Config from "../../../config/config";
import {
  CREATE_USER_NOTIFICATION_PREF_ERROR,
  CREATE_USER_NOTIFICATION_PREF_REQUEST,
  CREATE_USER_NOTIFICATION_PREF_SUCCESS,
  
  GET_USER_NOTIFICATION_PREF_ERROR,
  GET_USER_NOTIFICATION_PREF_REQUEST,
  GET_USER_NOTIFICATION_PREF_SUCCESS,
  
} from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";

export const getUserNotificationPrefs = (user_id,column_name,type) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getUserNotificationPrefsRequest());
    await axios
      .get(`${Config.API_URL}api/organization/get-user-notification-prefs?user_id=${user_id}&column_name=${column_name}&type=${type}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(getUserNotificationPrefsSuccess(data));
        } else {
          dispatch(getUserNotificationPrefsSuccess([]));
          dispatch(getUserNotificationPrefsError(data));
        }
      })
      .catch((error) => {
        dispatch(getUserNotificationPrefsError(error));
      });
  };
};

export const getUserNotificationPrefsRequest = () => {
  return {
    type: GET_USER_NOTIFICATION_PREF_REQUEST,
  };
};
export const getUserNotificationPrefsSuccess = (data) => {
  return {
    type: GET_USER_NOTIFICATION_PREF_SUCCESS,
    payload: data,
  };
};
export const getUserNotificationPrefsError = (error) => {
  return {
    type: GET_USER_NOTIFICATION_PREF_ERROR,
    payload: error,
  };
};

// -------------------------------------

export const activeOrDeactiveNotification = (user_id,id,status) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createUserNotificationPrefRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/active-deactive-notification/${user_id}/${id}/${status}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data && data?.status === true) {
          dispatch(createUserNotificationPrefSuccess(data));
          dispatch(notificationSuccess(data.message));
        } else {
          dispatch(createUserNotificationPrefError(data));
           dispatch(notificationFail(data.message));
        }
      })
      .catch((error) => {
        dispatch(createUserNotificationPrefError(error));
        dispatch(notificationFail(error.response.data.message));
      });
  };
};

export const createUserNotificationPrefRequest = () => {
  return {
    type: CREATE_USER_NOTIFICATION_PREF_REQUEST,
  };
};
export const createUserNotificationPrefSuccess = (data) => {
  return {
    type: CREATE_USER_NOTIFICATION_PREF_SUCCESS,
    payload: data,
  };
};
export const createUserNotificationPrefError = (error) => {
  return {
    type: CREATE_USER_NOTIFICATION_PREF_ERROR,
    payload: error,
  };
};

