import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import history from "../../utils/HistoryUtils";
import AlertDialog from "../../components/Alert/AlertDialog";
import Notification from "../../components/Notification/Notification";
import { deleteTrust } from "../../store/action";
import { getSingleTrustById } from "../../store/action/trust/trustAction";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  desc: {
    fontSize: 16,
  },
  descBreak: {
    fontSize: 16,
    wordBreak: "break-word",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },

  mainWrapper: {
    paddingBottom: "0 !important",
    paddingTop: "24px !important",
  },

  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },

  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },
  hospitalBox: {
    background: "#f8fafc",
    margin: "30px 0",
    borderRadius: 8,
    width: "100%",
  },
}));

const DetailTrust = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const id = match.params.id;
  const [Id, setId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trustItems, setTrustItems] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);
  const { deleteTrustSuccess, deleteTrustError } = useSelector(
    (state) => state.trust
  );
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const loginUserInfo = JSON.parse(window.localStorage.getItem("loginUserInfo"));

  const getSingleTrust = async () => {
    setLoading(true);
    dispatch(
      getSingleTrustById(id, (result) => {
        if (result?.data) {
          setTrustItems(result?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  const upadateLink = () => {
    history.push(`update`);
  };
  const backPage = () => {
    history.go(-2);
  };

  const deleteTrustClose = () => {
    setDeleteOpen(false);
  };

  const alertResponse = (id) => {
    dispatch(deleteTrust(id));
    setStaffNotify(true);
  };

  const deleteStaffItem = (id) => {
    setDeleteOpen(true);
    setId(id);
  };

  useEffect(() => {
    if (id) {
      getSingleTrust();
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {staffNotify && deleteTrustSuccess?.message && (
        <Notification data={deleteTrustSuccess?.message} status="success" />
      )}{" "}
      {staffNotify && deleteTrustError?.message && (
        <Notification data={deleteTrustError?.message} status="error" />
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.mainTitle}>
              Customer Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Customers Name
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Customer Code:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Framework:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.framework}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              VAT:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.vat}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              LLP Status:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.llp_status}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              AWR:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.awr}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              LPP Trust Code:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.llp_trust_code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Cost Center Code:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.cost_center_code}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Cash Connect No:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.cas_connect_no}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Contract:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.contract}
            </Typography>
          </Grid>
        </Grid>

        {trustItems?.hospital &&
          trustItems?.hospital.map((items, index) => {
            return (
              <Grid
                container
                spacing={4}
                key={index}
                className={classes.hospitalBox}
              >
                <Grid item xs={12} className="mb-4">
                  <Typography className={classes.mainTitle}>
                    {`${index + 1}.`} Site
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Typography variant="body2" className={classes.heading}>
                    Site Name
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    {items.hospital_name}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Typography variant="body2" className={classes.heading}>
                    Client Type
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    {items.client_type}
                  </Typography>
                </Grid>
               
                <Grid item xs={3} className={classes.gridItem}>
                  <Typography variant="body2" className={classes.heading}>
                    Charge Area
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    {items.charge_area}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Typography variant="body2" className={classes.heading}>
                    Client Address
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    {items.client_address}
                  </Typography>
                </Grid>
                {items?.ward &&
                  items?.ward.map((warditem, wIndex) => {
                    return (
                      <Fragment key={wIndex}>
                        <Grid item xs={12} className={classes.mainWrapper}>
                          <Typography className={classes.subTitle}>
                            {`${wIndex + 1}.`} Wards
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          className={classes.gridItem}
                        >
                          <Typography
                            variant="body2"
                            className={classes.heading}
                          >
                            Ward Name
                          </Typography>
                          <Typography variant="h6" className={classes.desc}>
                            {warditem.ward_name}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          className={classes.gridItem}
                        >
                          <Typography
                            variant="body2"
                            className={classes.heading}
                          >
                            Type
                          </Typography>
                          <Typography variant="h6" className={classes.desc}>
                            {warditem.ward_type}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          className={classes.gridItem}
                        >
                          <Typography
                            variant="body2"
                            className={classes.heading}
                          >
                            Phone Number
                          </Typography>
                          <Typography variant="h6" className={classes.desc}>
                            {warditem.phone_number}
                          </Typography>
                        </Grid>
                      </Fragment>
                    );
                  })}
              </Grid>
            );
          })}
        <Grid container spacing={4}>
          <Grid item xs={6} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Preferred Invoice Method
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.preference_invoice_method}
            </Typography>
          </Grid>
          

          <Grid item xs={6} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Email Address
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.email_address}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={7} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Invoicing Address
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.address_line_1}, {trustItems.address_line_2}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              City
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.city}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Postal Code
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.post_code}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.mainWrapper}>
            <Typography className={classes.mainTitle}>
              Portal Login Details
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Customer Portal URL
            </Typography>
            <Typography variant="h6" className={classes.descBreak}>
              {trustItems.trust_portal_url}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Email Address
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.portal_email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Password
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {trustItems.portal_password}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.mainWrapper}>
            <Typography className={classes.mainTitle}>Notes</Typography>
          </Grid>

          {trustItems?.training &&
            trustItems?.training.map((items, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.gridItem}
                  key={index}
                >
                  <Typography variant="body2" className={classes.heading}>
                    {++index}. Notes{" "}
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    {items.training_name}
                  </Typography>
                </Grid>
              );
            })}
          <Grid item xs={12} className={classes.mainWrapper}>
            <Typography
              className={classes.mainTitle}
              style={{ marginBottom: "16px" }}
            >
              Contact Information
            </Typography>
          </Grid>
          {trustItems?.contact &&
            trustItems?.contact.map((item, index) => (
              <>
                <Typography
                  style={{ marginLeft: "18px" }}
                  variant="body2"
                  className={classes.heading}
                >
                  {++index}. Contact Information
                </Typography>
                <Grid
                  container
                  spacing={4}
                  key={index}
                  style={{ marginTop: "0", marginLeft: "18px" }}
                  className={classes.hospitalBox}
                >
                  <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                    <Typography variant="body2" className={classes.heading}>
                      First Name
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {item.first_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                    <Typography variant="body2" className={classes.heading}>
                      Last Name
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {item.last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                    <Typography variant="body2" className={classes.heading}>
                      Email Address
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {item.contact_email_address}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                    <Typography variant="body2" className={classes.heading}>
                      Phone Number
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {item.phone_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                    <Typography variant="body2" className={classes.heading}>
                      Client
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {item.client}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                    <Typography variant="body2" className={classes.heading}>
                      Department
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {item.department}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.btnContainer}
            >
              <Button color="primary" onClick={backPage}>
                Back
              </Button>
              {staffDetail !== "Booking" && staffDetail !== "Finance" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={upadateLink}
                  >
                    <EditIcon className="mr-2" />
                    Edit
                  </Button>
                  
                </>
              )}
              {(staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION")  && (
                <>
                   <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => deleteStaffItem(trustItems.id)}
                  >
                    <DeleteIcon className="mr-2" />
                    Delete
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <AlertDialog
        id={Id}
        open={deleteOpen}
        close={deleteTrustClose}
        response={alertResponse}
        title="Delete Customers"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />
    </>
  );
};

export default DetailTrust;
