import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  alpha,
  InputBase,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Backdrop,
  CircularProgress,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentScheme } from "../../store/action";
import history from "../../utils/HistoryUtils";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import apiConfigs from "../../config/config";
import axios from "axios";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },
  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

const ViewPaymentScheme = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [searchData, setSearchData] = useState({ search: "" });
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );
  const { getPaymentSchemeItem, loading } = useSelector(
    (state) => state.paymentScheme
  );

  const getList = (
    pageNo = 1,
    search = "",
    type = order,
    column_name = orderBy
  ) => {
    dispatch(getPaymentScheme({ pageNo, search, type, column_name }));
  };

  const onhandlClick = (id) => {
    history.push(`${match.url}/${id}/detail`);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(getList(value), 2000);
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1.length > 2) {
      setTimeout(getList(page, d1), 1000);
    } else {
      setTimeout(getList(page, ""), 1000);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setTimeout(getList(page, searchData.search), 1000);
  };

  const dublicatePaymentScheme = async (booking_id) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/dublicate-paymentscheme/${booking_id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          setTimeout(() => {
            getList();
          }, 100);
        }
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (
      (loginUserInfo?.role == "STAFF" &&
        loginUserInfo?.staffdetails == "Admin") ||
      loginUserInfo?.role == "ORGANIZATION" ||
      loginUserInfo?.is_consulatant == "Yes"
    ) {
      getList();
    }
  }, [orderBy, order]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {((loginUserInfo?.role == "STAFF" &&
        loginUserInfo?.staffdetails == "Admin") ||
        loginUserInfo?.role == "ORGANIZATION" ||
        loginUserInfo?.is_consulatant == "Yes") && (
        <Paper className={`${classes.root} mb-6`}>
          <Box className="mb-5" display="flex" alignItems="center">
            <SearchIcon
              className={classes.searchIcondet}
              onClick={handleClickSearch}
            />
            <div className={classes.search}>
              <InputBase
                name="search"
                placeholder="Search…"
                onChange={handleSearchChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>

            {(loginUserInfo?.role === "ORGANIZATION" ||
              staffDetail === "Admin" ||
              (getPaymentSchemeItem?.data?.isConsultant === "No" &&
                staffDetail !== "Booking" &&
                staffDetail !== "Finance")) && (
              <div className="ml-5">
                <Link to={`${match.url}/create`} className="btn btn-secondary">
                  <AddIcon className="mr-2" />
                  Add Rate
                </Link>
              </div>
            )}
          </Box>

          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, property) => {
                  handleRequestSort(
                    property,
                    setOrder,
                    setOrderBy,
                    orderBy,
                    order
                  );
                }}
                rowCount={getPaymentSchemeItem?.data?.data?.length}
                headCells={headCells[0]}
              />

              <TableBody>
                {getPaymentSchemeItem?.data?.data &&
                  getPaymentSchemeItem?.data?.data.map((list, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">{index + 1}</TableCell>

                        <TableCell align="left">{list.name}</TableCell>
                        <TableCell align="left">
                          {list.speciality_name}
                        </TableCell>
                        <TableCell align="left">{list.grade_name}</TableCell>
                        <TableCell align="left">{list.day_from}</TableCell>
                        <TableCell align="left">{list.day_to}</TableCell>
                        <TableCell align="left">{list.remark}</TableCell>
                        {(loginUserInfo?.role === "ORGANIZATION" ||
                          staffDetail === "Admin" ||
                          (getPaymentSchemeItem?.data?.isConsultant === "No" &&
                            staffDetail !== "Booking" &&
                            staffDetail !== "Finance")) && (
                          <TableCell align="right">
                            <Link
                              to="#"
                              onClick={(e) => dublicatePaymentScheme(list.id)}
                            >
                              <FileCopyOutlinedIcon />
                            </Link>
                          </TableCell>
                        )}
                        <TableCell align="right">
                          <Link
                            to="#"
                            className="btn btn-secondary"
                            onClick={(e) => onhandlClick(list.id)}
                          >
                            View
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {getPaymentSchemeItem?.data?.data?.length == 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan="6" scope="">
                      {" "}
                      Sorry no record available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="mt-5" display="flex" justifyContent="flex-end">
            <Pagination
              onChange={handleChangePage}
              page={page}
              count={getPaymentSchemeItem?.data?.last_page}
              showFirstButton
              showLastButton
            />
          </Box>
        </Paper>
      )}
    </>
  );
};
export default ViewPaymentScheme;
