import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Badge,
  Box,
  IconButton,
  Menu,
  Card,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import history from "../../utils/HistoryUtils";
import axios from "axios";
import Config from "../../../src/config/config";
import LoadingComponent from "../Loading/Loading";
import Notification from "../Notification/Notification";
import { notificationClear } from "../../store/action/notificationMsg";
import { getNotification, readNotification } from "../../store/action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "transparent",
    boxShadow: "none",
    color: "#000",
    position: "sticky",
    top: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textTransform: "capitalize",
    fontSize: "24px",
  },
  menuBox: {
    "& .MuiMenu-list": {
      padding: 0,
      minWidth: 175,
      minHeight: "28px",
    },
    "& .MuiListItem-root": {
      borderBottom: "1px solid #dcdcdc",
      // width: 300,
      minWidth: 190,
      maxWidth: 350,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    "& .active": {
      backgroundColor: "#f78b46",
      color: "#fff",
      pointerEvent: "none",
      cursor: "default",
    },
  },
  menuHeading: {
    fontSize: 15,
  },
  menuDesc: {
    fontSize: 13,
    whiteSpace: "normal",
    color: "rgba(0, 0, 0, 0.7)",
    "&.isRead": {
      fontWeight: "500",
      color: "#000",
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationShow, setNotificationShow] = React.useState(null);
  const { status } = useSelector((state) => state.loadingReducer);
  const { notificationList } = useSelector((state) => state.notificationList);
  const loadingStatus = status ? true : false;
  var notificationInfo = useSelector((state) => state.notificationMsg);
  const location = useLocation();
  const open = Boolean(anchorEl);
  const open1 = Boolean(notificationShow);
  const loggedUser = localStorage.getItem("role").replace(/['"]+/g, "");
  let unReadNotification;

  const titleName = window.location.pathname.split("/").pop();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuNotification = (event) => {
    setNotificationShow(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationShow(null);
  };

  const openProfile = () => {
    // const loggedUser = localStorage.getItem("role").replace(/['"]+/g, '');
    if (loggedUser === "ORGANIZATION") {
      history.push("/admin/organization-profile");
    } else if (loggedUser === "SUPERADMIN") {
      history.push("/super-admin/profile");
    } else {
      history.push("/staff/staff-profile");
    }
    handleClose();
  };

  const logout = () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .get(`${Config.API_URL}api/organization/logout`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        localStorage.clear();
        history.push("/login");
      })
      .catch((error) => {
        localStorage.clear();
        history.push("/login");
      });
  };

  const clearNotificationMsg = () => {
    let reqParam = { message: null, status: null, type: null };
    setTimeout(() => {
      dispatch(notificationClear(reqParam));
    }, 4000);
  };

  useEffect(() => {
    dispatch(getNotification(1));
  }, []);

  if(loggedUser == "ORGANIZATION") {
  unReadNotification =
    notificationList?.data &&
    notificationList?.data.filter((val) => val.is_read == 0).length;
  }else{
  unReadNotification =
    notificationList?.data &&
    notificationList?.data.filter((val) => val.is_staff_read == 0).length;
  }

  const readNotification1 = (e, val) => {
    e.preventDefault();
    const requestData = {
      notification_id: val.id,
      is_read: true,
      signee_id: val.signee_id,
    };
    dispatch(readNotification(requestData));
  };
  const ReadAllNotification = () => {
    const requestData = {
      notification_id: "All",
      is_read: true,
    };
    dispatch(readNotification(requestData));
  };
  const loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
  return (
    <>
      {/* common notification */}
      {notificationInfo?.message && (
        <>
          <Notification
            data={notificationInfo?.message}
            status={notificationInfo?.status ? "success" : "error"}
          />
          {clearNotificationMsg()}
        </>
      )}
      {/* common notification */}

      {/* loading */}
      {loadingStatus && <LoadingComponent status={loadingStatus} />}
      {/* loading */}

      <AppBar position="static" className={classes.root}>
        <Box style={{ padding: "0px 24px" }}>
          <Card
            style={{
              marginTop: 12,
              marginBottom: 20,
              padding: "0 20px",
              display: "flex",
              justifyContent: "space-between",
              color: "#02294F",
              alignItems: "center",
            }}
          >
            {loginUserInfo.role === "ORGANIZATION" ? (
              titleName === "compliance-document" ? (
                <h1 className={classes.title}>Compliance Document list</h1>
              ) : titleName === "umbrella-company" ? (
                <h1 className={classes.title}>Umbrella Companies</h1>
              ) : titleName === "compliance-category" ? (
                <h1 className={classes.title}>Compliance Category</h1>
              ) : titleName === "payment-scheme" ? (
                <h1 className={classes.title}>Payment Schemes</h1>
              ) : titleName === "systemlogs" ? (
                <h1 className={classes.title}>System Logs</h1>
              ) : titleName === "invoice-studio" ? (
                <h1 className={classes.title}>Invoicing Studio</h1>
              ) : titleName === "detail" ? (
                <h1 className={classes.title}>Details</h1>
              ) : (
                <h1 className={classes.title}>
                  {titleName !== "invoice-studio" &&
                    titleName !== "detail" &&
                    titleName !== "changepassword" &&
                    titleName}
                </h1>
              )
            ) : titleName === "staff-profile" ? (
              <h1 className={classes.title}>Staff Profile</h1>
            ) : titleName === "payment-scheme" ? (
              <h1 className={classes.title}>Payment Scheme</h1>
            ) : titleName === "candidate" ? (
              <h1 className={classes.title}>Candidates</h1>
            ) : (
              <h1 className={classes.title}>{titleName}</h1>
            )}

            <Box display="flex" justifyContent="flex-end">
              <IconButton color="inherit" onClick={handleMenuNotification}>
                <Badge badgeContent={unReadNotification} color="secondary">
                  <NotificationsIcon color="primary" />
                </Badge>
              </IconButton>

              <Menu
                anchorEl={notificationShow}
                open={open1}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                className={classes.menuBox}
              >
                {notificationList?.data && notificationList?.data.length > 0 ? (
                  notificationList?.data.map((val, index) => {
                    if (index < 5) {
                      return (
                        <MenuItem
                          onClick={(e) => readNotification1(e, val)}
                          
                          style={{
                            background:
                            (loggedUser === "ORGANIZATION" ? val.is_read : val.is_staff_read) == 0
                              ? "#e7f2ff"
                              : "white",
                          }}
                          key={index}
                        >
                          <div>
                              <Typography
                                variant="body2"
                                className={`${classes.menuDesc} ${
                                  (loggedUser === "ORGANIZATION" ? val.is_read : val.is_staff_read) === 0
                                    ? "isRead"
                                    : ""
                                }`}
                              >
                                {val.message}{" "}
                              </Typography>
                            </div>
                        </MenuItem>
                      );
                    }
                  })
                ) : (
                  <MenuItem>
                    <div>
                      <Typography variant="h6" className={classes.menuHeading}>
                        No Data Found{" "}
                      </Typography>
                    </div>
                  </MenuItem>
                )}
                {notificationList?.data && notificationList?.data.length > 0 ? (
                  <MenuItem>
                    <>
                      <Link to="/admin/notification">
                        <Typography variant="caption">
                          Show all notification
                        </Typography>
                      </Link>
                      <span
                        style={{ marginLeft: "auto" }}
                        onClick={ReadAllNotification}
                      >
                        <Typography variant="caption">Read all</Typography>
                      </span>
                    </>
                  </MenuItem>
                ) : (
                  ""
                )}
              </Menu>

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon color="primary" />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={openProfile}>Profile</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Card>
        </Box>
      </AppBar>
    </>
  );
};

export default Navbar;
