import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import {
  CREATE_NOTIFICATION_PREF_ERROR,
  CREATE_NOTIFICATION_PREF_REQUEST,
  CREATE_NOTIFICATION_PREF_SUCCESS,
  
  DELETE_NOTIFICATION_PREF_ERROR,
  DELETE_NOTIFICATION_PREF_REQUEST,
  DELETE_NOTIFICATION_PREF_SUCCESS,
  
  GET_NOTIFICATION_PREF_ERROR,
  GET_NOTIFICATION_PREF_REQUEST,
  GET_NOTIFICATION_PREF_SUCCESS,
  
  UPDATE_NOTIFICATION_PREF_REQUEST,
  UPDATE_NOTIFICATION_PREF_SUCCESS,
  UPDATE_NOTIFICATION_PREF_ERROR,
  GET_NOTIFICATION_PREF_BY_ID_REQUEST,
 
} from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";

export const getNotificationPrefs = (column_name="",type="") => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getNotificationPrefsRequest());
    await axios
      .get(`${Config.API_URL}api/organization/get-notification-prefs?column_name=${column_name}&type=${type}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(getNotificationPrefsSuccess(data));
        } else {
          dispatch(getNotificationPrefsSuccess([]));
          dispatch(getNotificationPrefsError(data));
        }
      })
      .catch((error) => {
        dispatch(getNotificationPrefsError(error));
      });
  };
};

export const getNotificationPrefsRequest = () => {
  return {
    type: GET_NOTIFICATION_PREF_REQUEST,
  };
};
export const getNotificationPrefsSuccess = (data) => {
  return {
    type: GET_NOTIFICATION_PREF_SUCCESS,
    payload: data,
  };
};
export const getNotificationPrefsError = (error) => {
  return {
    type: GET_NOTIFICATION_PREF_ERROR,
    payload: error,
  };
};

// --------------------------------
export const getNotificationPrefsById = (id, result) => {
  return async (dispatch) => {
    dispatch(getNotificationPrefsByIdRequest());
    await apiClient(true)
      .get(`api/organization/get-notification-pref/${id}`)
      .then((response) => {
        result(response.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

export const getNotificationPrefsByIdRequest = () => {
  return {
    type: GET_NOTIFICATION_PREF_BY_ID_REQUEST,
  };
};
// --------------------------------


export const addNotificationPreference = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createNotificationPrefsRequest());
    await axios
      .post(`${Config.API_URL}api/organization/add-notification-prefs`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(createNotificationPrefsSuccess(data));
          setTimeout(() => {
            dispatch(getNotificationPrefs());
          }, 2000);
        } else {
          dispatch(createNotificationPrefsError(data));
        }
      })
      .catch((error) => {
        dispatch(createNotificationPrefsError(error));
      });
  };
};

export const createNotificationPrefsRequest = () => {
  return {
    type: CREATE_NOTIFICATION_PREF_REQUEST,
  };
};
export const createNotificationPrefsSuccess = (data) => {
  return {
    type: CREATE_NOTIFICATION_PREF_SUCCESS,
    payload: data,
  };
};
export const createNotificationPrefsError = (error) => {
  return {
    type: CREATE_NOTIFICATION_PREF_ERROR,
    payload: error,
  };
};

// -------------------------------------

export const deleteNotificationPrefs = (pref_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteNotificationPrefsRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-notification-prefs/${pref_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(deleteNotificationPrefsSuccess(data));
          dispatch(notificationSuccess(data.message));
          setTimeout(() => {
            dispatch(getNotificationPrefs());
          }, 2000);
        } else {
          dispatch(deleteNotificationPrefsError(data.message));
          dispatch(notificationFail(data.message));
        }
      })
      .catch((error) => {
        dispatch(deleteNotificationPrefsError(error.response.data));
        dispatch(notificationFail(error.response.data));
      });
  };
};

export const deleteNotificationPrefsRequest = () => {
  return {
    type: DELETE_NOTIFICATION_PREF_REQUEST,
  };
};
export const deleteNotificationPrefsSuccess = (data) => {
  return {
    type: DELETE_NOTIFICATION_PREF_SUCCESS,
    payload: data,
  };
};
export const deleteNotificationPrefsError = (error) => {
  return {
    type: DELETE_NOTIFICATION_PREF_ERROR,
    payload: error,
  };
};

// --------------------------------
export const updateNotificationPrefs = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateNotificationPrefsRequest());
    await axios
      .post(`${Config.API_URL}api/organization/edit-notification-prefs`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(updateNotificationPrefsSuccess(data));
          setTimeout(() => {
            dispatch(getNotificationPrefs());
          }, 2000);
        } else {
          dispatch(updateNotificationPrefsError(data));
        }
      })
      .catch((error) => {
        dispatch(updateNotificationPrefsError(error));
      });
  };
};


export const updateNotificationPrefsRequest = () => {
  return {
    type: UPDATE_NOTIFICATION_PREF_REQUEST,
  };
};

export const updateNotificationPrefsSuccess = (data) => {
  return {
    type: UPDATE_NOTIFICATION_PREF_SUCCESS,
    payload: data,
  };
};
export const updateNotificationPrefsError = (error) => {
  return {
    type: UPDATE_NOTIFICATION_PREF_ERROR,
    payload: error,
  };
};
