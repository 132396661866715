import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import history from "../../utils/HistoryUtils";
import axios from "axios";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../components/Notification/Notification";
import AsigneeStaffEvent from "./AsigneeStaffEvent";
import AlertDialog from "../../components/Alert/AlertDialog";
import { activeOrDeactiveStaff } from "../../store/action";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  desc: {
    fontSize: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },
  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },
}));

const DetailStaff = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const user_id = match.params.id;
  const [Id, setId] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);
  const { staffDeleteSuccess, staffDeleteError } = useSelector(
    (state) => state.staff
  );
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const loginUserInfo = JSON.parse(window.localStorage.getItem("loginUserInfo"));
  const [activeOpen, setActiveOpen] = useState(false);
  const [deActiveOpen, setDeActiveOpen] = useState(false);  
  const [openAsigneeStaff, setOpenAsigneeStaff] = useState(false);
  const roleName = localStorage.getItem('role').replace(/['"]+/g, "");
  const upadateLink = () => {
    history.push(`update`);
  };
  const changepasswordLink=()=>{
    history.push(`changepassword`);
  }

  const getSingleStaff = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    setLoading(true);
    await axios
      .get(`${apiConfigs.API_URL}api/organization/user/get-user/${user_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setItems(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error.message", error.message);
        setLoading(false);
      });
  };

  const deleteStaffItem = (id) => {
    handleClickOpenAsigneeStaff();
    setId(id);
  };

   const handleClickOpenAsigneeStaff = () => {
    setOpenAsigneeStaff(true);
  }

   const handleClose = () => {
    setOpenAsigneeStaff(false)
  }


  const backPage = () => {
    history.go(-2);
  };

  const aActiveClose = () => {
    setActiveOpen(false);
  };
  const dActiveClose = () => {
    setDeActiveOpen(false);
  };
  const alertActiveResponse = (id) => {
    dispatch(activeOrDeactiveStaff(id,1));
    setStaffNotify(true);
  };
   const alertDeActiveResponse = (id) => {
    dispatch(activeOrDeactiveStaff(id,0));
    setStaffNotify(true);
  };

  const onHandlActive = () => {
    setActiveOpen(true);
  };

  const onHandlDeActive = () => {
    setDeActiveOpen(true);
  };


  useEffect(() => {
    getSingleStaff();
  }, [user_id]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {staffNotify && staffDeleteSuccess?.message && (
        <Notification
          data={
            staffDeleteSuccess?.message
              ? "Staff member deleted successfully"
              : ""
          }
          status="success"
        />
      )}

      {staffNotify && staffDeleteError?.message && (
        <Notification data={staffDeleteError?.message} status="error" />
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              First Name
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.first_name}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Last Name
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.last_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Email Address
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Contact Number
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.contact_number}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Select Role:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.role_name ? items.role_name : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Select Designation
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {items.designation_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.btnContainer}
            >
              <Button color="primary" onClick={backPage}>
                Back
              </Button>
              { roleName === 'ORGANIZATION' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={changepasswordLink}
                  >
                    <EditIcon className="mr-2" />
                    Change Password
                  </Button>
              )}
              
              {staffDetail !== "Booking" && staffDetail !== "Finance" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={upadateLink}
                  >
                    <EditIcon className="mr-2" />
                    Edit
                  </Button>
                 
                </>
              )}
              {(staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION")  && (
                    <>
                   <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => deleteStaffItem(items.id)}
                  >
                    <DeleteIcon className="mr-2" />
                    Delete
                  </Button>
                  </>
                )}
                
                 {((staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION" || loginUserInfo?.is_smt === "Yes") && (items.deleted === "1")) && (
                    <>
                   <Button
                    variant="contained"
                    color="secondary"
                     onClick={() => onHandlDeActive(items.id)}
                  >
                    Deactivate
                  </Button>
                  </>
                )}
                {((staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION" || loginUserInfo?.is_smt === "Yes") && (items.deleted === "0")) && (
                  <>
                   <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onHandlActive(items.id)}
                  >
                    Activate
                  </Button>
                  </> 
                )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    <AsigneeStaffEvent
      open={openAsigneeStaff}
      handleClose={handleClose}
      asigneeId = {items.id}
      asigneeName = {items.first_name +' '+items.last_name }
    />
      <AlertDialog
        id={items.id}
        open={activeOpen}
        close={aActiveClose}
        response={alertActiveResponse}
        title="Active Staff Member"
        description="Are you sure you want to active this user?"
        buttonName="Active"
      />

       <AlertDialog
        id={items.id}
        open={deActiveOpen}
        close={dActiveClose}
        response={alertDeActiveResponse}
        title="Deactive Staff Member"
        description="Are you sure you want to deactive this user?"
        buttonName="Deactive"
      />
    </>
  );
};

export default DetailStaff;
