export const headCells = [
  //for zero(0) index for Report
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'name',
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'candidate',
    label: 'Candidate',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'consultant_name',
    label: 'Consultant',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'speciality_name',
    label: 'Specialty',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'framework',
    label: 'framework',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'hospital_name',
    label: 'Site Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'ward_name',
    label: 'Ward Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'grade_name',
    label: 'Grade',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Invoice Date',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'start_time',
    label: 'Shift Time',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },],

  
]
