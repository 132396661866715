import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { updateBooking } from "../../store/action";
import history from "../../utils/HistoryUtils";
import Notification from "../../components/Notification/Notification";
import {
  getBookingSpeciality,
  getBookingTrust,
  getHospitalList,
  getWardByHospital,
} from "../../store/action/booking/bookingAction";
import { getStaffDetail } from "../../store/action/staff/staffAction";
import CandidatesList from "./CandidatesList";
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import startOfDay from "date-fns/startOfDay/index.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { CloseOutlined } from "@material-ui/icons";
import { useForm } from "react-hook-form";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  formControl: {
    width: "100%",
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    background: "rgba(0, 0, 0, 0.01)",
  },
}));

const UpdateBooking = ({ match }) => {
  const {
    formState: { errors },
    setValue
  } = useForm();
  const classes = useStyle();
  const booking_id = match.params.id;
  const dispatch = useDispatch();
  const [speciality, setSpeciality] = useState([]);
  const [trust, setTrust] = useState([]);
  const [getTrustId, setgetTrustId] = useState();
  const [getHospitalId, setGetHospitalId] = useState();
  const [hospitalList, setHospitalList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [specialityId, setSpecialityId] = useState([]);
  const [shiftTypeList, setShiftTypeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [shiftTime, setShiftTime] = useState([]);
  const [trustNotify, setTrustNotify] = useState(false);
  const [hours, setHours] = useState([]);
  const [gradeId, setGradeId] = useState([]);
  const [bookingDetail, setBookingDetail] = useState([]);
   const [initialData,setInitialData]=useState([])
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [timeError,setTimeError] = useState(false)
  const [timeErrorEnd,setTimeErrorEnd] = useState(false)
  const [isStartTime,setIsStartTime] = useState(false)
  const [isEndTime,setIsEndTime] = useState(false)

  const [searchingVal, setSearchingVal] = useState("");
  const { updateBookingSuccess, updateBookingError, loading } = useSelector(
    (state) => state.booking
  );
  const [staff, setStaff] = useState([]);
  const [rateNotify, setRateNotify] = useState(false);
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );
  const [breakDateErr, setBreakDateErr] = useState({
    date: false,
    break: false,
    grade:true
  });
  const [candidateAssignDetails,setCandidateAssignDetails]  = useState({})

  const [data, setData] = useState({
    reference_id: "",
    trust_id: "",
    ward_id: "",
    grade_id: "",
    date: "",
    shift_id: "",
    payable: "",
    start_time: "",
    end_time: "",
    shift_type_id: "",
    hospital_id: "",
    chargeable: "",
    speciality: "",
    booking_reference: "",
    booking_type: "",
    staff_id: "",
    break: "",
    total_hours: 2,
    awr_paye: "",
    non_awr_paye_chargeble: "",
    awr_umbrella_chargeble: "",
    non_awr_umbrella_chargeble: "",
    non_awr_paye_payable: "",
    awr_umbrella_payable: "",
    non_awr_umbrella_payable: "",
    total_holiday_hour: "",
    total_saturday_hour: "",
    total_day_hour: "",
    total_night_hour: "",
    purchase_order: "",
    payment_scheme_type: "",
  });
  const [startTime, setStartTime] = useState(
    dayjs(`2014-08-18T${data?.start_time}`)
  );
  const  [endTime,setEndTime] = useState(dayjs(`2014-08-18T${data?.start_time}`));
  const [isNonAwr, setIsNonAwr] = useState(false);

  const handleChangeStartTime = (newValue) => {
    // let startTime = dayjs('2014-08-18T'+data?.start_time)
    
    // const sHours = newValue?.$H; // Accessing the hours property
    // const sMinutes = newValue?.$m; // Accessing the minutes property

      // const startTime = `${sHours}:${sMinutes}`;
      
      // setStartTime(newValue) 
      // data.start_time = startTime;
      // setData({ ...data, start_time: timeFormate(newValue) });
      // getCharge();
      // getSearchBookingDetail();
      // setTimeError(false) 
  

  };

  const handleChangeEndTime = (newValue) => {
    // const eHours = newValue?.$H; 
    // const eMinutes = newValue?.$m;
    // const endTime = `${eHours}:${eMinutes}`
    // setEndTime(endTime)
    // setData({ ...data, end_time: timeFormate(endTime) });
    // getCharge();
    // getSearchBookingDetail();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const intValue = parseInt(value) || "";
    if (intValue >=0 && intValue <= 120) {
      handleChange(event);
      setValue(name, intValue, { shouldValidate: true });
    }
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    if (event.target.name === "date") {
      data.date = event.target.value;
      setIsNonAwr(true);
      getCharge();
    }
   
    if (event.target.name === "grade_id") {
      data.grade_id = event.target.value;
      setGradeId(event.target.value);
      setIsNonAwr(true);
      getSearchBookingDetail();
    }
    if (event.target.name === "break") {

      data.break = event.target.value;
      data.start_time = timeFormate(data.start_time);
      data.end_time = timeFormate(data.end_time);
      setIsNonAwr(true);
      getCharge();
    }
    if (event.target.name === "booking_type") {
      data.booking_type = event.target.value;
      getCharge();
      setIsNonAwr(true);
      getSearchBookingDetail();
    }
    if (event.target.name === "speciality") {
      setGradeId("");
      setSpecialityId(event.target.value);
      setIsNonAwr(true);
       getSearchBookingDetail()
    }
    if (event.target.name === "trust_id") {
      setGradeId("");
      setSpecialityId("");
      setIsNonAwr(true);
       getSearchBookingDetail()
    }
  };

  // const handleChangeCheck = (event) => {
  //   setSpecialityId(event.target.value);
  //   setData({ ...data, [event.target.name]: event.target.value ,break: '',grade_id:''});
    
  //   setBreakDateErr({
  //     date:true,
  //     break:true
  //   })
    
  // };
  const handleChangeCheck = (event) => {
    const { name, value } = event.target;
    setSpecialityId(value);
    setData(prevData => ({
      ...prevData,
      [name]: value,
      break: '',
      grade_id: ''
    }));
    setIsNonAwr(true);
    setGradeId('')

  };
  

  const getSpecialities = async () => {
    if (getTrustId !== undefined) {
      dispatch(
        getBookingSpeciality(getTrustId, (result) => {
          if (result?.status === true) {
            setSpeciality(result);
            getSearchBookingDetail();
          }
        })
      );
    }
  };

  useEffect(() => {
    if (getTrustId !== undefined) {
      getSpecialities();
    }
  }, [getTrustId]);

 useEffect(()=>{
    if(searchingVal.length >= 0){
       getSearchBookingDetail();
  }
  },[searchingVal])

 useEffect(() =>{
    if(data?.start_time){
      setStartTime(dayjs(`2014-08-18T${data?.start_time}`));
    }
    if(data?.end_time){
      setEndTime(dayjs(`2014-08-18T${data?.end_time}`))
    }
  },[data?.start_time,data?.end_time])

  useEffect(() =>{
    if(endTime){
      getCharge();
      getSearchBookingDetail();
    }
    if(startTime){
      getCharge();
      getSearchBookingDetail();
    }
  },[isEndTime,isStartTime])

  const getTrust = async () => {
    dispatch(
      getBookingTrust((result) => {
        if (result?.status === true) {
          setTrust(result);
        }
      })
    );
  };
  const trustHandleChange = (event) => {
    data.hospital_id = "";
    data.ward_id = "";
    setgetTrustId(event.target.value);
    setData({ ...data, [event.target.name]: event.target.value });
    data.trust_id = event.target.value;
    setIsNonAwr(true);
    getCharge();
     getSearchBookingDetail();
  };
  useEffect(() => {
    getTrust();
    getStaff();
  }, []);
  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
          if (loginUserInfo?.role !== "ORGANIZATION") {
            data.staff_id = loginUserInfo.id;
          }
        }
      })
    );
  };

  const gethospital = async () => {
    if (getTrustId) {
      dispatch(
        getHospitalList(getTrustId, (result) => {
          if (result?.status === true) {
            setHospitalList(result);
          }
        })
      );
    }
  };
  const hospitalHandleChange = (event) => {
    data.ward_id = "";
    setGetHospitalId(event.target.value);
    setIsNonAwr(true);
    setData({ ...data, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    gethospital();
  }, [getTrustId]);

  const getWardType = async () => {
    dispatch(
      getWardByHospital(getHospitalId, getTrustId, (result) => {
        if (result?.status === true) {
          setWardList(result);
          getSearchBookingDetail();
        }
      })
    );
  };

  useEffect(() => {
    getWardType();
  }, [getHospitalId]);

  const getShiftType = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-all-shift-type`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setShiftTypeList(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };
  useEffect(() => {
    getShiftType();
  }, []);
  
  const getGradeList = async () => {
    if (getTrustId !== undefined) {
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      await axios
        .get(
          `${apiConfigs.API_URL}api/organization/gradelist?trustId=${getTrustId}&specialityId=${specialityId}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${loggedInUser}`,
            },
          }
        )
        .then((response) => {
          setGradeList(response.data);
          getSearchBookingDetail();
        })
        .catch((error) => {
          console.error("error.message", error.message);
        });
    }
  };
  useEffect(() => {
    if (getTrustId != undefined && specialityId != undefined) {
      getGradeList();
    }
  }, [getTrustId, specialityId]);

  const getShift = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-shifts`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setShiftTime(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };
  useEffect(() => {
    getShift();
  }, []);
  
  const getBookingDetail = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-booking/${booking_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        let speciality = "";
        response.data.data.speciality.map((val) => {
          speciality = val.speciality_id;
        });
        setBookingDetail(response.data);
        setInitialData(response.data)
        response.data.data.speciality = speciality;
        setSpecialityId(response.data.data.speciality);
        setData(response.data.data);
        setGradeId(response.data.data.grade_id);
        setTimeout(() => {
          setgetTrustId(response.data.data.trust_id);
        }, 500);
        setTimeout(() => {
          setGetHospitalId(response.data.data.hospital_id);
        }, 1000);

        setTimeout(() => {
          // setgetTrustId(response.data.data.trust_id);
          getSpecialities();
        }, 500);
        setTimeout(() => {
          getGradeList();
        }, 1000);
      })
      .catch((error) => {
        console.log("error.message", error.message);
      });
  };

  const getSearchBookingDetail = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    let updateData = {
      'booking_id' : booking_id,
      'trust_id' : getTrustId,
      'hospital_id' : getHospitalId,
      'grade_id' : gradeId,
      'speciality_id' : specialityId,
      'ward_id' : data.ward_id,
      'start_time' : data.start_time,
      'end_time' : data.end_time,
      'search': searchingVal ? searchingVal :'',
    }
    
    await axios
      .post(`${apiConfigs.API_URL}api/organization/get-search-booking`,updateData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setBookingDetail(response.data);
        setInitialData(response.data)
      })
      .catch((error) => {
        console.log("error.message", error.message);
      });
  };

  useEffect(() => {
    getCharge();
    getHoursList();
    getBookingDetail();
  }, []);

  const timeFormater = (value) =>{
  let date = new Date(value)
if(date != 'Invalid Date'){
  return `${date.getHours()}:${date.getMinutes()}`
} else {

 return value
}
  }
  const submitData = (e) => {
    e.preventDefault();
    // console.log("timeErrorEnd",timeError , timeErrorEnd)
    // if(!timeError && !timeErrorEnd ){
      if (data?.date && data?.break) {
      dispatch(updateBooking({...data, start_time: timeFormater(startTime) , end_time: timeFormater(endTime)}));
      setTrustNotify(true);
      }
  // }
  };
  const backPage = () => {
    history.goBack();
  };

  const timeFormate = (value) =>{
    const [hours, minutes] = value.split(':').slice(0, 2);
     return `${hours}:${minutes}`;
  }
 
  const getCharge = async () => {
    setRateNotify("");
    let updatedEndTime;

    if (
      (data.start_time ||
      startTime) &&
      (data?.end_time|| endTime) &&
      data.trust_id &&
      data.grade_id &&
      data.date &&
      data.break
    ) {
    //   if(startTime?.$H || startTime?.$m){
    //   data.start_time = `${startTime?.$H ? startTime?.$H :'00'}:${startTime?.$m ? startTime?.$m :'00'}`
    //  }else {
    //   data.start_time = startTime
    //  }
    if (startTime?.$H <= 24 || startTime?.$m <= 60) {
      const hours = startTime?.$H !== undefined ? String(startTime.$H).padStart(2, '0') : '00';
      const minutes = startTime?.$m !== undefined ? String(startTime.$m).padStart(2, '0') : '00';
      data.start_time = `${hours}:${minutes}`;
    } else {
      data.start_time = startTime;
    }
    //  if(endTime?.$H || endTime?.$m){
    //   data.end_time = `${endTime?.$H ? endTime?.$H :'00'}:${endTime?.$m ? endTime?.$m :'00'}`
    //  }else{
    //   data.end_time = endTime
    //  }
    if (endTime?.$H <= 24 || endTime?.$m <= 60) {
      const hours = endTime?.$H !== undefined ? String(endTime.$H).padStart(2, '0') : '00';
      const minutes = endTime?.$m !== undefined ? String(endTime.$m).padStart(2, '0') : '00';
      updatedEndTime = `${hours}:${minutes}`;
      data.end_time = updatedEndTime;
    } else {
      data.end_time = endTime;
    }
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      await axios
        .post(`${apiConfigs.API_URL}api/organization/get-rate`,{...data, date: moment(data.date).format("YYYY-MM-DD")}, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        })
        .then((response) => {
          const output = response.data.data;
          if (output?.status === false) {
            setBreakDateErr({
              date: true,
              break: true,
              grade:false

            });
            data.chargeable = data.payable = "";
            data.date = "";
            data.break = "";
            data.non_awr_paye_chargeble = "";
            data.awr_umbrella_chargeble = "";
            data.awr_umbrella_chargeble = "";
            data.non_awr_umbrella_chargeble = "";
            data.non_awr_paye_payable = "";
            output.awr_umbrella_payableAmount = "";
            data.non_awr_umbrella_payable = "";
            data.total_hours = "";
            data.total_holiday_hour = "";
            data.total_saturday_hour = "";
            data.total_day_hour = "";
            data.total_night_hour = "";
            data.payment_scheme_type = "";
            setData({ ...data });
            setRateNotify(output.message);
            setIsNonAwr(false);
          } else {
            setBreakDateErr({
              date: false,
              break: false,
              grade:false

            });
            data.payable = Number(output.payableAmount);
            data.chargeable = output.chargebleAmount;
            data.non_awr_paye_chargeble = output.non_awr_paye_chargebleAmount;
            data.awr_umbrella_chargeble = output.awr_umbrella_chargebleAmount;
            data.non_awr_umbrella_chargeble =
              output.non_awr_umbrella_chargebleAmount;

            data.non_awr_paye_payable = output.non_awr_paye_payableAmount;
            data.awr_umbrella_payable = output.awr_umbrella_payableAmount;
            data.non_awr_umbrella_payable =
              output.non_awr_umbrella_payableAmount;
            data.total_hours = output.totalhours;
            data.total_holiday_hour = output.holiDayhour;
            data.total_saturday_hour = output.saturDayhour;
            data.total_day_hour = output.dayhour;
            data.total_night_hour = output.nighthour;
            data.payment_scheme_type = output.payment_scheme_type;
            setData({ ...data });
            setIsNonAwr(false);
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        });
    }
  };

const dateHandler = (name,date)=>{
     setData({ ...data, [name]:date });
      data.date = date
      getCharge();
    
  } 
  const getHoursList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-hours`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setHours(response.data);
        const output = response.data.data;
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };
  return (
    <>
      {trustNotify && updateBookingSuccess?.message && (
        <Notification data={updateBookingSuccess?.message} status="success" />
      )}
      {trustNotify && updateBookingError?.message && (
        <Notification data={updateBookingError?.message} status="error" />
      )}
      {rateNotify && rateNotify && (
        <Notification data={rateNotify} status="error" />
      )}
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
        </Backdrop>
      ) : (
        ""
      )}
      <Paper className={classes.root}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="reference_id"
                label="Reference Id"
                variant="outlined"
                name="reference_id"
                value={data?.reference_id}
                onChange={handleChange}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={updateBookingError?.message?.staff_id ? true : false}
                disabled={
                  (loginUserInfo?.role === "STAFF" &&
                    loginUserInfo?.staffdetails === "Admin") ||
                  loginUserInfo?.role === "ORGANIZATION"
                    ? false
                    : true
                }
              >
                <InputLabel>Consultant Name</InputLabel>
                <Select
                  value={data.staff_id}
                  label="Consultant Name"
                  name="staff_id"
                  onChange={trustHandleChange}
                  disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                >
                  <MenuItem value="">Select Consultant</MenuItem>
                  {staff?.data &&
                    staff?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="booking_reference"
                label="Booking Reference"
                type="text"
                name="booking_reference"
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                className={classes.textField}
                variant="outlined"
                error={
                  updateBookingError?.message?.booking_reference ? true : false
                }
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                value={data?.booking_reference}
                inputProps={{
                  min: 60,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                error={!!updateBookingError?.message?.trust_id}
              >
                <InputLabel>Customers Name</InputLabel>
                <Select
                  value={data?.trust_id}
                  label="Customers Name"
                  name="trust_id"
                  onChange={trustHandleChange}
                >
                  <MenuItem value="">Select Customers</MenuItem>
                  {trust?.data !== undefined &&
                    trust?.data &&
                    trust?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}

                </Select>
                <FormHelperText>
                  {updateBookingError?.message?.trust_id
                    ? "The Customers field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                error={!!updateBookingError?.message?.hospital_id}
              >
                <InputLabel>Site Name</InputLabel>
                <Select
                  value={data?.hospital_id ? data?.hospital_id : ""}
                  onChange={hospitalHandleChange}
                  label="Site Name"
                  name="hospital_id"
                >
                  <MenuItem value="">Select Site</MenuItem>
                  {hospitalList?.data !== undefined &&
                    hospitalList?.data &&
                    hospitalList?.data.map((List, index) => {
                      return (
                        <MenuItem value={List.id} key={index}>
                          {List.hospital_name}
                        </MenuItem>
                      );
                    })}

                </Select>
                <FormHelperText>
                  {updateBookingError?.message?.hospital_id
                    ? "The site field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                error={!!updateBookingError?.message?.ward_id}
              >
                <InputLabel>Ward Name</InputLabel>
                <Select
                  value={data?.ward_id}
                  onChange={handleChange}
                  name="ward_id"
                  label="Ward Name"
                >
                  <MenuItem value="">Select a ward</MenuItem>
                  {wardList?.data !== undefined &&
                    wardList?.data &&
                    wardList?.data.map((list, index) => {
                      return (
                        <MenuItem value={list.id} key={index}>
                          {list.ward_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {updateBookingError?.message?.ward_id
                    ? "The ward field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                error={updateBookingError?.message?.speciality}
              >
                <InputLabel>Specialities Name</InputLabel>

                <Select
                  value={
                    data?.speciality.length > 0
                      ? data?.speciality[0]
                      : data?.speciality
                  }
                  onChange={handleChangeCheck}
                  label="Specialities Name"
                  name="speciality"
                >
                  {speciality?.data !== undefined &&
                    speciality?.data &&
                    speciality?.data.map((items, index) => {
                      return (
                        <MenuItem value={items.id} key={index}>
                          {items.speciality_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {updateBookingError?.message?.speciality
                    ? "The specialities field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                error={!!updateBookingError?.message?.grade_id || gradeId === '' ?  true : false }
              >
                <InputLabel>Grade Required</InputLabel>
                <Select
                  value={data.grade_id}
                  onChange={handleChange}
                  name="grade_id"
                  label="Grade Required"
                >
                  <MenuItem value="">Select a grade</MenuItem>
                  {gradeList?.data !== undefined &&
                    gradeList?.data &&
                    gradeList?.data.map((list, index) => {
                      return (
                        <MenuItem value={list.id} key={index}>
                          {list.grade_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {updateBookingError?.message?.grade_id
                    ? "The grade field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* <TextField
                id="date"
                label="Date"
                type="date"
                name="date"
                value={data?.date}
                className={classes.textField}
                variant="outlined"
                error={
                  updateBookingError?.message?.data
                    ? true
                    : breakDateErr?.date
                    ? true
                    : false
                }
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          id="date"
                          label="Date"
                          type="date"
                          name="date"
                          value={data.date}
                          className={classes.textField}
                          onChange={(e) =>{dateHandler("date",e)
                        }}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          error={
                              updateBookingError?.message?.data
                                ? true
                              : breakDateErr?.date
                               ? true
                                 : false
                            }
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" required fullWidth {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          required
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <TimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(newValue)=>{
                      setStartTime(newValue);
                      setIsStartTime(!isStartTime)
                    }}
                      renderInput={(params) =>
                         <TextField
                         {...params} 
                        //  onFocus={(e) => handleChangeStartTime(e.target.value) }
                         
                         onBlur={(e) => handleChangeStartTime(e.target.value)} 
                         variant="outlined"
                         />}
                    // className="customClock"
                    
                    ampm={false}  
                    hours12={false}
                  />
                </Stack>
              </LocalizationProvider>
              {timeError ?
              <p style={{
                color:"red"
              }}
              >
            Please select a valid time 
            </p>:''}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <TimePicker
                      label="End Time"
                      value={endTime}
                      onChange={(newValue) =>{
                        setEndTime(newValue);
                        setIsEndTime(!isEndTime)
                      }}
                      // renderInput={(params) => <TextField {...params} variant="outlined"/>}
                      // className="customClock"
                       renderInput={(params) =>
                         <TextField
                         {...params} 
                        //  onFocus={(e) => handleChangeEndTime(e.target.value) }
                         onBlur={(e) => handleChangeEndTime(e.target.value)}
                         variant="outlined"
                         />}
                      ampm={false}  
                      hours12={false}
                      InputLabelProps={{ shrink: true}} 
                    />
                  </Stack>
                </LocalizationProvider>
                {timeErrorEnd  ? 
              <p style={{
                color:"red"
              }}
              >
                Please select a valid time
               </p> : ''}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="break"
                label="Break (In Minutes)"
                type="number"
                name="break"
                value={data?.break}
                className={classes.textField}

                //disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                variant="outlined"
                error={
                  updateBookingError?.message?.break
                    ? true
                    : breakDateErr?.break
                    ? true
                    : false
                }
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                  maxLength: 3,
                }}
                inputProps={{
                  max: 120,
                  onWheel: (event) => event.target.blur(),
                }}
                fullWidth
                required
                maxLength={3}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="total_hours"
                label="Total Hours"
                type="text"
                value={data?.total_hours}
                name="total_hours"
                className={classes.textField}
                variant="outlined"
                error={updateBookingError?.message?.total_hours ? true : false}
                onChange={handleChange}
                disabled={true}
                fullWidth
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={updateBookingError?.message?.booking_type ? true : false}
                disabled={ (data?.payment_scheme_type === "Fixed" || data?.custom_status == "PROVISIONAL") ?  true : false}
               
              >
                <InputLabel>Booking Types</InputLabel>
                <Select
                  value={data.booking_type}
                  label="Booking Types"
                  name="booking_type"
                  disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a Booking Types</MenuItem>
                  <MenuItem value="Day">Day</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                  <MenuItem value="Saturday">Saturday</MenuItem>
                  <MenuItem value="Sunday">Sunday</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="purchase_order"
                label="Purchase Order"
                type="text"
                name="purchase_order"
                className={classes.textField}
                variant="outlined"
                error={
                  updateBookingError?.message?.purchase_order ? true : false
                }
                onChange={handleChange}
                disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                value={data?.purchase_order}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>Amount</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="non_awr_paye_payable"
                  label="NON-AWR - PAYE"
                  variant="outlined"
                  disabled={data?.custom_status === "PROVISIONAL" ? true : false}
                  name="non_awr_paye_payable"
                  type="number"
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={data?.non_awr_paye_payable}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="payable"
                  label="AWR - PAYE"
                  variant="outlined"
                  name="payable"
                  type="number"
                  value={data.payable}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="non_awr_umbrella_payable"
                  label="NON-AWR - UMBRELLA"
                  variant="outlined"
                  name="non_awr_umbrella_payable"
                  type="number"
                  value={data?.non_awr_umbrella_payable}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <div className="rate-symbol">
                <span className="symbol">£</span>
                <TextField
                  id="awr_umbrella_payable"
                  label="AWR -UMBRELLA"
                  variant="outlined"
                  name="awr_umbrella_payable"
                  type="number"
                  value={data?.awr_umbrella_payable}
                  onChange={handleChange}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>

          <Box className={classes.footerBtn}>
            
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={submitData}
              formNoValidate
              disabled={isNonAwr}
            >
              Update{" "}
              {loading === true ? (
                <CircularProgress
                  style={{ width: 16, height: 16, marginLeft: 10 }}
                  color="inherit"
                />
              ) : (
                ""
              )}
            </Button>
          </Box>
        </form>
      </Paper>
       { <CandidatesList
          bookingDetail={bookingDetail}
          booking_id={booking_id}
          getBookingDetail={getBookingDetail}
          setConfirmBtn={setConfirmBtn}
          setBookingDetail={setBookingDetail}
          initialData={initialData}
          actionType={'update'}
           searchingVal={searchingVal}
          setSearchingVal={setSearchingVal}
          setCandidateAssignDetails={setCandidateAssignDetails}
        />}
    </>
  );
};

export default UpdateBooking;
