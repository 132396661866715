import moment from "moment/moment";
import { apiClient } from "../../../config/apiClient";
import {
  CHANGE_PAYMENT_STATUS_ERROR,
  CHANGE_PAYMENT_STATUS_REQUEST,
  CHANGE_PAYMENT_STATUS_SUCCESS,
  GET_BOOKING_ERROR,
  GET_BOOKING_REQUEST,
  GET_BOOKING_SUCCESS,
  USER_INVITATION_ERROR,
  USER_INVITATION_REQUEST,
  USER_INVITATION_SUCCESS,
} from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";
export const getReport = ({ pageNo = 1, data,type="",column_name="" }) => {
  return async (dispatch) => {
    dispatch(notificationFail(""));
    dispatch(getReportRequest());
    await apiClient(true)
      .get(
        `api/organization/report-completed-booking?speciality=${data.specialty}&customer=${data.customer}&site=${data.site}&ward=${data.ward}&framework=${data.framework}&consultant=${data.consultants}&candidate=${data.candidate}&start_date=${moment(data.start_date).format("YYYY-MM-DD")}&end_date=${moment(data.end_date).format("YYYY-MM-DD")}&trust_id=${data.trust_id}&umbrella_id=${data.umbrella_id}&page=${pageNo}&type=${type}&column_name=${column_name}&report_type=${data.report_type}`
      )
      .then((response) => {
        dispatch(getReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getReportSuccess(""));
        dispatch(getReportError(error));
      });
  };
};

const getReportRequest = () => {
  return {
    type: GET_BOOKING_REQUEST,
  };
};

const getReportSuccess = (data) => {
  return {
    type: GET_BOOKING_SUCCESS,
    payload: data,
  };
};

const getReportError = (error) => {
  return {
    type: GET_BOOKING_ERROR,
    payload: error,
  };
};

// -----------------------------------------------------

export const userInvitation = (data) => {
  return async (dispatch) => {
    dispatch(userInvitationRequest());
    await apiClient(true)
      .post(`api/organization/user/send-invitation`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(userInvitationSuccess(dataItem));
      })
      .catch((error) => {
        dispatch(userInvitationFailure(error));
      });
  };
};

const userInvitationRequest = () => {
  return {
    type: USER_INVITATION_REQUEST,
  };
};

const userInvitationSuccess = (data) => {
  return {
    type: USER_INVITATION_SUCCESS,
    payload: data,
  };
};

const userInvitationFailure = (error) => {
  return {
    type: USER_INVITATION_ERROR,
    payload: error,
  };
};

// --------------------------------------------

export const changePaymentStatus = (data) => {
  return async (dispatch) => {
    dispatch(changePaymentStatusRequest());
    await apiClient(true)
      .put(`api/organization/change-signee-payment-status`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(changePaymentStatusSuccess(dataItem.message));
        dispatch(notificationSuccess(dataItem.message));
      })
      .catch((error) => {
        dispatch(changePaymentStatusFailure(error));
        dispatch(notificationFail(error.response.data.message));
      });
  };
};

const changePaymentStatusRequest = () => {
  return {
    type: CHANGE_PAYMENT_STATUS_REQUEST,
  };
};

const changePaymentStatusSuccess = (data) => {
  return {
    type: CHANGE_PAYMENT_STATUS_SUCCESS,
    payload: data,
  };
};

const changePaymentStatusFailure = (error) => {
  return {
    type: CHANGE_PAYMENT_STATUS_ERROR,
    payload: error,
  };
};
