import React, { Fragment, useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentScheme, updateTrust } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import axios from "axios";
import apiConfigs from "../../config/config";
import CloseIcon from "@material-ui/icons/Close";
import history from "../../utils/HistoryUtils";
import UtilService from "../../helper/service";
import { getSpecShiftCreate } from "../../store/action/specialities/specialitiesAction";
import { getAllTrust } from "../../store/action/trust/trustAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  formControl: {
    width: "100%",
  },
  formControl1: {
    width: "96%",
    marginLeft: "16px",
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  hospitalBox: {
    padding: 8,
    width: "100%",
  },
  lightGray: {
    background: "#f4f5f6",
    width: "100%",
    margin: 0,
    padding: "16px 12px",
  },
  wardBox: {
    margin: 0,
    width: "100%",
    position: "relative",
  },

  addWards: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      width: 18,
      height: "auto",
    },
  },
  removeWard: {
    position: "absolute",
    top: 24,
    right: 24,
    cursor: "pointer",
  },
  removeTraining: {
    position: "absolute",
    top: 24,
    right: "20px",
    cursor: "pointer",
  },
  removehospital: {
    position: "absolute",
    top: -9,
    right: -9,
    cursor: "pointer",
    background: "#ff8b46",
    borderRadius: "50%",
    width: 26,
    height: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: 16,
    },
  },
  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },
}));

const UpdatePaymentScheme = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [gradeList, setGradeList] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [trust, setTrust] = useState([]);
  const id = match.params.id;
  const { updatePaymentSchemeError, updatePaymentSchemeSuccess } = useSelector(
    (state) => state.paymentScheme
  );
  const [paymentSchNotify, setPaymentSchNotify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [specError, setSpecError] = useState(false);
  const [hours, setHours] = useState([]);
  
  const [isChecked, setIsChecked] = useState(false);
  const [data, setData] = useState({
    id: "",
    trust_id: "",
    grade_id: "",
    speciality_id: "",
    remark: "",
    payable_day_rate: "",
    payable_night_rate: "",
    payable_saturday_rate: "",
    payable_holiday_rate: "",
    chargeable_day_rate: "",
    chargeable_night_rate: "",
    chargeable_saturday_rate: "",
    chargeable_holiday_rate: "",
    day_from: "",
    day_to: "",
    night_from: "",
    night_to: "",
    saturday_from: "",
    saturday_to: "",
    holiday_from: "",
    holiday_to: "",
    awr_umbrella_payable_day_rate: "",
    awr_umbrella_payable_night_rate: "",
    awr_umbrella_payable_saturday_rate: "",
    awr_umbrella_payable_holiday_rate: "",
    awr_umbrella_chargeable_day_rate: "",
    awr_umbrella_chargeable_night_rate: "",
    awr_umbrella_chargeable_saturday_rate: "",
    awr_umbrella_chargeable_holiday_rate: "",

    non_awr_paye_payable_day_rate: "",
    non_awr_paye_payable_night_rate: "",
    non_awr_paye_payable_saturday_rate: "",
    non_awr_paye_payable_holiday_rate: "",
    non_awr_paye_chargeable_day_rate: "",
    non_awr_paye_chargeable_night_rate: "",
    non_awr_paye_chargeable_saturday_rate: "",
    non_awr_paye_chargeable_holiday_rate: "",
  
    non_awr_umbrella_payable_day_rate: "",
    non_awr_umbrella_chargeable_day_rate: "",
    non_awr_umbrella_payable_night_rate: "",
    non_awr_umbrella_chargeable_night_rate: "",
    non_awr_umbrella_payable_saturday_rate: "",
    non_awr_umbrella_chargeable_saturday_rate: "",
    non_awr_umbrella_payable_holiday_rate: "",
    non_awr_umbrella_chargeable_holiday_rate: "",
    start_date: "",
    end_date: "",
    payment_scheme_type: "",
    wtr:""
  });

    const handleCheckboxClick = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setIsChecked(true);
      setData({ ...data, [event.target.name]: "Yes" });
    } else {
      setIsChecked(false);
      setData({ ...data, [event.target.name]: "No" });
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "day_to") {
      data.night_from = event.target.value;
    }
    if (event.target.name === "day_from") {
      data.night_to = event.target.value;
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const getTrust = async () => {
    try {
      dispatch(
        getAllTrust((result) => {
          setTrust(result);
        })
      );
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getGradeList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-gradelist`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setGradeList(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };

  const getSpecialities = async () => {
    dispatch(
      getSpecShiftCreate((result) => {
        if (result?.status === true) {
          setSpeciality(result);
        } else {
          setSpecError(true);
        }
      })
    );
  };

  useEffect(() => {
    getSpecialities();
    getTrust();
    getHoursList();
    getGradeList();
  }, []);

  const getSignlePaymentScheme = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    setLoading(true);
    await axios
      .get(`${apiConfigs.API_URL}api/organization/payment-scheme/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        if (response?.data?.data?.wtr?.replace(/['"]+/g, "") === "Yes") {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("error.message", error.message);
        setLoading(false);
      });
  };

  const getHoursList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-hours`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setHours(response.data);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  useEffect(() => {
    getSignlePaymentScheme();
  }, [id]);

  const submitData = async (e) => {
    e.preventDefault();
   let payload = {
      ...data,
    start_date: data?.start_date ? moment(data?.start_date).format("YYYY-MM-DD") : "",
    end_date:data?.end_date ? moment(data?.end_date).format("YYYY-MM-DD") : ""
    }
    dispatch(updatePaymentScheme(payload));
    setPaymentSchNotify(true);
  };

  const backPage = () => {
    history.goBack();
  };
 const dateHandler = (name,date)=>{
    setData({ ...data, [name]: date });
  }
  return (
    <>
      {paymentSchNotify && updatePaymentSchemeSuccess?.message && (
        <Notification
          data={updatePaymentSchemeSuccess?.message}
          status="success"
        />
      )}

      {paymentSchNotify && updatePaymentSchemeError?.message && (
        <Notification data={updatePaymentSchemeError?.message} status="error" />
      )}
      <Paper className={classes.root}>
        <form onSubmit={submitData}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={!!updatePaymentSchemeError.message?.trust_id}
              >
                <InputLabel>Customer Name</InputLabel>
                <Select
                  value={data.trust_id}
                  label="Customer Name"
                  name="trust_id"
                  onChange={handleChange}
                >
                  <MenuItem value="">Select Customers</MenuItem>
                  {trust?.data &&
                    trust?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {updatePaymentSchemeError?.message?.trust_id
                    ? "The Customers field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={!!updatePaymentSchemeError.message?.speciality_id}
              >
                <InputLabel value="">Specialities Name</InputLabel>
                <Select
                  value={data?.speciality_id}
                  label="Specialities Name"
                  name="speciality_id"
                  onChange={handleChange}
                >
                  {speciality?.data &&
                    speciality?.data.map((items, index) => {
                      return (
                        <MenuItem value={items.id} key={index}>
                          {items.speciality_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {updatePaymentSchemeError?.message?.speciality_id
                    ? "The Speciality field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={!!updatePaymentSchemeError.message?.grade_id}
              >
                <InputLabel>Grade Required</InputLabel>
                <Select
                  value={data.grade_id}
                  name="grade_id"
                  required
                  label="Grade Required"
                  error={!!updatePaymentSchemeError?.message?.grade_id}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a grade</MenuItem>
                  {gradeList?.data &&
                    gradeList?.data.map((list, index) => {
                      return (
                        <MenuItem value={list.id} key={index}>
                          {list.grade_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {updatePaymentSchemeError?.message?.grade_id
                    ? "The grade field is required."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={!!updatePaymentSchemeError?.message?.remark}
              >
                <InputLabel>Remark </InputLabel>
                <Select
                  value={data?.remark}
                  name="remark"
                  label="remark Required"
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a Remark</MenuItem>
                  <MenuItem value="Day Rate" key="Day Rate">
                    {" "}
                    Day Rate
                  </MenuItem>
                  <MenuItem value="Night Rate" key="Night Rate">
                    {" "}
                    Night Rate
                  </MenuItem>
                  <MenuItem value="Saturday Rate" key="Saturday Rate">
                    {" "}
                    Saturday Rate
                  </MenuItem>
                  <MenuItem value="Sunday Rate" key="Sunday Rate">
                    {" "}
                    Sunday Rate
                  </MenuItem>
                </Select>
              </FormControl>
             
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* <TextField
                id="start_date"
                value={data?.start_date}
                label="Start Date"
                type="date"
                name="start_date"
                className={classes.textField}
                variant="outlined"
                error={
                  !!updatePaymentSchemeError?.message?.start_date ? true : false
                }
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                inputProps={
                  {
                    // min: disPastDate,
                  }
                }
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                        id="start_date"
                         value={data?.start_date}
                         label="Start Date"
                         type="date"
                         name="start_date"
                         className={classes.textField}
                         variant="outlined"
                         onChange={(e) =>dateHandler("start_date",e)}
                         error={
                           !!updatePaymentSchemeError?.message?.start_date ? true : false
                           }
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* <TextField
                id="end_date"
                label="End Date"
                type="date"
                name="end_date"
                className={classes.textField}
                variant="outlined"
                value={data?.end_date}
                error={
                  !!updatePaymentSchemeError?.message?.end_date ? true : false
                }
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                inputProps={
                  {
                    // min: disPastDate,
                  }
                }
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                           id="end_date"
                          label="End Date"
                          type="date"
                          name="end_date"
                          className={classes.textField}
                          variant="outlined"
                          value={data.end_date}
                          onChange={(e) =>dateHandler("end_date",e)}
                          error={
                           !!updatePaymentSchemeError?.message?.end_date ? true : false
                           }                          
                           InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="nhs_product_group"
                label="NHS Product Group"
                variant="outlined"
                name="nhs_product_group"
                InputLabelProps={{
                  shrink: data?.nhs_product_group ? true : false,
                }}
                value={data?.nhs_product_group}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_product_group"
                label="LPP Product Group"
                variant="outlined"
                name="lpp_product_group"
                InputLabelProps={{
                  shrink: data?.lpp_product_group ? true : false,
                }}
                value={data?.lpp_product_group}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_job_title"
                label="LPP Job Title"
                variant="outlined"
                name="lpp_job_title"
                InputLabelProps={{
                  shrink: data?.lpp_job_title ? true : false,
                }}
                value={data?.lpp_job_title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_job_code"
                label="LPP Job Code"
                variant="outlined"
                name="lpp_job_code"
                InputLabelProps={{
                  shrink: data?.lpp_job_code ? true : false,
                }}
                value={data?.lpp_job_code}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_hte_job_family"
                label="LPP/HTE Job Family"
                variant="outlined"
                name="lpp_hte_job_family"
                InputLabelProps={{
                  shrink: data?.lpp_hte_job_family ? true : false,
                }}
                value={data?.lpp_hte_job_family}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="miso_job_title"
                label="MISO Job Title"
                variant="outlined"
                name="miso_job_title"
                InputLabelProps={{
                  shrink: data?.miso_job_title ? true : false,
                }}
                value={data?.miso_job_title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className="mt-3">
                <Typography>Payment Scheme Type</Typography>
                <RadioGroup
                  name="payment_scheme_type"
                  value={data?.payment_scheme_type || ""}
                  onChange={handleChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="Fixed"
                    control={<Radio />}
                    label="Fixed"
                  />
                  <FormControlLabel
                    value="Variable"
                    control={<Radio />}
                    label="Variable"
                  />
                </RadioGroup>
              </Box>
            </Grid>

           <Grid item xs={12} sm={6} lg={4}>
              <Checkbox
                checked={isChecked}
                label="WTR"
                name="wtr"
                value={data?.wtr}
                onClick={(event) => handleCheckboxClick(event)}
                className="mt-2"
              />{" "}
              WTR
            </Grid>

            <Grid item xs={12}>
              <div className="pt-2 pb-0">
                <Divider />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>Rates</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="pt-0 pb-2">
                <Divider />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Day Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.day_from}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data?.day_from}
                  label="From Time Required"
                  name="day_from"
                  disabled={data?.payment_scheme_type === "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  <MenuItem value="">From Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.day_to}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data?.day_to}
                  label="To Time"
                  name="day_to"
                  disabled={data?.payment_scheme_type === "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_day_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_day_rate"
                        value={data?.non_awr_paye_payable_day_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_payable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_day_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_day_rate"
                        value={data?.non_awr_paye_chargeable_day_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_chargeable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_day_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        value={data?.payable_day_rate}
                        name="payable_day_rate"
                        helperText={
                          updatePaymentSchemeError.message?.payable_day_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message?.payable_day_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_day_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        name="chargeable_day_rate"
                        value={data?.chargeable_day_rate}
                        helperText={
                          updatePaymentSchemeError.message?.chargeable_day_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.chargeable_day_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_day_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_day_rate"
                        value={data?.non_awr_umbrella_payable_day_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_payable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_day_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_day_rate"
                        value={data?.non_awr_umbrella_chargeable_day_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_chargeable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_day_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_day_rate"
                        value={data?.awr_umbrella_payable_day_rate}
                        helperText={
                          updatePaymentSchemeError.message
                            ?.awr_umbrella_payable_day_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_payable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_day_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_day_rate"
                        value={data?.awr_umbrella_chargeable_day_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_chargeable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Night Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.night_from}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data?.night_from}
                  label="From Time Required"
                  name="night_from"
                  onChange={handleChange}
                  disabled={true}
                >
                  <MenuItem value="">From Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.night_to}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data?.night_to}
                  label="To Time"
                  name="night_to"
                  onChange={handleChange}
                  disabled={true}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_night_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_night_rate"
                        value={data?.non_awr_paye_payable_night_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_payable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_night_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_night_rate"
                        value={data?.non_awr_paye_chargeable_night_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_chargeable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_night_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        name="payable_night_rate"
                        value={data?.payable_night_rate}
                        helperText={
                          updatePaymentSchemeError.message?.payable_night_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message?.payable_night_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_night_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        value={data?.chargeable_night_rate}
                        name="chargeable_night_rate"
                        helperText={
                          updatePaymentSchemeError.message
                            ?.chargeable_night_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.chargeable_night_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_night_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_night_rate"
                        value={data?.non_awr_umbrella_payable_night_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_payable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_night_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_night_rate"
                        value={data?.non_awr_umbrella_chargeable_night_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_chargeable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_night_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_night_rate"
                        value={data?.awr_umbrella_payable_night_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_payable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_night_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_night_rate"
                        value={data?.awr_umbrella_chargeable_night_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_chargeable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Saturday Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.saturday_from}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data?.saturday_from}
                  label="From Time Required"
                  name="saturday_from"
                  disabled={data?.payment_scheme_type === "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  <MenuItem value="">From Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.saturday_to}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data?.saturday_to}
                  label="To Time"
                  name="saturday_to"
                  disabled={data?.payment_scheme_type === "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_saturday_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_saturday_rate"
                        value={data?.non_awr_paye_payable_saturday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_payable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_saturday_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_saturday_rate"
                        value={data?.non_awr_paye_chargeable_saturday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_chargeable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_saturday_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        value={data?.payable_saturday_rate}
                        name="payable_saturday_rate"
                        helperText={
                          updatePaymentSchemeError.message
                            ?.payable_saturday_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.payable_saturday_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_saturday_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        value={data?.chargeable_saturday_rate}
                        name="chargeable_saturday_rate"
                        helperText={
                          updatePaymentSchemeError.message
                            ?.chargeable_saturday_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.chargeable_saturday_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_saturday_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_saturday_rate"
                        value={data?.non_awr_umbrella_payable_saturday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_payable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_saturday_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_saturday_rate"
                        value={data?.non_awr_umbrella_chargeable_saturday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_chargeable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_saturday_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_saturday_rate"
                        value={data?.awr_umbrella_payable_saturday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_payable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_saturday_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_saturday_rate"
                        value={data?.awr_umbrella_chargeable_saturday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_chargeable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Holiday/Sunday Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.holiday_from}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data?.holiday_from}
                  label="From Time Required"
                  name="holiday_from"
                  disabled={data?.payment_scheme_type === "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  <MenuItem value="">From Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type === "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={!!updatePaymentSchemeError?.message?.holiday_to}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data?.holiday_to}
                  label="To Time"
                  name="holiday_to"
                  disabled={data?.payment_scheme_type === "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_holiday_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_holiday_rate"
                        value={data?.non_awr_paye_payable_holiday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_payable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_holiday_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_holiday_rate"
                        value={data?.non_awr_paye_chargeable_holiday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_paye_chargeable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_holiday_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        value={data?.payable_holiday_rate}
                        name="payable_holiday_rate"
                        helperText={
                          updatePaymentSchemeError.message?.payable_holiday_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.payable_holiday_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_holiday_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        value={data?.chargeable_holiday_rate}
                        name="chargeable_holiday_rate"
                        helperText={
                          updatePaymentSchemeError.message
                            ?.chargeable_holiday_rate
                        }
                        error={
                          !!updatePaymentSchemeError.message
                            ?.chargeable_holiday_rate
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_holiday_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_holiday_rate"
                        value={data?.non_awr_umbrella_payable_holiday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_payable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_holiday_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_holiday_rate"
                        value={data?.non_awr_umbrella_chargeable_holiday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.non_awr_umbrella_chargeable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_holiday_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_holiday_rate"
                        value={data?.awr_umbrella_payable_holiday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_payable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_holiday_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_holiday_rate"
                        value={data?.awr_umbrella_chargeable_holiday_rate}
                        error={
                          !!updatePaymentSchemeError.message
                            ?.awr_umbrella_chargeable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              formNoValidate
            >
              Update
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default UpdatePaymentScheme;
