import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Card,
  Chip,
  InputBase,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AlertDialog from "../../components/Alert/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import history from "../../utils/HistoryUtils";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import apiConfigs from "../../config/config";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import MessageIcon from "@material-ui/icons/Message";

import "react-datepicker/dist/react-datepicker.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  changeDocStatus,
  deleteSignee,
  downloadChecklistPdf,
  deleteSigneeDoc,
  getSingleSignee,
  getContactEventForSignee,
  signeeCompStatus,
  signeeProStatus,
} from "../../store/action";



import Notification from "../../components/Notification/Notification";
import InsertPhotoOutlinedIcon from "@material-ui/icons/InsertPhotoOutlined";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CreateContactEvent from "./CreateContactEvent";
import SingleCandidateList from "./SingleCandidateList";
import CreateRemarksEvent from "./CreateRemarksEvent";
import CreateRemarksDocument from "./CreateRemarksDocument";
import CreateDatePickerEvent from "./CreateDatePickerEvent";
import { ADD_SIGNEE_REMARK_SUCCESS } from "../../store/action/actiontypes";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCellsContactEvent } from "./HeadcellsContactEvent";
import UserNotificationPreference from "../UserNotificationPreference/UserNotificationPreference";
import EditContactEventDialog from "./EditContactDialog";
import { notificationSuccess } from "../../store/action/notificationMsg";
import { enabledDisabledLogin } from "../../store/action/signee/signeeAction";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  root1: {
    padding: 24,
    margin: "8px 0",
  },
  desc: {
    fontSize: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },
  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },
  accDetailBackColor: {
    backgroundColor: "#eaecfb",
    margin: "0",
    padding: "0 4px",
  },
  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },
  boxContainer: {
    borderBottom: "1px solid #ccc",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  selectCon: {
    marginLeft: "auto",
    minWidth: 140,
  },
  fileCon: {
    marginLeft: "auto",
    minWidth: 140,
    textAlign: "right",
    marginRight: "20px",
    ordeflow: "hidden",
    flex: 1,
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  statusSelect: {
    "& .MuiSelect-select": {
      padding: "10px 32px 10px 10px",
    },
  },
  statusContainer: {
    width: "100%",
    maxWidth: "100%",
    flex: "0 0 100%",
    padding: "6px !important",
    background: "#eaebed",
    marginBottom: 24,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  statusLabel: {
    marginRight: 16,
    fontWeight: "500",
    color: "#184a7b",
  },
  formControl1: {
    width: 182,
    display: "flex",
    border: "none",
    background: "#184a7b",
    color: "#fff",
    padding: "4px 8px",
    paddingLeft: 12,
    borderRadius: 6,
    "& .MuiInputBase-root": {
      color: "#fff",
      "&:before": {
        border: "none !important",
      },
    },
    "& svg": {
      fill: "#fff",
    },
  },
  cardBox: {
    background: "#cae0f7",
    padding: "12px 20px",
    marginRight: 12,
    minWidth: 220,
    marginBottom: 12,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  uploadFile: {
    position: "absolute",
    left: "0",
    top: "0",
    opacity: "0",
    zIndex: "9",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,

    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: "auto",
      cursor: "pointer",
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const DetailSignee = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const user_id = match.params.id;
  const [Id, setId] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteDocOpen, setDeleteDocOpen] = useState(false);
  const [docId, setDocsId] = useState(false);
  const [signeeNotify, setSigneeNotify] = useState(false);
  const [docNotify, setDocNotify] = useState(false);
  const [compNotify, setCompNotify] = useState(false);
  const [proNotify, setProNotify] = useState(false);
  const [key1, aetKey1] = useState("");
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const loginDetail = JSON.parse(localStorage.getItem("loginUserInfo"));
  const loginUserInfo = loginDetail;
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [key2, setKey] = useState("");
  const getToken = localStorage.getItem("token")
    ? localStorage.getItem("token").replace(/['"]+/g, "")
    : "";
  const [addDocMsg, setAddDocMsg] = useState("");
  const [fileSizeMsg, setFileSize] = useState("");
  const [notifyMsg, setNotifyMsg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = React.useState(1);
  const [searchData, setSearchData] = useState({ search: "" });
  const [open, setOpen] = useState(false);
  const [openRemark, setOpenRemark] = useState(false);
  const [openRemarkDocument, setOpenRemarkDocument] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [remarks, setRemarks] = useState({
    document_subkey: "",
    document_id: "",
  });
  const [remarksDocument, setRemarksDocument] = useState({
    document_subkey: "",
    document_id: "",
    remark: "",
  });
  const [exDateDocument, setDatePicker] = useState({
    document_subkey: "",
    document_id: "",
    expire_date: "",
  });
  const [value, setValue] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created_at");

  const baseUrl = apiConfigs.API_URL + "uploads/signee_docs/";
  // "http://backendbooking.kanhasoftdev.com/public/uploads/signee_docs/";
  const { profile } = useSelector((state) => state.orgProfile);

  const {
    getSingleSigneeItem,
    loading,
    deleteSigneeSuccess,
    deleteSigneeError,
    changeDocStatusSuccess,
    signeeProStatusSuccess,
    signeeComStatusSuccess,
    signeeComStatusError,
    deleteDocumentError,
    deleteDocumentSuccess,
    getCandidateEventItem,
    getCandidateEventError,
    addCandidateRemarkSuccess,
    addCandidateRemarkError,
  } = useSelector((state) => state.signee);

  const [complainceStatus, setComplainceStatus] = useState({
    signee_id: user_id,
    organization_id: "",
    key: "",
    document_status: "",
  });
  const [remarkGeneral, setRemarkGeneral] = useState(null);
  const [remarkDocument, setRemarkDocument] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [contactData, setContactData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [profileStatus, setProfileStatus] = useState({
    signee_id: "",
    status: "",
  });

  const [complStatus, setComplStatus] = useState({
    signeeId: "",
    status: "",
  });

  const deleteStaffItem = (id) => {
    setDeleteOpen(true);
    setId(id);
  };
   const downloadChecklist = (id) => {
    dispatch(downloadChecklistPdf(id));
  };

  const disabledEnabledLogin = (id, status) =>{
    let statusData = status === 0 ? "Disabled": "Enabled"
    let data = {id: id, status: statusData}; 
    dispatch(enabledDisabledLogin(data));
  }

  const deleteDocument = (id) => {
    setDeleteDocOpen(true);
    setDocsId(id);
  };
  const [selectedValue, setSelectedValue] = useState("0");

  const [selectedItems, setSelectedItems] = useState([]);
  
  const handleNewCheckboxChange = async (e, subKey) => {
  const isNewChecked = e.target.value; // Get the current checked state of the checkbox
  const wasPreviouslyNewChecked = selectedItems.includes(subKey); // Check if the item was previously selected

  // Update the checked value
  await updateCheckedNewValue(subKey, isNewChecked);

  // Update the selectedItems state based on the current checked state
  if (isNewChecked && !wasPreviouslyNewChecked) {
    setSelectedItems(prevState => [...prevState,subKey]); // Add the item to selectedItems if it was checked
  } else if (!isChecked && wasPreviouslyNewChecked) {
    setSelectedItems(prevState => prevState.filter(id => id !== subKey)); // Remove the item from selectedItems if it was unchecked
  }
};

  const updateCheckedNewValue = async (subKey, isChecked) => {
      // const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      let formData = {
          'key': subKey,
           'is_checked': isChecked,
      }
      await axios
      .post(`${apiConfigs.API_URL}api/organization/new-checked/`+ user_id,formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
     .then((response) => {
      setLoader(false);
      dispatch(getSingleSignee(user_id));
      dispatch(notificationSuccess(response?.data?.message));
      })
      .catch((error) => {
        console.log("error.message", error.message);
      });
  };
  const handleCheckboxChange = async (e, itemId) => {
  const isChecked = e.target.value; // Get the current checked state of the checkbox
  const wasPreviouslyChecked = selectedItems.includes(itemId); // Check if the item was previously selected

  // Update the checked value
  await updateCheckedValue(itemId, isChecked);

  // Update the selectedItems state based on the current checked state
  if (isChecked && !wasPreviouslyChecked) {
    setSelectedItems(prevState => [...prevState, itemId]); // Add the item to selectedItems if it was checked
  } else if (!isChecked && wasPreviouslyChecked) {
    setSelectedItems(prevState => prevState.filter(id => id !== itemId)); // Remove the item from selectedItems if it was unchecked
  }
};

  const updateCheckedValue = async (itemId, isChecked) => {
      // const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      let formData = {
          'id': itemId,
           'is_checked': isChecked,
      }
      await axios
      .post(`${apiConfigs.API_URL}api/organization/check-document`,formData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
     .then((response) => {
      setLoader(false);
      dispatch(getSingleSignee(user_id));
      dispatch(notificationSuccess(response?.data?.message));
      })
      .catch((error) => {
        console.log("error.message", error.message);
      });
  };

  const alertResponse = (id) => {
    dispatch(deleteSignee(id));
    setSigneeNotify(true);
  };
  const deleteDocResponse = (id) => {
    dispatch(deleteSigneeDoc(id));
    setSigneeNotify(true);
  };

  const deleteRoleClose = () => {
    setDeleteOpen(false);
  };
  const deleteDocClose = () => {
    setDeleteDocOpen(false);
  };

  useEffect(() => {
    dispatch({
      type: ADD_SIGNEE_REMARK_SUCCESS,
      payload: [],
    });
  }, []);

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    getevent(1, "");
  }, [orderBy, order]);

  useEffect(() => {
    dispatch(getSingleSignee(user_id));
    getevent(1, "");
  }, [user_id]);

  useEffect(() => {
    if (deleteDocumentSuccess?.status === true) {
      dispatch(getSingleSignee(user_id));
      getevent(1, "");
    }
  }, [deleteDocumentSuccess]);

  const backPage = () => {
    history.go(-2);
  };
  const upadateLink = () => {
    history.push(`update`);
  };

  const handleComplianceStatus = (event, org_id) => {
    setComplainceStatus({
      ...complainceStatus,
      key: event.target.name,
      document_status: event.target.value,
      organization_id: org_id,
    });
  };

  useEffect(() => {
    if (complainceStatus.key !== "") {
      dispatch(changeDocStatus(complainceStatus));
      setDocNotify(true);
      setTimeout(() => {
        dispatch(getSingleSignee(user_id));
      }, 4000);
    }
  }, [complainceStatus]);

  const handleProfileStatus = (event, id) => {
    setProfileStatus({
      ...profileStatus,
      [event.target.name]: event.target.value,
      signee_id: id,
    });
  };

  // console.log(user_id)
  // console.log(getSingleSigneeItem?.data)

  useEffect(() => {
    if (profileStatus.signee_id !== "") {
      dispatch(signeeProStatus(profileStatus));
      setProNotify(true);
      setTimeout(() => {
        dispatch(getSingleSignee(user_id));
        setProNotify(false);
      }, 4000);
    }
  }, [profileStatus]);

  const handleComplStatus = (event, id) => {
    setComplStatus({
      ...complStatus,
      [event.target.name]: event.target.value,
      signeeId: id,
    });
  };

  useEffect(() => {
    if (complStatus.signeeId !== "") {
      dispatch(signeeCompStatus(complStatus));
      setCompNotify(true);
      setTimeout(() => {
        dispatch(getSingleSignee(user_id));
        setCompNotify(false);
      }, 4000);
    }
  }, [complStatus]);
  let specilaity_list = getSingleSigneeItem?.data?.speciality?.speciality_name
    ? getSingleSigneeItem?.data?.speciality?.speciality_name.split(",")
    : "";

  const handleClick = (e, keyData) => {
    setKey(keyData);
    setSelectedFile(e.target.files);
    setFileSize("");
  };

  useEffect(() => {
    if (selectedFile && selectedFile.length > 0) {
      let totalLength = null;
      if (selectedFile.length <= 5) {
        for (const key of Object.keys(selectedFile)) {
          const fsize = selectedFile.item(key).size;
          const file2 = Math.round(fsize / 1024);
          totalLength += file2;
        }
        if (totalLength <= 20480) {
          onSubmit();
        } else {
          setFileSize("File size should be less than 20MB");
        }
      } else {
        setFileSize("Maximum 5 documents allowed");
      }
    }
  }, [selectedFile]);

  const onSubmit = (e) => {
    let formData = new FormData();
    for (const key of Object.keys(selectedFile)) {
      formData.append("files[]", selectedFile[key]);
      formData.append("key", key2);
    }
    setAddDocMsg("");

    axios
      .post(
        apiConfigs.API_URL + "api/organization/upload-document/" + user_id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          onUploadProgress: (progressEvent) => {
            setLoader(true);
          },
        }
      )
      .then(function (response) {
        const dataItem = response.data;
        if (dataItem && dataItem.status === true) {
          setLoader(false);
          setNotifyMsg(true);
          setAddDocMsg("Document Uploaded Successfully");
          dispatch(getSingleSignee(user_id));
          setSelectedFile([]);
        }
      })
      .catch(function (error) {
        setLoader(false);
        setAddDocMsg(error.message);
      });
  };

  const getevent = (pageNo = 1, search = "") => {
    dispatch(getContactEventForSignee(pageNo, search, user_id, orderBy, order));
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(getevent(page, d1), 100);
    } else {
      setTimeout(getevent(page, ""), 100);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setTimeout(getevent(page, searchData.search), 1000);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(getevent(value), 2000);
  };
  const handleClickOpen = (id) => {
    setOpen(true);
  };
  const handleClickOpenRemark = (document_subkey, document_id, data) => {
    setRemarks({ document_subkey: document_subkey, document_id: document_id });
    setOpenRemark(true);
  };
  const handleClickOpenRemarkDocument = (
    document_subkey,
    document_id,
    remark
  ) => {
    setRemarksDocument({
      document_subkey: document_subkey,
      document_id: document_id,
      remark,
    });
    setOpenRemarkDocument(true);
  };

  const handleClickOpenDatePicker = (
    document_subkey,
    document_id,
    expire_date
  ) => {
    setDatePicker({
      document_subkey: document_subkey,
      document_id: document_id,
      expire_date,
    });
    setOpenDatePicker(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseRemarkDocument = () => {
    setOpenRemarkDocument(false);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };

  const handleCloseDatePicker = () => {
    setOpenDatePicker(false);
  };

  const handleExpireDateChange = (date, id) => {
    const data = {
      expire_date: moment(date).format("YYYY-MM-DD"),
      document_id: id,
    };
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(`${apiConfigs.API_URL}api/organization/doc-expiredate`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const output = response.data;
        if (output?.status === true) {
          setAddDocMsg("Expire date update Successfully");
          dispatch(getSingleSignee(user_id));
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openEditDialog = (row) => {
    setEditDialog(true);
    // console.log(row)
    setContactData(row);
  };

  useEffect(() => {
    if (getSingleSigneeItem?.data?.remark) {
      setRemarkGeneral(getSingleSigneeItem?.data?.remark);
    }
  }, []);

  const calculateAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };

  const tabList = [
    "Details",
    "Preferences",
    "Compliance Document Details",
    "Contact Event list",
    "Booking list",
    "Notification Preference",
  ];

  const getTotal = () => {
    let completedDocs = 0;

    if(getSingleSigneeItem?.data?.document_list){
      Object.entries(getSingleSigneeItem?.data?.document_list).map(
        (list, index) => {
          if(list[1]?.data){
            
            const tempDocs = Object.entries(list[1]?.data) || {};
            if (!tempDocs?.find((obj) => obj?.[1]?.docs?.length > 0)) {
              return;
            } else {
              for (const [, sectionData] of tempDocs) {
                if (!sectionData.docs.length) {
                  return;
                }
                for (const doc of sectionData.docs) {
                  if (
                    doc.document_status !== "SUCCESS" &&
                    doc.document_status !== "REJECTED" &&
                    doc.is_checked !== 0
                  ) {
                    return;
                  }
                }
              }
              completedDocs+=1;
            }
          }
        }
      );
    } 
    return Math.round((completedDocs / 8) * 100);
  };

  const getDocUploadedStatus = (docs) => {
    const tempDocs = Object.entries(docs);
    if (!tempDocs?.find((obj) => obj?.[1]?.docs?.filter(d=>d.file_name)?.length > 0)) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ marginRight: '18px' }}

        >
          <circle cx="12" cy="12" r="8" fill="red" />
        </svg>
      );
    } else {
      for (const [, sectionData] of tempDocs) {
        if (!sectionData.docs.length) {
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ marginRight: '18px' }}
            >
              <circle cx="12" cy="12" r="8" fill="yellow" />
            </svg>
          );
        }
        for (const doc of sectionData.docs) {
          if (
            (doc.document_status !== "SUCCESS" &&
            doc.document_status !== "REJECTED") &&
             doc.is_checked !== 0
          ) {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ marginRight: '18px' }}
              >
                <circle cx="12" cy="12" r="8" fill="yellow" />
              </svg>
            );
          }
          
        }
      }
              return (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  style={{ marginRight: '18px' }}
                >
                  <rect width="22" height="22" fill="#22d822" rx="4" />
                  <path
                    fill="white"
                    d="M9 16.17l-3.5-3.5a1 1 0 0 1 1.41-1.41L9 13.35l6.59-6.59a1 1 0 0 1 1.41 1.41z"
                  />
                </svg>
              );
   
    }
  };
  return (
    <>
      {(staffDetail === "Compliance" ||
        staffDetail === "Admin" ||
        loginDetail.role === "ORGANIZATION") && (
        <>
          <Paper
            className={`${classes.root} mb-6`}
            style={{ marginBottom: "15px" }}
          >
            <Grid container spacing={0}>
              <Grid xs={6} sm={6} md={9} lg={9}  style={{rowGap:"15px", display:"flex", flexDirection:"column"}}>
                <Typography variant="h5">
                  <b>
                    {getSingleSigneeItem?.data?.first_name}{" "}
                    {getSingleSigneeItem?.data?.last_name}{" "}
                  </b>
                  <span>
                    {" "}
                    - ({calculateAge(
                      getSingleSigneeItem?.data?.date_of_birth
                    )}{" "}
                    Years)
                  </span>
                </Typography>
                <Grid xs={12} style={{display:"flex" , justifyContent:"space-between", flexBasis: "0%"}}>
                  <Grid >
                    <Typography variant="body2" className={classes.heading}>
                      Email Address
                    </Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {getSingleSigneeItem?.data?.email}
                    </Typography>
                  </Grid>
                  <Grid >
                    <Typography variant="body2">Contact Number</Typography>
                    <Typography variant="h6" className={classes.desc}>
                      {getSingleSigneeItem?.data?.contact_number
                        ? getSingleSigneeItem?.data?.contact_number
                        : "-"}
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography variant="body2" className={classes.heading}>
                      Speciality:
                    </Typography>
                    <div className={classes.chipContainer}>
                      {specilaity_list.length > 0 &&
                        specilaity_list?.map((list, index) => (
                          <Chip
                            className="tag mt-1 mr-1"
                            label={list}
                            key={index}
                          ></Chip>
                        ))}
                    </div>
                  </Grid>

                  {(loginDetail.role === "ORGANIZATION" ||
                    staffDetail === "Admin" ||
                    staffDetail === "Compliance" ||
                    loginDetail?.id === getSingleSigneeItem?.data?.created_by ||
                    loginDetail?.id === getSingleSigneeItem?.data?.staff_id) && (
                    <Grid >
                      <Typography variant="body2">Consultant</Typography>
                      <Typography variant="h6" className={classes.desc}>
                        {getSingleSigneeItem?.data?.consultant
                          ? getSingleSigneeItem?.data?.consultant
                          : ""}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid>
                    <Typography variant="body2">Remark:</Typography>
                        <>
                          <Typography variant="h6" className={classes.desc}>
                            <b>{ 
                            ((getSingleSigneeItem?.data?.remark !== null && getSingleSigneeItem?.data?.remark !== "undefined") ? getSingleSigneeItem?.data?.remark : "-" )}</b>
                          </Typography>
                        </>
                </Grid>
              </Grid>
              

              <Grid xs={6} sm={6} md={3} lg={3} style={{ display:"flex", alignItems:"end", flexDirection:"column"}}>
                
                <Grid style={{display:"flex", flexDirection:"column", rowGap:"10px"}}>
                    <Box
                      className="Per-circle"
                      style={{
                        fontWeight:"800",
                        fontSize: "19px",
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        border: "5px solid #FF8B46",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#184a7b",
                        margin: "0 20px 0px auto",
                            marginInline: "auto"
                      }}
                    >
                      {" "}
                      {getTotal()}%
                    </Box>
                  <Grid>
                    <Typography variant="body2">Status:</Typography>
                    <FormControl className={classes.formControl1}>
                      <Select
                        sx={{ padding: "2px 0px 2px 0px !important" }}
                        value={getSingleSigneeItem?.data?.new_status || ""}
                        name="status"
                        onChange={(e) =>
                          handleComplStatus(e, getSingleSigneeItem?.data?.user_id)
                        }
                        defaultValue={0}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Not active">
                        Not active
                        </MenuItem>
                        <MenuItem value="Pre reg">Pre reg</MenuItem>
                        <MenuItem value="New sign up">New sign up</MenuItem>
                        <MenuItem value="Archive">Archive</MenuItem>
                        <MenuItem value="Do not use">Do not use</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
            
            
          </Paper>
        </>
      )}

      <Box className={classes.statusButton}>
        {tabList.map((list, index) => (
          <span
            style={{ textTransform: "capitalize" }}
            onClick={() => setValue(index)}
            className={`btn ${value == index ? "active" : ""}`}
          >
            {list}
          </span>
        ))}
      </Box>
      {loading || loader ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {(signeeNotify || docNotify) &&
        (deleteSigneeSuccess?.message || changeDocStatusSuccess.message) && (
          <Notification
            data={
              deleteSigneeSuccess?.message || changeDocStatusSuccess.message
            }
            status="success"
          />
        )}
      {signeeNotify && deleteSigneeError?.message && (
        <Notification data={deleteSigneeError?.message} status="error" />
      )}
      {compNotify && signeeComStatusSuccess?.message && (
        <Notification data={signeeComStatusSuccess?.message} status="success" />
      )}
      {proNotify && signeeProStatusSuccess?.message && (
        <Notification data={signeeProStatusSuccess?.message} status="success" />
      )}
      {fileSizeMsg && <Notification data={fileSizeMsg} status="error" />}
      {addDocMsg && <Notification data={addDocMsg} status="success" />}

      {deleteDocumentSuccess && deleteDocumentSuccess?.message && (
        <Notification data={deleteDocumentSuccess?.message} status="success" />
      )}
      {deleteDocumentError && deleteDocumentError?.message && (
        <Notification data={deleteDocumentError?.message} status="error" />
      )}
      {addCandidateRemarkSuccess && addCandidateRemarkSuccess?.message && (
        <Notification
          data={addCandidateRemarkSuccess?.message}
          status="success"
        />
      )}
      {addCandidateRemarkError && addCandidateRemarkError?.message && (
        <Notification data={addCandidateRemarkError?.message} status="error" />
      )}
      <CreateContactEvent
        open={open}
        handleClose={handleClose}
        signee_id={user_id}
      />
      <CreateRemarksEvent
        open={openRemark}
        handleClose={handleCloseRemark}
        signee_id={user_id}
        remark={remarks}
        textdata={
          remarkGeneral ? remarkGeneral : getSingleSigneeItem?.data?.remark
        }
        setRemarkGeneral={setRemarkGeneral}
      />

      <CreateDatePickerEvent
        open={openDatePicker}
        handleClose={handleCloseDatePicker}
        signee_id={user_id}
        expire_date={exDateDocument}
        setExpireDate={expireDate}
      />
      <CreateRemarksDocument
        open={openRemarkDocument}
        handleClose={handleCloseRemarkDocument}
        signee_id={user_id}
        remark={remarksDocument}
        textdata={remarkDocument ? remarkDocument : ""}
        setRemarkDocument={setRemarkDocument}
      />

      {value === 0 && (
        <>
          <Paper className={`${classes.root} mb-2`}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  First Name
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.first_name}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Last Name
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.last_name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Email Address
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Contact Number
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.contact_number
                    ? getSingleSigneeItem?.data?.contact_number
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Date of birth
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.date_of_birth
                    ? getSingleSigneeItem?.data?.date_of_birth
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Address
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.address_line_1
                    ? getSingleSigneeItem?.data?.address_line_1
                    : "-"}{" "}
                  {getSingleSigneeItem?.data?.address_line_2}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  City
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.city
                    ? getSingleSigneeItem?.data?.city
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Postcode
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.postcode
                    ? getSingleSigneeItem?.data?.postcode
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Date Registration
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.date_registered
                    ? getSingleSigneeItem?.data?.date_registered
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Nationality
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.nationality
                    ? getSingleSigneeItem?.data?.nationality
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Registered Body pin
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.nmc_dmc_pin
                    ? getSingleSigneeItem?.data?.nmc_dmc_pin
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Candidate Id
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.candidate_id
                    ? getSingleSigneeItem?.data?.candidate_id
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Revalidation Due Date
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.u_revalidate_due_date !=
                    "00-00-0000" &&
                  getSingleSigneeItem?.data?.u_revalidate_due_date != null &&
                  getSingleSigneeItem?.data?.u_revalidate_due_date != undefined
                    ? getSingleSigneeItem?.data?.u_revalidate_due_date
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Appraisal Due Date
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.u_apprisal_due_date !=
                    "00-00-0000" &&
                  getSingleSigneeItem?.data?.u_apprisal_due_date != null &&
                  getSingleSigneeItem?.data?.u_apprisal_due_date != undefined
                    ? getSingleSigneeItem?.data?.u_apprisal_due_date
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Start Date
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.start_date
                    ? getSingleSigneeItem?.data?.start_date
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  SOE Registration Date
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.npm_revalidation_date !=
                    "00-00-0000" &&
                  getSingleSigneeItem?.data?.npm_revalidation_date != null &&
                  getSingleSigneeItem?.data?.npm_revalidation_date != undefined
                    ? getSingleSigneeItem?.data?.npm_revalidation_date
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Revalidation Due Date
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.revalidation_due_date !=
                    "00-00-0000" &&
                  getSingleSigneeItem?.data?.revalidation_due_date != null &&
                  getSingleSigneeItem?.data?.revalidation_due_date != undefined
                    ? getSingleSigneeItem?.data?.revalidation_due_date
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Registration Status
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.registration_status
                    ? getSingleSigneeItem?.data?.registration_status
                    : ""}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Speciality:
                </Typography>
                <div className={classes.chipContainer}>
                  {specilaity_list.length > 0
                    ? specilaity_list?.map((list, index) => (
                        <Chip
                          className="tag mt-1 mr-1"
                          label={list}
                          key={index}
                        ></Chip>
                      ))
                    : "-"}
                </div>
              </Grid>
              {(loginDetail.role === "ORGANIZATION" ||
                staffDetail === "Admin" ||
                staffDetail === "Compliance" ||
                loginDetail?.id === getSingleSigneeItem?.data?.created_by ||
                loginDetail?.id === getSingleSigneeItem?.data?.staff_id) && (
                <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                  <Typography variant="body2" className={classes.heading}>
                    Consultant
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    {getSingleSigneeItem?.data?.consultant
                      ? getSingleSigneeItem?.data?.consultant
                      : "-"}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>
          <Paper className={`${classes.root} mb-2`}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.mainTitle}>
                  Payroll Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Payment Method
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.payment_method
                    ? getSingleSigneeItem?.data?.payment_method
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Umbrella Name
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.name_umbrella
                    ? getSingleSigneeItem?.data?.name_umbrella
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  HRMC Engagement Reason
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.hrmc_engagement_reason
                    ? getSingleSigneeItem?.data?.hrmc_engagement_reason
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Name of Bank
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.name_of_bank
                    ? getSingleSigneeItem?.data?.name_of_bank
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Account Number
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.account_number
                    ? getSingleSigneeItem?.data?.account_number
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Sort Code
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.sort_code
                    ? getSingleSigneeItem?.data?.sort_code
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  NI Number
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.ni_number
                    ? getSingleSigneeItem?.data?.ni_number
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper className={`${classes.root} mb-2`}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.mainTitle}>
                  Next of Kin:
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Name
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.name_of_kin
                    ? getSingleSigneeItem?.data?.name_of_kin
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Address
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.address_of_kin
                    ? getSingleSigneeItem?.data?.address_of_kin
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Contact Number
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.contact_of_kin
                    ? getSingleSigneeItem?.data?.contact_of_kin
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Emergency Contact Number
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  {getSingleSigneeItem?.data?.econtact_of_kin
                    ? getSingleSigneeItem?.data?.econtact_of_kin
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className={classes.btnContainer}
                >
                  <Button color="primary" onClick={backPage}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                     onClick={(e) =>
                          disabledEnabledLogin(getSingleSigneeItem?.data?.user_id, getSingleSigneeItem?.data?.login_status)
                        }
                  >
                    { getSingleSigneeItem?.data?.login_status === 0 ? 'Disabled Login' : "Enabled Login" }
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                     onClick={(e) =>
                          downloadChecklist(getSingleSigneeItem?.data?.user_id)
                        }
                  >
                    Download Checklist
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickOpenRemark(null, null)}
                  >
                    Remarks
                  </Button>
                  {(loginDetail.role === "ORGANIZATION" ||
                    staffDetail === "Admin" ||
                    staffDetail === "Compliance" ||
                    loginDetail?.id === getSingleSigneeItem?.data?.created_by ||
                    loginDetail?.id ===
                      getSingleSigneeItem?.data?.staff_id) && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={upadateLink}
                      >
                        <EditIcon className="mr-2" />
                        Edit
                      </Button>
                    </>
                  )}
                  {(staffDetail === "Admin" ||
                    loginUserInfo?.role === "ORGANIZATION") && (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) =>
                          deleteStaffItem(getSingleSigneeItem?.data?.user_id)
                        }
                      >
                        <DeleteIcon className="mr-2" />
                        Delete
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {value === 1 && (
        <>
          <Typography variant="h5" style={{ marginBottom: 16 }}>
            Preferences
          </Typography>
          <Grid container spacing={2} style={{ marginBottom: 24 }}>
            <Grid item xs={12} style={{ display: "flex", flexWrap: "wrap" }}>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Monday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.monday_day === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.monday_night === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Tuesday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.tuesday_day === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.tuesday_night === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Wednesday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.wednesday_day === 1
                              ? true
                              : false
                          }
                          color="primary"
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.wednesday_night === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Thursday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.thursday_day === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.thursday_night === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Friday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.friday_day === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.friday_night === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Saturday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.saturday_day === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.saturday_night === 1
                              ? true
                              : false
                          }
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
              <Card className={classes.cardBox}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <p className="f-500 mb-2"> Sunday</p>
                  <div>
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.sunday_day === 1
                              ? true
                              : false
                          }
                          name="monday_day"
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      style={{ cursor: "default" }}
                      control={
                        <Checkbox
                          checked={
                            getSingleSigneeItem?.data?.preferences
                              ?.sunday_night === 1
                              ? true
                              : false
                          }
                          name="monday_night"
                          color="primary"
                          style={{ cursor: "default" }}
                        />
                      }
                      label="Night"
                    />
                  </div>
                </FormControl>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 24 }}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <div className="" style={{ marginRight: 24 }}>
                <p className="f-500 mb-3">
                  {" "}
                  Number of shifts you are looking
                  <br /> to work per week.
                </p>
                <Card
                  className={classes.cardBox}
                  style={{ width: "max-content" }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <div>
                      <b>
                        {getSingleSigneeItem?.data?.preferences?.no_of_shift}
                      </b>{" "}
                      Shift
                    </div>
                  </FormControl>
                </Card>
              </div>

              <div>
                <p className="f-500 mb-2">Travel for work</p>
                <Card
                  className={classes.cardBox}
                  style={{ width: "max-content" }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <div>
                      <FormControlLabel
                        style={{ cursor: "default" }}
                        control={
                          <Checkbox
                            checked={
                              getSingleSigneeItem?.data?.preferences
                                ?.is_travel === 1
                                ? true
                                : false
                            }
                            name="monday_day"
                            color="primary"
                            style={{ cursor: "default" }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        style={{ cursor: "default" }}
                        control={
                          <Checkbox
                            checked={
                              getSingleSigneeItem?.data?.preferences
                                ?.is_travel === 0
                                ? true
                                : false
                            }
                            name="monday_night"
                            color="primary"
                            style={{ cursor: "default" }}
                          />
                        }
                        label="No"
                      />
                    </div>
                  </FormControl>
                </Card>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {value === 2 && (
        <>
          <Typography variant="h5" style={{ marginBottom: 16 }}>
            Compliance Document Details
          </Typography>
          {getSingleSigneeItem?.data &&
            getSingleSigneeItem?.data?.document_list &&
            Object.entries(getSingleSigneeItem?.data?.document_list).map(
              (list, index) => {
                return (
                  <>
                    <Accordion
                      key={index}
                      expanded={expanded === `panel${index + 1}`}
                      onChange={handleChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography variant="h5">
                          {getDocUploadedStatus(list[1]?.data || {})}
                          {list[1].title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes?.accDetailBackColor}>
                        <Grid container spacing={0}>
                          {list[1].data &&
                            list[1].data &&
                            Object.entries(list[1].data).map(
                              (lists, indexs) => {
                                return (
                                  <Grid item xs={12}>
                                    <Paper
                                      className={`${classes.root1}`}
                                      key={indexs}
                                    >
                                      <Grid container spacing={0}>
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ display: "flex", 
                                           position:"relative",
                                        }}
                                        >
                                          <InfoIcon
                                            style={{
                                              marginRight: 12,
                                              opacity: "0.6",
                                            }}
                                          />

                                          <Typography
                                            className={classes.mainTitle}
                                          >
                                            {lists[1].subTitle}
                                          </Typography>
                                          {(staffDetail === "Compliance" ||
                                            staffDetail === "Admin" ||
                                            loginDetail.role ===
                                              "ORGANIZATION") && (
                                            <>
                                               
                                              <div className={classes.fileCon}>
                                                <button
                                                  title="Upload Files"
                                                  className="btn1"
                                                  style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                 
                                                  <PublishIcon />
                                                  <input
                                                    key={key1}
                                                    accept="image/*,.pdf"
                                                    type="file"
                                                    multiple
                                                    name="files"
                                                    onChange={(e) =>
                                                      handleClick(
                                                        e,
                                                        lists[1].subKey
                                                      )
                                                    }
                                                    className={
                                                      classes.uploadFile
                                                    }
                                                  />
                                                </button>
                                              </div>
                                              <Grid style={{ display: "flex" }}>
                                                <div
                                                    className={classes.selectCon}
                                                  >
                                                  {lists[1]?.docs.filter(d=>d.file_name)?.length > 0 && (
                                                    <FormControl
                                                      variant="outlined"
                                                      className={
                                                        classes.formControl
                                                      }
                                                      fullWidth
                                                      >
                                                      <Select
                                                        value={
                                                          lists[1].docs[0]
                                                            ?.document_status ||
                                                          complainceStatus?.document_status
                                                        }
                                                        name={lists[0]}
                                                        onChange={(e) =>
                                                          handleComplianceStatus(
                                                            e,
                                                            getSingleSigneeItem
                                                              ?.data?.parent_id
                                                          )
                                                        }
                                                        defaultValue={0}
                                                        className={
                                                          classes.statusSelect
                                                        }
                                                      >
                                                        <MenuItem value="PENDING">
                                                          PENDING
                                                        </MenuItem>
                                                        <MenuItem value="SUCCESS">
                                                          ACCEPTED
                                                        </MenuItem>
                                                        <MenuItem value="REJECTED">
                                                          REJECTED
                                                        </MenuItem>
                                                      </Select>
                                                    </FormControl>
                                                  )}
                                                </div>
                                              </Grid>
                                            </>
                                          )}
                                        </Grid>

                                        {lists[1].docs &&
                                          lists[1].docs?.map(
                                            (items, index1) => 
                                              
                                             
                                                  <Grid item xs={12}>
                                                    {items?.file_name ?
                                                    (<div
                                                      className="compliance-container mb-3"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                       
                                                      }}
                                                      > 
                                                      <div
                                                          className="image-icon"
                                                          style={{
                                                          marginRight: 12,
                                                          opacity: "0.6",
                                                          }}
                                                        >
                                                          {items?.file_name
                                                            .split(".")
                                                            .pop() === "pdf" ? (
                                                            <PictureAsPdfIcon />
                                                          ) : (
                                                            <InsertPhotoOutlinedIcon />
                                                          )}
                                                      </div>
                                    
                                                      <Link
                                                        style={{
                                                          minWidth: "300px",
                                                          maxWidth: "300px",
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow: "ellipsis",
                                                        }}
                                                        to={{
                                                          pathname: `${baseUrl}${items?.file_name}`,
                                                        }}
                                                        target="_blank"
                                                      >
                                                        {items?.file_name}
                                                      </Link>
                                                      <Typography
                                                        style={{
                                                          minWidth: "100px",
                                                          marginRight: "10px",
                                                        }}
                                                      >
                                                        <Tooltip
                                                          title="Remarks"
                                                          arrow
                                                        >
                                                          <Button
                                                            color="secondary"
                                                            onClick={() =>
                                                              handleClickOpenRemarkDocument(
                                                                lists[1].subKey,
                                                                lists[1].docs[0],
                                                                items?.remark
                                                                  ? items?.remark
                                                                  : ""
                                                              )
                                                            }
                                                          >
                                                            <MessageIcon />
                                                          </Button>
                                                        </Tooltip>
                                                        :{items?.remark}
                                                      </Typography>

                                                      {staffDetail ===
                                                        "Compliance" ||
                                                      staffDetail === "Admin" ||
                                                      loginDetail.role ===
                                                        "ORGANIZATION" ? (
                                                        <>
                                                          <span
                                                            style={{
                                                              float: "right",
                                                              paddingRight: "5px",
                                                            }}
                                                          >
                                                            Expire Date:{" "}
                                                            {items?.expire_date ===
                                                            null
                                                              ? "N/A"
                                                              : ""}
                                                          </span>
                                                          <span
                                                            style={{
                                                              float: "right",
                                                            }}
                                                          >
                                                            <TextField
                                                              id="expire_date"
                                                              value={
                                                                items?.expire_date ===
                                                                null
                                                                  ? ""
                                                                  : moment(
                                                                      items?.expire_date
                                                                    ).format(
                                                                      "DD/MM/YYYY"
                                                                    )
                                                              }
                                                              readOnly={true}
                                                              dateFormat="DD/MM/YYYY"
                                                              onClick={() =>
                                                                handleClickOpenDatePicker(
                                                                  lists[1].subKey,
                                                                  lists[1].docs[index1],
                                                                  moment(
                                                                    items?.expire_date
                                                                  ).format(
                                                                    "YYYY-MM-DD"
                                                                  )
                                                                )
                                                              }
                                                            />
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <span
                                                            style={{
                                                              float: "right",
                                                            }}
                                                          >
                                                            Expire Date:{" "}
                                                            {items?.expire_date ||
                                                              "N/A"}
                                                          </span>
                                                        </>
                                                      )}
                                                      <DeleteIcon
                                                        color="secondary"
                                                        style={{
                                                          float: "right",
                                                          marginLeft: "10px",
                                                          marginTop: "-2px",
                                                        }}
                                                        onClick={(e) =>
                                                          deleteDocument(items?.id)
                                                        }
                                                      />
                                                       
                                                        <FormControl
                                                        variant="outlined"
                                                        className={
                                                          classes.formControl
                                                        }
                                                        >
                                                      <Select
                                                      sx={{ padding: "2px 0px 2px 0px !important" }}
                                                            value={items?.is_checked}
                                                            name="is_checked"
                                                            onChange={(e) => handleCheckboxChange(e, items?.id)}
            
                                                            className={
                                                              classes.statusSelect
                                                            }
                                                          >
                                                            <MenuItem value="0">
                                                              NA
                                                            </MenuItem>
                                                            <MenuItem value="1">
                                                              Folder Required
                                                            </MenuItem>
                                                            <MenuItem value="2">
                                                              Compliance Required
                                                            </MenuItem>
                                                          </Select>
                                                          </FormControl>
                                                    </div>
                                                    ): (
                                                       <div className="compliance-container">
                                                        <span
                                                          style={{
                                                            paddingLeft: 35,
                                                            paddingRight: 35,
                                                            opacity: "0.6",
                                                          }}
                                                        >
                                                          Sorry, document does't exist
                                                        </span>
                                                        <FormControl
                                                        variant="outlined"
                                                        className={
                                                          classes.formControl
                                                        }
                                                        >
                                                      <Select
                                                      sx={{ padding: "2px 0px 2px 0px !important" }}
                                                            value={items?.is_checked}
                                                            name="is_checked"
                                                            onChange={(e) => handleCheckboxChange(e, items?.id)}
            
                                                            className={
                                                              classes.statusSelect
                                                            }
                                                          >
                                                            <MenuItem value="0">
                                                              NA
                                                            </MenuItem>
                                                            <MenuItem value="1">
                                                              Folder Required
                                                            </MenuItem>
                                                            <MenuItem value="2">
                                                              Compliance Required
                                                            </MenuItem>
                                                          </Select>
                                                          </FormControl>
                                                    </div>
                                                    
                                                    
                                                    )
                                                    }
                                                     
                                                  </Grid>
                                            
                                          )}
                                       
                                        {lists[1]?.docs.length === 0 && (
                                          <Grid
                                            item
                                            xs={12}
                                            className={classes.boxContainer}
                                             
                                          >

                                            <div className="compliance-container">
                                              <span
                                                style={{
                                                  paddingLeft: 35,
                                                  paddingRight: 35,
                                                  opacity: "0.6",
                                                  display:"flex",
                                                }}

                                                
                                              >
                                                Sorry, document does't exist
                                              </span> 
                                              <FormControl
                                                        variant="outlined"
                                                        className={
                                                          classes.formControl
                                                        }
                                                        >
                                                      <Select
                                                      sx={{ padding: "2px 0px 2px 0px !important" }}
                                                            name="is_checked"
                                                            onChange={(e) => handleNewCheckboxChange(e, lists[1].subKey)}
                                                            value={selectedValue}
                                                            className={
                                                              classes.statusSelect
                                                            }
                                                          >
                                                            <MenuItem value="0">
                                                              NA
                                                            </MenuItem>
                                                            <MenuItem value="1">
                                                              Folder Required
                                                            </MenuItem>
                                                            <MenuItem value="2">
                                                              Compliance Required
                                                            </MenuItem>
                                                          </Select>
                                                          </FormControl>
                                            </div>
                                           
                                           
                                          </Grid>
                                        )}
                                        
                                      </Grid>
                                    </Paper>
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              }
            )}
        </>
      )}
      {value === 3 && (
        <>
          <Typography variant="h5" style={{ marginBottom: 16, marginTop: 15 }}>
            Contact Event list
          </Typography>
          <Paper className={`${classes.root} mb-6`}>
            <Box className="mb-5" display="flex" alignItems="center">
              <SearchIcon
                className={classes.searchIcondet}
                onClick={handleClickSearch}
              />
              <div className={classes.search}>
                <InputBase
                  name="search"
                  placeholder="Searchâ€¦"
                  onChange={handleSearchChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
              <div className="ml-5">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClickOpen}
                >
                  <AddIcon className="mr-2" />
                  Add Contact Event
                </Button>
              </div>
            </Box>

            <Table className={classes.table}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, property) => {
                  handleRequestSort(
                    property,
                    setOrder,
                    setOrderBy,
                    orderBy,
                    order
                  );
                }}
                rowCount={getCandidateEventItem?.data?.data?.length}
                headCells={headCellsContactEvent[0]}
              />

              <TableBody>
                {getCandidateEventItem?.data &&
                  getCandidateEventItem?.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">{index + 1}</TableCell>
                        <TableCell align="left">
                          {moment(row.created_at).format("DD MMM YYYY hh:mm A")}
                        </TableCell>
                        <TableCell align="left">{row.comment}</TableCell>
                        <TableCell align="left">{`${row.first_name}`}</TableCell>
                        <TableCell align="left">
                          {/* {console.log(row)} */}

                          {loginDetail.id == row?.created_by && (
                            <EditIcon onClick={() => openEditDialog(row)} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {getCandidateEventItem?.data === "" ||
                  (getCandidateEventItem?.data === undefined && (
                    <TableRow>
                      <TableCell scope="row" colSpan="4">
                        <div className="" align="center">
                          Sorry, Contact Event not found!
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box className="mt-5" display="flex" justifyContent="flex-end">
              <Pagination
                onChange={handleChangePage}
                page={page}
                count={getCandidateEventItem?.data?.last_page}
                showFirstButton
                showLastButton
              />
            </Box>
          </Paper>
        </>
      )}
      {value === 4 && <SingleCandidateList user_id={user_id} match={match} />}
      {value === 5 && (
        <UserNotificationPreference user_id={user_id} column_name="" type="" />
      )}
      <AlertDialog
        id={Id}
        open={deleteOpen}
        close={deleteRoleClose}
        response={alertResponse}
        title="Delete Candidate"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />
      <AlertDialog
        id={docId}
        open={deleteDocOpen}
        close={deleteDocClose}
        response={deleteDocResponse}
        title="Delete Candidate Document"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />
      <EditContactEventDialog
        title="edit"
        open={editDialog}
        data={contactData}
        setData={setContactData}
        handleClose={() => setEditDialog(false)}
      />
    </>
  );
};

export default DetailSignee;
