import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getUserNotificationPrefsItem: [],
  getUserNotificationPrefsError: [],
  UserNotificationPrefsSuccess: [],
  UserNotificationPrefsErrors: [],
};

const userNotificationPreferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_NOTIFICATION_PREF_REQUEST:
      return updateObject(state, {
        loading: true,
        UserNotificationPrefsSuccess: "",
        UserNotificationPrefsErrors: "",
      });

    case actionTypes.GET_USER_NOTIFICATION_PREF_SUCCESS:
      return updateObject(state, {
        loading: false,
        getUserNotificationPrefsItem: action.payload,
      });

    case actionTypes.GET_USER_NOTIFICATION_PREF_ERROR:
      return updateObject(state, {
        loading: false,
        getUserNotificationPrefsError: action.payload,
      });

    case actionTypes.CREATE_USER_NOTIFICATION_PREF_REQUEST:
      return updateObject(state, {
        loading: true,
        subUserNotificationPrefsSuccess: "",
        SubUserNotificationPrefsErrors: "",
      });

    case actionTypes.CREATE_USER_NOTIFICATION_PREF_SUCCESS:
      return updateObject(state, {
        loading: false,
        UserNotificationPrefsSuccess: action.payload,
      });

    case actionTypes.CREATE_USER_NOTIFICATION_PREF_ERROR:
      return updateObject(state, {
        loading: false,
        UserNotificationPrefsErrors: action.payload,
      });

    
    default:
      return state;
  }
};

export default userNotificationPreferenceReducer;
