export const headCells = [
  //for zero(0) index for Signee/Candidate
  [
    {
      id: "id",
      label: "Id",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "first_name",
      label: "Candidate Name",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "cons_first_name",
      label: "Consultant",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "id_badge_exp_date",
      label: "ID Badge Expiration",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "accessni_expiry_date",
      label: "AccessNI Expiry Date",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "dbs_expire_date",
      label: "DBS Expiration Dates",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "fit_expire_date",
      label: "OCC. Health Expiration Dates",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "passport_expire_date",
      label: "BRP",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "nmc_expire_date",
      label: "NMC Expiration Dates",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "mandotoryt_expire_date",
      label: "Mandatory Training",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "ref1_expire_date",
      label: "Ref 1",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "ref2_expire_date",
      label: "Ref 2",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "proximity",
      label: "Proximity",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "Invoice",
      label: "View",
      sortable: false,
      align: "right",
      numIndex: 0,
      activeIndexOnly: true,
    },
  ],
];
